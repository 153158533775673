import React from 'react';
import {IStoryblokColorPicker, IStoryBlokComponent} from '~interfaces/storyblock';
import {openPopupWidget} from 'react-calendly';
import {Button} from '@ef-global/web-ui-react/lib/components/Button';
import {css} from '@emotion/core';

interface CalendlyProps extends IStoryBlokComponent {
  text: string;
  type: string;
  calendly_url: string;
  primary?: boolean;
  cta?: boolean;
  square?: boolean;
  small?: boolean;
  id?: string;
  background_color?: IStoryblokColorPicker;
  border_color?: IStoryblokColorPicker;
}

const Calendly = (props: CalendlyProps) => {
  const {text, type, primary, cta, small, square, id, background_color, border_color, calendly_url} = props;

  const buttonStyle = css`
    border: ${border_color?.color ? `${border_color?.color} solid 1px !important` : 'inherit'};
    background-color: ${background_color?.color ? `${background_color?.color} !important` : 'inherit'};
  `;

  return type === 'link' ? (
    <a href='' className={`ef-link`} onClick={() => openPopupWidget({url: calendly_url})}>{text}</a>
  ) : (
    <Button
      onClick={() => openPopupWidget({url: calendly_url})}
      {...(primary ? {primary: true} : {secondary: true})}
      cta={cta}
      small={small}
      square={square}
      id={id}
      className='ef-button-pointer-events-none'
      style={{height: 'fit-content'}}
      css={[buttonStyle]}
    >
      {text}
    </Button>
  );
};

export default Calendly;
