import React from 'react';
import Components from '~components/components';
import SEO from '~components/utils/seo';
import { IFocusPoint } from '~src/interfaces/storyblock';
import { resize } from '~src/services/image-service';
import { ISeoMetatags } from '~src/interfaces/plugins';

interface IPageProps {
  body: Array<any>;
  story: any;
  settings: any;
  featured_image: IFocusPoint;
  seo_data: ISeoMetatags;
  keywords: string;
  google_site_verification: string;
  noindex: boolean;
}

const Page = (props: IPageProps) => {
  const { body, story, settings, featured_image, seo_data, keywords, google_site_verification, noindex = false} = props;

  const socialMediaImage = featured_image ? resize(featured_image.image, 1280, 720) : '';

  return (
    <>
      {seo_data && (
        <SEO
          seoData={seo_data}
          image={socialMediaImage}
          keywords={keywords?.split(',')}
          googleSiteVerification={google_site_verification}
          noindex={noindex}
        />
      )}

      {body?.length > 0 ? body.map((blok) => Components(blok, story, settings)) : <div />}
    </>
  );
};

export default Page;
