import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Button } from '@ef-global/web-ui-react/lib/components/Button';
import Link from '~components/utils/link';
import SettingsContext from '~src/helpers/settingsContext';

import chunk from 'lodash/chunk'

import SingleStudent from './single-student';
import { getStudents, getResponsiveValue } from './library';

import {DEFAULT_VALUES, GRIS_SYSTEM_NO_OF_COLUMNS} from './constants';
import './student.scss';
import {SingleStudentRowSkeleton} from '~components/custom/students/skeletons/single-student-skeleton';
import {IStudent} from '~components/custom/students/types';

interface StudentProps {
  filter: any;
  incrementPage: any;
  noOfInterests?: number,
  noOfColumnsMobile?: number;
  noOfStudentsPerPageMobile?: number;
  noOfColumnsTablet?: number;
  noOfStudentsPerPageTablet?: number;
  noOfColumns?: number;
  noOfStudentsPerPage?: number;
  minAge?: number;
  maxAge?: number;
  chooseACountryTitle?: string,
  filterTitle?: string,
  personalLetterVisibleCharacters?: number;
}

export default ({
    filter,
    noOfColumns = DEFAULT_VALUES.noOfColumns,
    noOfColumnsMobile = DEFAULT_VALUES.noOfColumnsMobile,
    noOfColumnsTablet = DEFAULT_VALUES.noOfColumnsTablet,
    noOfInterests = DEFAULT_VALUES.noOfInterests,
    incrementPage
}: StudentProps) => {
    // @ts-ignore
    const { settings, settings: { content: { student_detail_link } = {} } } = useContext(SettingsContext);

    const [listOfStudents, setListOfStudents] = useState([])
    const [noOfRecords, setNoOfRecords] = useState(0)

    const noOfColumnsResponsive = getResponsiveValue(noOfColumnsMobile, noOfColumnsTablet, noOfColumns)
    const rowsOfStudents = chunk(listOfStudents, noOfColumnsResponsive);
    const classNameForNoOfColumns = Math.ceil(GRIS_SYSTEM_NO_OF_COLUMNS / noOfColumnsResponsive)

    const mustAppend = () => filter.page > 0;

    const noOfStudentsLeft = () => Math.abs(listOfStudents.length - noOfRecords);

    const getStudentsCallback = useCallback(async () => {
        const { students, length } = await getStudents(filter)
        // @ts-ignore
        setListOfStudents(!mustAppend() ? students : [...listOfStudents, ...students]);
        setNoOfRecords(length);

    }, [filter]);

    useEffect(() => { getStudentsCallback() }, [filter.age, filter.country, filter.gender, filter.page, filter.size]);

    const moreButton = noOfStudentsLeft() ? (
        <div className='u-text-center see-more'>
            <Button
                className='next-page'
                secondary
                onClick={incrementPage}
            >
                See more
            </Button>
        </div>
    ) : <></>
    return (
        <div className='students'>
          {!rowsOfStudents || !rowsOfStudents.length ? (
            <>
              <SingleStudentRowSkeleton classNameForNoOfColumns={classNameForNoOfColumns} />
              <SingleStudentRowSkeleton classNameForNoOfColumns={classNameForNoOfColumns} />
            </>
            ) : rowsOfStudents?.map((currentRow, index) => (
              <div
                key={index}
                className='ef-row students--results'
              >
                {currentRow?.map((student: IStudent) => (
                  <div key={student.id} className={`ef-col -s-${classNameForNoOfColumns}`}>
                    <Link
                      className='student-link'
                      storyblokLink={student_detail_link}
                      queryString={location.search ? `${location.search}&studentid=${student.id}` : `?studentid=${student.id}`}
                      aria-label='Student'
                      openInANewTab={false}
                    >
                      <SingleStudent noOfInterests={noOfInterests} student={student} />
                    </Link>
                  </div>
                ))}
              </div>
            ))}
            {moreButton}
        </div>
    )
};
