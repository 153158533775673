import React from 'react';
import './video-component.scss';
import Loadable from 'react-loadable';

const VideoComponent = (props: any) => {
  const ReactPlayer = Loadable({
    loader: () => import('react-player/lazy'),
    loading: () => <div />
  });
  const autoplay = !!props.autoplay;
  const loop = !!props.loop;
  const controls = false
  const url = props?.file?.filename || props.url
  return (
    <ReactPlayer
      url={url || ''}
      className='react-player'
      width='100%'
      height='100%'
      playing={autoplay}
      controls={controls}
      muted={autoplay}
      loop={loop}
      playsinline
    />
  );
};

export default VideoComponent;
