import {Accordion as WebUIAccordion} from '@ef-global/web-ui-react/lib/components/Accordion';
import React from 'react';
import {IStoryBlokComponent, IStoryblokColorPicker} from '~interfaces/storyblock';
import AccordionItem, {IAccordionItem} from './accordion-item';
import {css} from '@emotion/core';
import {createSpacingClass} from '~components/utils/spacing';
import {ISpacing} from '~interfaces/components';

interface IAccordionProps extends IStoryBlokComponent {
  accordion_items: Array<any>;
  background_color: IStoryblokColorPicker;
  divider_color: IStoryblokColorPicker;
  text_color: IStoryblokColorPicker;
  spacing: Array<ISpacing>;
  rounded_corners: boolean;
}

/**
 * Accordion implementation from WebUiReactKit
 * https://github.com/ILC-Technology/web-ui-react/blob/develop/src/components/Accordion.tsx
 */
const Accordion = (props: IAccordionProps) => {
  const {accordion_items, background_color, divider_color, text_color, spacing, rounded_corners} = props;
  const accordionSpacing = createSpacingClass(spacing);

  return (
    <WebUIAccordion
      css={css`
        background-color: ${background_color?.color ?? 'inherit'};
        border-radius: ${rounded_corners ? '10px' : '0'};
        p, .ef-icon {
          color: ${text_color?.color ?? 'inherit'};
        }
        .ef-icon > path {
          fill: ${text_color?.color ?? 'inherit'} !important;
        }
      `}
      className={`u-mb-xl full-width ${accordionSpacing}`}
    >
      {accordion_items && accordion_items.map(
        (accordionItem: IAccordionItem) =>
          (accordionItem?.content || accordionItem?.blok_content) && accordionItem?.title ?
            (
              <AccordionItem
                {...accordionItem}
                divider_color={divider_color}
                text_color={text_color}
                key={accordionItem._uid}
              />
            )
            : null
      )}
    </WebUIAccordion>
  );
};

export default Accordion;
