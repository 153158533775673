import queryString from "query-string";
import sourceCodeMapping from "./source-code-mapping.json";
import { IFormElement } from "~interfaces/components";
import {
  FIELD_REQUIRED,
  INVALID_EMAIL,
  INVALID_LENGTH_OVER,
  INVALID_PHONE,
} from "./error-messages";
import { getCookie } from "~src/helpers/cookieHelper";

/**
 * Formik requires a Map with each element id and its value.
 * ex: {
 *  first_name: '',
 *  email: ''
 * }
 */
const createFormikInitialValues: any = (
  formElements: Array<IFormElement | Array<IFormElement>>
) => {
  return formElements.reduce((finalFormObject, formElement) => {
    if (Array.isArray(formElement))
      return {
        ...finalFormObject,
        ...createFormikInitialValues(formElement),
      };
    if (formElement.type !== "section") {
      let paramValue;
      const splittedValue = formElement?.placeholder?.split("&") ?? null;

      // Checks if splitted contains key and query element to fetch from URL.
      // else just act normally getting formstack default value or empty.
      if (splittedValue && splittedValue.length === 2 && splittedValue[1] === "query") {
        const urlParams = new URLSearchParams(window.location.search);
        paramValue = urlParams.get(splittedValue[0]);
        if (!paramValue || paramValue == "") {
          paramValue = formElement.default;
        }
      } else {
        paramValue = formElement.default || "";
      }

      return {
        ...finalFormObject,
        [formElement.id]: paramValue,
      };
    }

    return { ...finalFormObject };
  }, {});
};

const parseAnalyticsCookie = (cookieName: string) => {
  let cookieValues: {
    utmccn: string;
    utmcmd: string;
    utmcsr: string;
    utmctr: string;
  } | any = {};
  const cookie = getCookie(cookieName);
  const DEFAULT_SOURCE_VALUE = sourceCodeMapping.find(({ from }) => from === "")
    ?.to;
  const queryStringObject: any = queryString.parse(location.search);
  const { utm_medium: utmMediumValue, source } = queryStringObject;

  if (cookie) {
    const rawCookieValues = cookie.split("|");

    cookieValues = rawCookieValues.reduce((acc, curr) => {
      const split = curr.split("=");
      const utmparam = split[0] ?? "";
      const value = split[1] ?? "";
      return { ...acc, [utmparam]: value };
    }, {});

    cookieValues.utmcsr =
      source ||
      sourceCodeMapping.find(
        ({ from, medium }) =>
          from?.toLocaleLowerCase() ===
          cookieValues?.utmcsr?.toLocaleLowerCase() &&
          (medium?.toLocaleLowerCase() ===
            cookieValues?.utmcmd?.toLocaleLowerCase() ||
            medium?.toLocaleLowerCase() === utmMediumValue?.toLocaleLowerCase())
      )?.to ||
      DEFAULT_SOURCE_VALUE;
  } else if (queryStringObject) {
    cookieValues = {
      ...cookieValues,
      ...queryStringObject,
      utmcsr: source ?? DEFAULT_SOURCE_VALUE,
    };
  }

  return cookieValues;
};

const clearQueryStringParam = (param: string) =>
  param.replace("[", "").replace("]", "");
const clearCookieParam = (param: string) =>
  param.replace("{", "").replace("}", "");
const queryStringRegex = /\[[^\]\[]*\]/g;
const cookieRegex = /\{[^\}\{]*\}/g;

const handleSingleValue = (value: string) => {
  // take the '__utmzz' cookie and get the 'utmcmd' subkey value
  // {__utmzz|utmcmd}

  // get the entire '__utmzz' cookie value
  // {__utmzz}

  // get the '__utmzz' query param value
  // [__utmzz]

  // you can use all of them together
  // {__utmzz|utmcmd},[__utmzz]-{__utmzz}

  try {
    const queryStringFound =
      value && typeof value === "string" && value.match(queryStringRegex);
    const queryStringObject: any = queryString.parse(location.search);
    const cookieFound =
      value && typeof value === "string" && value.match(cookieRegex);
    const valuesToReplace = [
      { value: "(not set)", replaceWith: "" },
      { value: "(none)", replaceWith: "" },
      { value: /([(])(.[^()]*)([)])/g, replaceWith: "$2" }, // this regex replace strings like (whatever) with whatever
    ];

    const valueWithCookie = cookieFound
      ? cookieFound.reduce((acc, current) => {
        const [key, subKey] = current.split("|").map(clearCookieParam);

        return !subKey
          ? acc.replace(current, getCookie(clearCookieParam(current)) || "")
          : acc.replace(
            current,
            parseAnalyticsCookie(clearCookieParam(key))[subKey]
          );
      }, value)
      : value;

    const valueWithQuerystring = queryStringFound
      ? queryStringFound.reduce(
        (acc, current) =>
          acc.replace(
            current,
            queryStringObject[clearQueryStringParam(current)] || ""
          ),
        valueWithCookie
      )
      : valueWithCookie;

    return valuesToReplace.reduce(
      (acc, { value: localValue, replaceWith }) =>
        acc.replace(localValue, replaceWith),
      valueWithQuerystring
    );
  } catch (error) {
    return value;
  }
};


/**
 * Cycles through all the fields and runs specific validation rules for each given its type
 *
 * @param values Array of values which contain the field id and its value
 * @param formFields Array with all the fields and their properties
 */
const fieldValidation = (
  values: Record<string, string>,
  formFields: Array<IFormElement>
) => {
  const errors: Record<string, string> = {};

  // For every field in the form
  formFields.map((field: any) => {
    if (field.type === "section") return;
    field.valid = true;

    // TODO: hardfix for forms.
    if (
      field.name == "your_towncity" &&
      (values[field.id].length >= field.maxlength ||
        values[field.id].length <= field.minlength)
    ) {
      field.valid = false;
      errors[field.id] = INVALID_LENGTH_OVER(field.maxlength);
    }
    // end TODO: hardfix for forms.

    if (!values[field.id] && !!Number(field.required)) {
      field.valid = false;
      errors[field.id] = FIELD_REQUIRED;
    } else {
      // Further validation specific to some fields
      switch (field.type) {
        case "email":
          if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values[field.id])
          ) {
            field.valid = false;
            errors[field.id] = INVALID_EMAIL;
          }
          break;
        case "phone":
          if (!phoneValidation(values[field.id], field.format || "")) {
            field.valid = false;
            errors[field.id] = INVALID_PHONE;
          }
        default:
          break;
      }
    }
  });
  return errors;
};

/**
 * Runs a different phone validation rule for each specific country
 */
function phoneValidation(phoneNumber: string, country: string) {
  switch (country) {
    case "US":
      if (
        !/^(\+1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/.test(
          phoneNumber
        )
      ) {
        return false;
      }
    default:
      break;
  }
  return true;
}

/**
 * Adds the field_ prefix for every form value for formstack submission
 */
const convertDataForSubmission = (values: Record<string, string>) =>
  Object.entries(values).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [`field_${key}`]: handleSingleValue(value),
    }),
    {}
  );

/**
 * Parses the form data and nests the fields it into arrays/pages if the form is multipart
 * Whenever it detects a section creates a new Array/Page. If no sections are available no nesting is made
 */
const createFormPages = (fieldsData: Array<IFormElement>) => {
  return fieldsData.reduce(
    (acc: { total: any; currentSection: number }, currValue) => {
      if (currValue.type === "section") {
        return {
          ...acc,
          total: [...acc.total, [currValue]],
          currentSection: acc.currentSection + 1,
        };
      }

      if (acc.currentSection >= 0) {
        const tempTotal = [...acc.total];
        tempTotal[acc.currentSection].push(currValue);
        return {
          ...acc,
          total: tempTotal,
        };
      }

      return {
        ...acc,
        total: [...acc.total, currValue],
      };
    },
    { total: [], currentSection: -1 }
  ).total;
};

export {
  createFormikInitialValues,
  convertDataForSubmission,
  fieldValidation,
  createFormPages,
};
