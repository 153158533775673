import React from 'react';
import Form from '~components/web-ui/form/form';
import Loadable from 'react-loadable';
import IconNoteOutlineComponent from '@ef-global/web-ui-react/lib/icons/IconNoteOutline';
import IconAcademicMaterialComponent from '@ef-global/web-ui-react/lib/icons/IconAcademicMaterial';
import IconPersonOutlineComponent from '@ef-global/web-ui-react/lib/icons/IconPersonOutline';

const ReactModal = Loadable({
  loader: async () => {
    const {Modal: component} = await import(/* webpackChunkName: "Modal" */ '@ef-global/web-ui-react/lib/components/Modal');
    return component;
  },
  loading: () => <div/>
});

const ReactModalContent = Loadable({
  loader: async () => {
    const {Modal: component} = await import(/* webpackChunkName: "Modal" */ '@ef-global/web-ui-react/lib/components/Modal');
    return component.Content;
  },
  loading: () => <div/>
});

export default (
  {
    formId,
    submissionMessageSuccess,
    submissionMessageError,
    modalIsOpen,
    handleCloseModal,
    student: {name},
  }: any) => (
  <ReactModal
    className='ef-modal -m student-profile-form-modal'
    // @ts-ignore
    isOpen={modalIsOpen}
    onRequestClose={handleCloseModal}
    overlayClassName={{
      afterOpen: 'ef-modal__overlay--after-open',
      base: 'ef-modal__overlay -lightbox',
      beforeClose: 'ef-modal__overlay--before-close',
    }}
    htmlOpenClassName='ef-modal--active'
    ariaHideApp={false}
    closeTimeoutMS={150}
  >
    <button className='ef-modal__dismiss' onClick={handleCloseModal}/>
    <ReactModalContent className='u-p-0'>
      <div className='ef-row modal-content'>
        <div className='ef-col student-form-container u-s-p-m u-m-p-xl modal-box-shadow'>
          <h4>Tell me more about {name}</h4>
          <ul>
            <li><IconAcademicMaterialComponent/> See detailed student profile</li>
            <li><IconNoteOutlineComponent/> Read personal letter to host family</li>
            <li><IconPersonOutlineComponent/> Access matching experts</li>
          </ul>
          <Form
            form_id={formId}
            submission_message_success={submissionMessageSuccess}
            submission_message_error={submissionMessageError}
            spacing_between_elements='u-mb-s'
          />
        </div>
      </div>
    </ReactModalContent>
  </ReactModal>
)
