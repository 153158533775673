import {Accordion as WebUIAccordion} from '@ef-global/web-ui-react/lib/components/Accordion';
import React from 'react';
import {IStoryblokColorPicker, IStoryBlokComponent} from '~interfaces/storyblock';
import Markdown from '~components/custom/markdown';
import * as accordionStyles from './accordion-item.module.scss'
import Components from '~components/components';
import {css} from '@emotion/core';

export interface IAccordionItem extends IStoryBlokComponent {
  title: string;
  title_header: string;
  content: string;
  blok_content: IStoryBlokComponent[];
  custom_toggle_text: string;
  divider_color: IStoryblokColorPicker;
  text_color: IStoryblokColorPicker;
}

const AccordionItem = (props: IAccordionItem) => {
  const {title, title_header, content, custom_toggle_text, blok_content, story, divider_color, text_color} = props;
  const toggleText = custom_toggle_text ?
    custom_toggle_text.split(',').map(text => text.trim())
    : ''

  return (
    <WebUIAccordion.Item
      css={css`
        border-bottom: 1px solid ${divider_color?.color ?? 'inherit'};
        color: ${text_color?.color ?? 'inherit'};
      `}
      className={`${custom_toggle_text ? accordionStyles.customToggleText : ''} ${accordionStyles.inlineTable}`}
    >
      <WebUIAccordion.ItemTitle
        as={title_header as any}
        icon={true}
        css={css`
          color: ${text_color?.color ?? 'inherit'};
        `}
      >
        {title}
        {
          custom_toggle_text ? (
            <WebUIAccordion.ItemTitleToggle text={toggleText[1] ?? ''}>
              {toggleText[0] ?? ''}
            </WebUIAccordion.ItemTitleToggle>
          ) : null
        }
      </WebUIAccordion.ItemTitle>
      <WebUIAccordion.ItemContent
        css={css`
          color: ${text_color?.color ?? 'inherit'};
        `}
      >
        {/* Keep legacy string content to avoid having to update every single component */}
        {content !== '' && typeof content !== 'undefined'
          ? <Markdown source={content}/>
          : blok_content && blok_content.map((blok) => (Components(blok, story)))}
      </WebUIAccordion.ItemContent>
    </WebUIAccordion.Item>
  );
};

export default AccordionItem;
