import { BreakpointContext } from "@ef-global/web-ui-react/lib/components/BreakpointContext";
import { css } from "@emotion/core";
import React, { useState } from "react";
import Components from "~components/components";
import FocusPointBg from "~components/utils/focusPointBg";
import FocusPointImg from "~components/utils/focusPointImg";
import Link from "~components/utils/link";
import { AspectRatio, ITitle } from "~interfaces/components";
import {
  IFocusPoint,
  IStoryblokColorPicker,
  IStoryBlokComponent,
  IStoryblokLink,
} from "~interfaces/storyblock";
import VideoCard from "./video-card";
import Markdown from "~components/custom/markdown";
import { scroll } from "~components/utils/scroll";

interface IGUDCard extends IStoryBlokComponent {
  vertical: string[];
  link: IStoryblokLink;
  title: string;
  description: string;
  image: IFocusPoint;
  id?: string;
  open_in_a_new_tab: boolean;
}

const isVertical = (options: string[] = [], currentBreakpoint: string) => {
  if (options.length > 0) {
    if (currentBreakpoint === "s" && options.find((item) => item === "s")) {
      return "-vertical";
    }
    if (currentBreakpoint !== "s" && options.find((item) => item === "m")) {
      return "-vertical";
    }
  }
  return "";
};

const setRatio = (ratio: Array<number>) => {
  if (ratio && ratio.length > 0) {
    return `-ratio-${ratio[0]}-${ratio[1]}`;
  }
  return "";
};

interface ICustomCardProps extends IStoryBlokComponent, ITitle {
  background_color: IStoryblokColorPicker;
  border_size: number;
  border_color: IStoryblokColorPicker;
  link: IStoryblokLink;
  open_in_a_new_tab: boolean;
  rounded_corners: number;
  content: Array<any>;
  background_image?: IFocusPoint;
  action?: any;
  hide_card_shadow: boolean;
  height_desktop: string | null;
  height_mobile: string | null;
  content_align: string | null;
  scroll_to: any;
}

/**
 * Card with only title and a content block
 */
const customCard = ({
  content,
  story,
  background_color,
  border_size,
  border_color,
  link,
  open_in_a_new_tab,
  rounded_corners,
  background_image,
  action,
  hide_card_shadow,
  height_desktop = null,
  height_mobile = null,
  scroll_to,
}: ICustomCardProps) => {
  const cardScrollTo = css`
    cursor: ${link?.url || link?.cached_url || scroll_to
      ? `pointer`
      : `default`};
  `;
  const cardWrapper = css`
    padding: 32px; // Current value for all viewports
    display: block;
    background-color: ${background_color?.color ?? "#fff"};
    border: ${border_size ?? 0}px solid ${border_color?.color ?? "#fff"};
    border-radius: ${rounded_corners ?? 0}px;
    ${!!height_desktop &&
    "display: flex;flex-direction:column;min-height:" + height_desktop + ";"}
    ${!!height_desktop && "max-height:" + height_desktop + ";"}
    @media (max-width: 768px) {
      ${!!height_mobile &&
      "display: flex;flex-direction:column;min-height:" + height_mobile + ";"}
      ${!!height_mobile && "max-height:" + height_mobile + ";"}
    }

    > .ef-row {
      flex: 1;
    }
  `;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const renderCustomContent = () => {
    return (
      <div className={`ef-card`}>
        <article
          className={`ef-card--content ${hide_card_shadow ? "-no-shadow" : ""}`}
          css={cardWrapper}
        >
          {background_image?.image && (
            <FocusPointBg
              focusPoint={background_image}
              alt={``}
              style={{
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: `${
                  background_image?.focusPoint?.x ?? "50"
                }% ${background_image?.focusPoint?.y ?? "50"}%`,
              }}
              className="ef-card--teaser__img"
              aspectRatio={1}
            />
          )}
          {content && content.map((blok) => Components(blok, story))}
        </article>
      </div>
    );
  };

  if (scroll_to) {
    return (
      <div
        css={cardScrollTo}
        onClick={() => {
          if (scroll_to) {
            scroll(scroll_to);
          }
        }}
      >
        {renderCustomContent()}
      </div>
    );
  }

  return (link?.url === "" && link?.cached_url === "") ||
    (link?.cached_url || "").replace(new RegExp(/\//, "g"), "") ===
      process.env.GATSBY_MARKET ? (
    <>
      {action && action[0]
        ? Components({ ...action[0], open, handleOpen, handleClose }, story)
        : null}
      {action && action[0] ? (
        <a onClick={() => handleOpen()} style={{ cursor: "pointer" }}>
          {renderCustomContent()}
        </a>
      ) : (
        renderCustomContent()
      )}
    </>
  ) : (
    <Link
      storyblokLink={link}
      openInANewTab={open_in_a_new_tab}
      style={{ textDecoration: "none" }}
    >
      {renderCustomContent()}
    </Link>
  );
};

interface IContentCardProps extends IGUDCard {
  outline: boolean;
  link_arrow: boolean;
  shadow: boolean;
  link_text: string;
  title_size: number;
  text_color: any;
  action?: any;
}

/**
 * Content card from https://code.ef.design/components/cards?version=css
 */
const contentCard = ({
  image,
  description,
  title,
  link_arrow,
  outline,
  shadow,
  vertical,
  link,
  link_text,
  id,
  open_in_a_new_tab,
  title_size = 24,
  text_color = { color: "#191919" },
  action,
  story,
}: IContentCardProps) => {
  const { currentBp } = React.useContext(BreakpointContext);

  const efCardColor = css`
    .ef-card--content__content,
    .ef-card--content__title,
    .ef-card--content__text,
    .ef-card--content__text p,
    .ef-card--content__link {
      color: ${text_color?.color};
    }
  `;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const renderContent = () => {
    return (
      <div
        className={
          "full-height" +
          " ef-card--content" +
          `${outline ? " -outline" : ""}` +
          `${!shadow ? " -no-shadow" : ""}` +
          ` ${isVertical(vertical, currentBp)}`
        }
      >
        {image.image && (
          <FocusPointBg
            customTag="div"
            focusPoint={image}
            className="ef-card--content__img"
            tabIndex={-1}
          />
        )}
        <div className="ef-card--content__inner" css={efCardColor}>
          <div className="ef-card--content__content">
            <h4
              className="ef-card--content__title"
              style={{ fontSize: title_size + "px" }}
            >
              {title}
            </h4>
            <div className="ef-card--content__text">
              <Markdown source={description} />
            </div>
            {link_arrow ? (
              <span className="ef-card--content__link ef-link--arrow -block">
                {link_text}
              </span>
            ) : (
              <p className="ef-card--content__link">{link_text}</p>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (link?.url === "" && link?.cached_url === "") ||
    (link?.cached_url || "").replace(new RegExp(/\//, "g"), "") ===
      process.env.GATSBY_MARKET ? (
    <>
      {action && action[0]
        ? Components({ ...action[0], open, handleOpen, handleClose }, story)
        : null}
      {action && action[0] ? (
        <a onClick={() => handleOpen()} style={{ cursor: "pointer" }}>
          {renderContent()}
        </a>
      ) : (
        renderContent()
      )}
    </>
  ) : (
    <Link
      storyblokLink={link}
      id={id}
      style={{
        textDecoration: "none",
      }}
      openInANewTab={open_in_a_new_tab}
    >
      {renderContent()}
    </Link>
  );
};

interface ITeaserCardProps extends IGUDCard {
  gradient: string;
  small: boolean;
  action: any;
  story: any;
}

/**
 * Teaser card from https://code.ef.design/components/cards?version=css
 */
const teaserCard = ({
  link,
  image,
  title,
  description,
  small,
  vertical,
  gradient = "",
  open_in_a_new_tab,
  action,
  story,
}: ITeaserCardProps) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { currentBp } = React.useContext(BreakpointContext);
  const cardIsVertical = isVertical(vertical, currentBp);
  const verticalAspectRatio = 9 / 16;
  const originalAspectRatio = 1;

  const renderCustomContent: any = () => (
    <Link
      className={
        "ef-card--teaser" +
        ` ${small ? " -small" : ""}` +
        ` ${cardIsVertical}` +
        ` ${gradient}`
      }
      storyblokLink={link as any}
      openInANewTab={open_in_a_new_tab}
    >
      {image.image && (
        <FocusPointImg
          focusPoint={image}
          alt={title}
          className="ef-card--teaser__img"
          aspectRatio={
            cardIsVertical ? verticalAspectRatio : originalAspectRatio
          }
        />
      )}
      <div className="ef-card--teaser__inner">
        <div className="ef-card--teaser__content">
          <h4 className="ef-card--teaser__title">{title}</h4>
          <div className="ef-card--teaser__text">
            <Markdown source={description} />
          </div>
        </div>
      </div>
    </Link>
  );

  return action ? (
    <>
      {action && action[0]
        ? Components({ ...action[0], open, handleOpen, handleClose }, story)
        : null}
      {action && action[0] ? (
        <a onClick={() => handleOpen()} style={{ cursor: "pointer" }}>
          {renderCustomContent()}
        </a>
      ) : (
        renderCustomContent()
      )}
    </>
  ) : (
    renderCustomContent()
  );
};

interface IAdvancedTeaserCardProps extends IGUDCard {
  gradient: string;
  small: boolean;
  aspect_ratio: AspectRatio;
  aspect_ratio_mobile: AspectRatio;
  text_color?: { color?: string };
  action: any;
  story: any;
}

/**
 *
 * Advanced Teaser card
 */
const advancedTeaserCard = ({
  link,
  image,
  title,
  description,
  small,
  aspect_ratio,
  aspect_ratio_mobile,
  gradient = "",
  id,
  open_in_a_new_tab,
  text_color = { color: "#191919" },
  action,
  story,
}: IAdvancedTeaserCardProps) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { currentBp } = React.useContext(BreakpointContext);
  let imageRatio: Array<number> = [];
  let imageRatioMobile: Array<number> = [];

  const efCardColor = css`
    .ef-card--teaser__content,
    .ef-card--teaser__title,
    .ef-card--teaser__text,
    .ef-card--teaser__text p,
    .ef-card--teaser__link {
      color: ${text_color?.color};
    }
  `;

  if (image) {
    imageRatio =
      aspect_ratio === "original"
        ? [image.imageSize.width, image.imageSize.height]
        : aspect_ratio.split(":").map(Number);

    imageRatioMobile =
      aspect_ratio_mobile === "original"
        ? [image.imageSize.width, image.imageSize.height]
        : aspect_ratio_mobile.split(":").map(Number);
  }

  const renderCustomContent: any = () => (
    <Link
      id={id}
      className={
        "ef-card--teaser" +
        ` ${small ? " -small" : ""}` +
        ` ${
          currentBp === "s" ? setRatio(imageRatioMobile) : setRatio(imageRatio)
        }` +
        ` ${gradient}`
      }
      storyblokLink={link}
      openInANewTab={open_in_a_new_tab}
    >
      {image.image && (
        <FocusPointImg
          focusPoint={image}
          className="ef-card--teaser__img"
          alt={title}
          aspectRatio={[
            imageRatioMobile[0] / imageRatioMobile[1],
            imageRatio[0] / imageRatio[1],
          ]}
        />
      )}
      <div className="ef-card--teaser__inner" css={efCardColor}>
        <div className="ef-card--teaser__content">
          <h4 className="ef-card--teaser__title">{title}</h4>
          <div className="ef-card--teaser__text">
            <Markdown source={description} />
          </div>
        </div>
      </div>
    </Link>
  );

  return action ? (
    <>
      {action && action[0]
        ? Components({ ...action[0], open, handleOpen, handleClose }, story)
        : null}
      {action && action[0] ? (
        <a onClick={() => handleOpen()} style={{ cursor: "pointer" }}>
          {renderCustomContent()}
        </a>
      ) : (
        renderCustomContent()
      )}
    </>
  ) : (
    renderCustomContent()
  );
};

/**
 * Verifies the card type and returns the correct card component
 */
const Card = (
  props: ICustomCardProps | ITeaserCardProps | IContentCardProps
) => {
  switch (props.component) {
    case "custom-card":
      return customCard(props as ICustomCardProps);
    case "teaser-card":
      return teaserCard(props as ITeaserCardProps);
    case "advanced-teaser-card":
      return advancedTeaserCard(props as IAdvancedTeaserCardProps);
    case "content-card":
      return contentCard(props as IContentCardProps);
    case "video-card":
      return <VideoCard {...(props as any)} />;
    default:
      // tslint:disable-next-line:no-console
      console.log("This card is not implemented");
      return <div />;
  }
};

export default Card;
