import React from "react";
import { isGatsbyProduction } from "../../utils/env-helpers";

const apiPrefix = "/api/v1/";
const apiProductionURL = "https://hseystudentapi.azurewebsites.net";
const apiDevURL = "https://hseystudentapidev.azurewebsites.net";
export const API_SETTINGS = {
  prefix: apiPrefix,
  url: (isGatsbyProduction() ? apiProductionURL : apiDevURL) + apiPrefix,
  method: "GET",
  headers: { "Content-Type": "application/json" },
};

export const MAXIMUM_STUDENTS_SIZE_RESPONSE = 50;
export const GENDERS = {
  male: "male",
  female: "female",
};

export const FLAGS_PATH = "/images/flags/";
export const getBackgroundImagePath = (countryCode: string | undefined) =>
  `/images/students/backgrounds/${countryCode}.jpg`;
export const getAvatarDefaultProfile = () => "/images/iec-avatar-default.png";
export const MOBILE_BREAKPOINTS = ["s"];
export const TABLET_BREAKPOINTS = ["m"];
export const GRIS_SYSTEM_NO_OF_COLUMNS = 12;

export const RESOLUTIONS: {
  mobile: number;
  tablet: number;
  desktop: number;
} = {
  mobile: 1,
  tablet: 2,
  desktop: 3,
};

export interface DefaultValuesInterface {
  noOfInterests: number;
  noOfColumnsMobile: number;
  noOfStudentsPerPageMobile: number;
  noOfColumnsTablet: number;
  noOfStudentsPerPageTablet: number;
  noOfColumns: number;
  noOfStudentsPerPage: number;
  minAge: string;
  maxAge: string;
  chooseACountryTitle: string;
  filterTitle: string;
  personalLetterVisibleCharacters: number;
}

export const DEFAULT_VALUES: DefaultValuesInterface = {
  noOfInterests: 4,
  noOfColumnsMobile: 1,
  noOfStudentsPerPageMobile: 9,
  noOfColumnsTablet: 2,
  noOfStudentsPerPageTablet: 9,
  noOfColumns: 3,
  noOfStudentsPerPage: 9,
  minAge: "15",
  maxAge: "18",
  chooseACountryTitle: "Choose a country",
  filterTitle: "Filter by gender and age",
  personalLetterVisibleCharacters: 750,
};

export const NO_OP = () => ({});
export const EMPTY_OPTION = <option key="empty-option" value="" />;
