import React from "react";
import { ISpacing } from "~/src/interfaces/components";
import Components from "~components/components";
import Carousel from "~components/web-ui-react/carousel";
import { IStoryBlokComponent } from "~interfaces/storyblock";
import { createSpacingClass } from "~/../components/src/components/utils/spacing";

interface IGallery extends IStoryBlokComponent {
  centeredSlides: boolean;
  carouselOnDesktop: boolean;
  galleryItemsPerRow: string;
  items: Array<any>;
  keep_aspect_ratio_desktop: boolean;
  dots_color: any;
  gallery_spacing: ISpacing[];
}

const splitArray = (array: any[], itemsPerSplittedArray: number) => {
  const splittedArray = [];
  let tempArray = [];
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    tempArray.push(element);

    if (
      tempArray.length === itemsPerSplittedArray ||
      index === array.length - 1
    ) {
      splittedArray.push(tempArray);
      tempArray = [];
    }
  }

  return splittedArray;
};

const Gallery = (props: IGallery) => {
  const {
    galleryItemsPerRow,
    items,
    carouselOnDesktop,
    keep_aspect_ratio_desktop,
    dots_color = { color: "#191919" },
    gallery_spacing,
  } = props;

  const spacing = createSpacingClass(gallery_spacing);
  const numberItemsPerRow = Number(galleryItemsPerRow);

  const itemsNormalized =
    items.length > 0 ? splitArray(items, numberItemsPerRow) : [];

  return items.length > 0 ? (
    <>
      <div className={`u-show-s-only ${spacing}`}>
        <Carousel {...props} dotsColor={dots_color?.color} />
      </div>
      <div className={`u-show-m-up ${spacing}`}>
        {carouselOnDesktop ? (
          <Carousel {...props} dotsColor={dots_color?.color} />
        ) : (
          items.length > 0 &&
          itemsNormalized.map((subArray, index) => (
            <div
              className="ef-row"
              style={{ marginBottom: "16px" }}
              key={index}
            >
              {subArray.map((blok) => (
                <div
                  className="ef-col"
                  key={blok._uid}
                  style={{
                    flex: keep_aspect_ratio_desktop
                      ? `0 0 ${100 / numberItemsPerRow}%`
                      : "",
                  }}
                >
                  {Components(blok, props.story)}
                </div>
              ))}
            </div>
          ))
        )}
      </div>
    </>
  ) : (
    <div>Add items to gallery</div>
  );
};

export default Gallery;
