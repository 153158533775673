/** @jsx jsx */
import { jsx } from '@emotion/core'
import { createContext, useState, useEffect } from 'react'
import { debounce } from './helpers/helpers'

interface BreakpointProvider extends React.FC<{}> {}

const BreakpointContext = createContext({ currentBp: '' })

const getCurrentBp = (): string => {
  return window
    .getComputedStyle(document.body, '::before')
    .getPropertyValue('content')
    .replace(/"/g, '')
}

const BreakpointProvider: BreakpointProvider = ({ children }) => {
  const [dimensions, setDimensions] = useState({
    currentBp: '',
  })

  useEffect(
    () => {
      const handleResize = (): void => {
        if (dimensions.currentBp !== getCurrentBp()) {
          setDimensions({
            currentBp: getCurrentBp(),
          })
        }
        dimensions.currentBp === 'none' && setTimeout(handleResize, 250)
      }
      const debouncedHandleResize = debounce(handleResize, 100)
      debouncedHandleResize()
      window.addEventListener('resize', debouncedHandleResize)
      return (): void => {
        window.removeEventListener('resize', debouncedHandleResize)
      }
    },
    [dimensions.currentBp]
  )

  return (
    <BreakpointContext.Provider value={dimensions}>
      {children}
    </BreakpointContext.Provider>
  )
}

export { BreakpointContext, BreakpointProvider }
