import React from 'react'
import Loadable from 'react-loadable'

const WhatsappShareButton = Loadable({
  loader: () => import('react-share'),
  render(loaded, props) {
    let Component = loaded.WhatsappShareButton;
    return <Component {...props}/>;
  },
  loading: () => <div/>
});

export const Whatsapp = (props: any) => {
  const {url, ...rest} = props
  return (
    <WhatsappShareButton url={url} {...rest}>
      <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32'>
        <g fill='none' fillRule='evenodd'>
          <circle
            cx='16'
            cy='16'
            r='15.5'
            stroke='#191919'
            strokeOpacity='0.2'
          />
          <circle
            cx='16'
            cy='16'
            r='15.5'
            stroke='#191919'
            strokeOpacity='0.2'
          />
          <path
            fill='#191919'
            fillRule='nonzero'
            d='M8 23.906l1.124-4.084a7.84 7.84 0 01-1.058-3.94C8.068 11.535 11.621 8 15.986 8a7.887 7.887 0 015.602 2.312 7.814 7.814 0 012.318 5.575c-.002 4.346-3.555 7.882-7.92 7.882h-.003a7.946 7.946 0 01-3.785-.96L8 23.907z'
          />
          <path
            fill='#FFF'
            d='M14.03 12.512c-.153-.335-.314-.341-.46-.347-.12-.005-.256-.005-.392-.005a.758.758 0 00-.546.252c-.188.201-.717.688-.717 1.678s.734 1.947.836 2.081c.103.135 1.416 2.231 3.497 3.038 1.73.67 2.081.537 2.456.504.376-.034 1.211-.487 1.382-.957.17-.47.17-.873.12-.957-.052-.084-.188-.134-.393-.235-.205-.1-1.211-.587-1.399-.654-.187-.067-.324-.101-.46.1-.137.202-.529.655-.648.79-.12.134-.24.15-.444.05-.205-.101-.864-.313-1.646-1a6.078 6.078 0 01-1.139-1.393c-.12-.2-.013-.31.09-.41.092-.09.205-.235.307-.353.102-.117.136-.201.205-.335.068-.135.034-.252-.017-.353-.052-.1-.45-1.096-.631-1.494z'
          />
          <path
            fill='#191919'
            fillRule='nonzero'
            d='M8 23.906l1.124-4.084a7.84 7.84 0 01-1.058-3.94C8.068 11.535 11.621 8 15.986 8a7.887 7.887 0 015.602 2.312 7.814 7.814 0 012.318 5.575c-.002 4.346-3.555 7.882-7.92 7.882h-.003a7.946 7.946 0 01-3.785-.96L8 23.907z'
          />
          <path
            fill='#FFF'
            d='M14.03 12.512c-.153-.335-.314-.341-.46-.347-.12-.005-.256-.005-.392-.005a.758.758 0 00-.546.252c-.188.201-.717.688-.717 1.678s.734 1.947.836 2.081c.103.135 1.416 2.231 3.497 3.038 1.73.67 2.081.537 2.456.504.376-.034 1.211-.487 1.382-.957.17-.47.17-.873.12-.957-.052-.084-.188-.134-.393-.235-.205-.1-1.211-.587-1.399-.654-.187-.067-.324-.101-.46.1-.137.202-.529.655-.648.79-.12.134-.24.15-.444.05-.205-.101-.864-.313-1.646-1a6.078 6.078 0 01-1.139-1.393c-.12-.2-.013-.31.09-.41.092-.09.205-.235.307-.353.102-.117.136-.201.205-.335.068-.135.034-.252-.017-.353-.052-.1-.45-1.096-.631-1.494z'
          />
        </g>
      </svg>
    </WhatsappShareButton>
  )
}
