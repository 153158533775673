import { css } from "@emotion/core";
import React from "react";
import Components from "~components/components";
import { createSpacingClass } from "~components/utils/spacing";
import { ISpacing } from "~interfaces/components";
import {
  IStoryblokColorPicker,
  IStoryBlokComponent,
} from "~interfaces/storyblock";
import "./section.scss";

interface ISectionProps extends IStoryBlokComponent {
  background: { color: string };
  border: string;
  components_vertical_spacing: string;
  content: any;
  // header_customizer
  full_width: boolean;
  id: string;
  spacing: Array<ISpacing>;
  title: string;
  title_size: string;
  visual_title_size: string;
  title_custom_text_color: IStoryblokColorPicker;
  title_spacing: Array<ISpacing>;
  rounded_corners: boolean;
  overlay_height_mobile?: number;
  overlay_height_desktop?: number;
  gradient?: string;
  zIndex: string;
  hide_in: string;
}

const Section: React.FC<ISectionProps> = (props) => {
  const { zIndex, hide_in } = props;
  const spacing = createSpacingClass(props.spacing);

  let componentsVerticalSpacingClass = "";
  const titleSpacingClass = createSpacingClass(props.title_spacing);
  switch (props.components_vertical_spacing) {
    case "small":
      componentsVerticalSpacingClass = "-components-s";
      break;
    case "gutter":
      componentsVerticalSpacingClass = "-components-gutter";
      break;
    case "medium":
      componentsVerticalSpacingClass = "-components-m";
      break;
    case "large":
      componentsVerticalSpacingClass = "-components-l";
      break;
    case "collapse":
    default:
      componentsVerticalSpacingClass = "";
  }
  const gradientClass = props.gradient ? props.gradient : "";

  const sectionStyle = css`
    background-color: ${props.background?.color};
    border-radius: ${props.rounded_corners ? "10px" : ""};
    margin-top: -${props.overlay_height_mobile || 0}px;
    ${!!zIndex && `z-index: ${zIndex};`}
    @media screen and (min-width: 1024px) {
      margin-top: -${props.overlay_height_desktop || 0}px;
    }
    ${!!props.title_custom_text_color?.color &&
    `.ttlx {
      color: ${props.title_custom_text_color?.color};
    }`}

    // Hide based on region & market (SB translatable)
    display: ${hide_in === "desktop" || hide_in === "all" ? "none" : "block"};
    @media screen and (max-width: 767px) {
      display: ${hide_in === "mobile" || hide_in === "all" ? "none" : "block"};
    }
  `;

  const TitleTag: any = `h${props.title_size}`;

  return (
    <section
      className={
        `ef-section ${spacing}` +
        ` ${componentsVerticalSpacingClass}` +
        ` ${gradientClass}` +
        ` ${props.border ? "ef-section__border" : ""}`
      }
      id={props.id}
      css={sectionStyle}
    >
      {/**
       *
       *
       *
       *
       * header_customizer space
       * not used for now, but reserved
       * for future requirements.
       *
       *
       *
       */}
      <div className={`${!props.full_width ? "ef-container" : ""}`}>
        {props.title && (
          <TitleTag
            className={`ttlx ef-h${props.visual_title_size} ${titleSpacingClass}`}
            id={props._uid}
          >
            {props.title}
          </TitleTag>
        )}
        {props.content !== undefined &&
          props.content.map((blok: IStoryBlokComponent) => (
            <div
              className={`ef-section__component ${props.full_width ? "-full-width" : ""
                }`}
              key={blok._uid}
            >
              {Components(blok, props.story)}
              {props.children}
            </div>
          ))}
      </div>
    </section>
  );
};

export default Section;
