import React from 'react'
import Loadable from 'react-loadable'

const LineShareButton = Loadable({
  loader: () => import('react-share'),
  render(loaded, props) {
    let Component = loaded.LineShareButton;
    return <Component {...props}/>;
  },
  loading: () => <div/>
});

export const Line = (props: any) => {
  const {url, ...rest} = props
  return (
    <LineShareButton url={url} {...rest}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
        <g fill="none" fillRule="evenodd">
          <circle
            cx="16"
            cy="16"
            r="15.5"
            stroke="#191919"
            strokeOpacity="0.2"
          />
          <path
            fill="#191919"
            d="M25.685 15.475c0-4.302-4.312-7.801-9.613-7.801-5.3 0-9.612 3.5-9.612 7.8 0 3.857 3.42 7.086 8.039 7.697.313.067.739.206.847.474.096.243.063.623.03.869l-.137.823c-.041.243-.193.95.833.518 1.026-.432 5.536-3.26 7.553-5.58 1.392-1.529 2.06-3.079 2.06-4.8"
          />
          <path
            fill="#FFF"
            d="M14.12 13.396h-.675a.187.187 0 00-.187.187v4.188c0 .103.083.187.187.187h.674a.187.187 0 00.187-.187v-4.188a.187.187 0 00-.187-.187m4.641 0h-.674a.187.187 0 00-.188.187v2.488L15.98 13.48a.158.158 0 00-.016-.02l-.01-.012a.105.105 0 00-.013-.011l-.006-.004-.01-.007-.006-.003a.21.21 0 00-.01-.006l-.006-.002-.01-.005-.006-.002a.606.606 0 00-.011-.004l-.007-.001-.01-.003a.09.09 0 00-.009 0c-.003-.002-.006-.002-.01-.002h-.01l-.006-.001h-.674a.187.187 0 00-.187.187v4.188c0 .103.084.187.187.187h.674a.187.187 0 00.188-.187v-2.487l1.921 2.595a.19.19 0 00.05.048l.011.007.006.003.009.004.009.004.005.002.013.004h.003a.181.181 0 00.048.007h.674a.187.187 0 00.187-.187v-4.188a.187.187 0 00-.187-.187m-6.267 3.513h-1.832v-3.327a.187.187 0 00-.187-.187H9.8a.187.187 0 00-.187.187v4.188c0 .05.02.096.052.13l.003.002.003.003a.186.186 0 00.129.052h2.694a.187.187 0 00.187-.187v-.675a.187.187 0 00-.187-.187m9.989-2.464a.187.187 0 00.187-.187v-.675a.187.187 0 00-.187-.187H19.79a.185.185 0 00-.13.053l-.002.002a.186.186 0 00-.055.132v4.188a.186.186 0 00.055.133l.002.002a.186.186 0 00.13.052h2.693a.187.187 0 00.187-.187v-.674a.187.187 0 00-.187-.188h-1.832v-.708h1.832a.187.187 0 00.187-.187v-.674a.187.187 0 00-.187-.187h-1.832v-.708h1.832z"
          />
        </g>
      </svg>
    </LineShareButton>
  )
}
