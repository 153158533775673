import React, { useState, useEffect } from "react";
import SbEditable from "storyblok-react";
import { Modal } from "@ef-global/web-ui-react/lib/components/Modal";
import Components from "~components/components";
import { createSpacingClass } from "~components/utils/spacing";

const MODAL_KEY = "modal";

export const getHref = () => {
  if (typeof window !== "undefined") return window?.location?.href;
  return "";
};

const ActionModal = (props: any) => {
  const randomGeneratedId =
    "mod_" + Math.floor(Math.random() * (500 - 1 + 1) + 1);
  const {
    type,
    size,
    content,
    position,
    show_content,
    story,
    open,
    handleOpen,
    handleClose,
    style,
    modalTitle = "",
    modal_title = "",
    contentSpacing = null,
    modal_uri_ref = null,
    modal_enable_close_listener = false,
  }: any = props;

  const spacing = createSpacingClass(contentSpacing);
  const [loaded, setLoaded] = useState(false);

  // Use effect to trigger show content (storyblok)
  useEffect(() => {
    if (getHref().includes("editor")) {
      show_content ? handleOpen() : handleClose();
    }
  }, [show_content]);

  // Use effect to control - add or remove- url parameter
  // when opening or closing the modal.
  useEffect(() => {
    if (typeof window !== undefined && modal_uri_ref) {
      // @ts-ignore
      const urlParams: any = new URLSearchParams(window.location.search);
      const url = window.location.href.split("?")[0];
      if (open) {
        urlParams.set(MODAL_KEY, modal_uri_ref);
        window.history.pushState(
          null,
          "",
          url + "?" + decodeURIComponent(urlParams.toString())
        );
      } else {
        if (loaded) {
          urlParams.delete(MODAL_KEY);
          window.history.pushState(
            null,
            "",
            url + "?" + decodeURIComponent(urlParams.toString())
          );
        }
      }
    }

    // Allow to close or trigger close action on buttons inside modal
    if (modal_enable_close_listener) {
      setTimeout(() => {
        const buttonFound = document.querySelectorAll(
          `#${randomGeneratedId} button.ef-button`
        );
        if (buttonFound) {
          buttonFound?.forEach((button: any) => {
            button?.addEventListener("click", () => {
              if (!open) {
                handleOpen();
              } else {
                handleClose();
              }
            });
          });
        }
      }, 250);
    }
  }, [open]);

  // When component mounted && window is available
  // Check if parameter exist on URL to open modal.
  useEffect(() => {
    setLoaded(true);
    if (typeof window !== undefined && modal_uri_ref) {
      const urlParams: any = new URLSearchParams(window.location.search);
      if (
        urlParams.has(MODAL_KEY) &&
        urlParams.get(MODAL_KEY) == modal_uri_ref
      ) {
        handleOpen();
      }
    }
  }, []);

  return (
    <>
      <SbEditable content={content}>
          <Modal
            id={randomGeneratedId}
            lightbox={type === "lightbox"}
            top={position === "top"}
            bottom={position === "bottom"}
            open={open}
            onDismiss={() => handleClose()}
            size={size}
            task={type === "task"}
            css={style}
          >
            {type === "task" && (modalTitle || modal_title) ? (
              // @ts-ignore
              <Modal.Header>
                {/** @ts-ignore */}
                <Modal.Title>
                  {modal_title == "" ? modalTitle : modal_title}
                </Modal.Title>
              </Modal.Header>
            ) : null}
            {type === "lightbox" ? (
              content !== undefined &&
              content.map((blok: any) => {
                return Components(blok, story);
              })
            ) : (
              <Modal.Content
                className={`${spacing ? spacing : "default-modal-spacing"}`}
              >
                {content !== undefined &&
                  content.map((blok: any) => {
                    return Components(blok, story);
                  })}
              </Modal.Content>
            )}
          </Modal>
          <div className="nodisplay">
            {type === "task" && (modalTitle || modal_title) ? (
              // @ts-ignore
              <Modal.Header>
                {/** @ts-ignore */}
                <Modal.Title>
                  {modal_title == "" ? modalTitle : modal_title}
                </Modal.Title>
              </Modal.Header>
            ) : null}
            {type === "lightbox" ? (
              content !== undefined &&
              content.map((blok: any) => {
                return Components({...blok, videoNoDisplay: true}, story);
              })
            ) : (
              <Modal.Content
                className={`${spacing ? spacing : "default-modal-spacing"}`}
              >
                {content !== undefined &&
                  content.map((blok: any) => {
                    return Components({...blok, videoNoDisplay: true}, story);
                  })}
              </Modal.Content>
            )}
          </div>
      </SbEditable>
    </>
  );
};

export default ActionModal;
