import React from 'react';
import { Button } from '@ef-global/web-ui-react/lib/components/Button';

import { getFlagImageUrl } from './library'

export default ({ country, countryName, onChange, currentValue }: any) => (
    <Button
        className='flag'
        primary={currentValue === country}
        secondary={currentValue !== country}
        small
        onClick={onChange}
        id={country}
        // @ts-ignore
        value={country}
    >
        {country ? <img className='flag--image' alt={`${countryName} flag`} src={getFlagImageUrl(country)} /> : null}
        <span className='flag--name'>{countryName}</span>
    </Button>
)
