import React from 'react';
import { IFocusPoint, IStoryBlokComponent } from '~src/interfaces/storyblock';
import FocusPointBg from '~components/utils/focusPointBg';
import { IMarketData } from '../../interfaces';
import { buildAlignmentClass } from '../../../../utils/alignment';
import styles from './infomeeting-cards.module.scss';
import LiveCamera from '~assets/svg/live-camera.inline.svg';


interface ICardAlignmentItem {
  target_device: string;
  size: string;
}

export interface IInfomeetingCards extends IStoryBlokComponent {
  card_image: IFocusPoint;
  card_alignment_item: Array<ICardAlignmentItem>
  card_button_content: string;
  card_live_text: string;
  infomeetings: Array<IMarketData>;
  openModal: (index: number) => void;
}

/**
 * Renders the live badge
 */
const renderLiveBadge = (liveText: string) => {
  return (
    <span className={styles.infomeetingCardsLive}>
      <LiveCamera />
      <p>{liveText}</p>
    </span>
  )
}

const InfomeetingCards = (props: IInfomeetingCards) => {
  const {
    card_image,
    card_alignment_item,
    card_button_content,
    infomeetings,
    card_live_text,
    openModal
  } = props;
  const cardAlignmentClasses = card_alignment_item?.reduce((acc, current) => {
    const alignmentClass = acc + buildAlignmentClass(current) + ' ';
    return alignmentClass;
  }, ' ') || '';

  return (
    <div>
      <div className='ef-row'>
        {infomeetings?.map((marketData, index) => (
            <div
              className={`ef-col${cardAlignmentClasses} ${styles.infomeetingCards}`}
              key={`${index}-${marketData.date}`}
            >
              <a
                className={`ef-card--content ${styles.infomeetingCardsContent}`}
                onClick={() => openModal(index)}
              >
                {card_image?.image && (
                  <FocusPointBg
                    customTag='div'
                    focusPoint={card_image}
                    alt={'title'}
                    className='ef-card--content__img'
                  />
                )}
                {marketData.isLive && renderLiveBadge(card_live_text)}
                <div className='ef-card--content__inner'>
                  <div className='ef-card--content__content'>
                    {/* Date */}
                    <div className={styles.infomeetingCardsDate}>
                      <p className='u-mb-xs'>{marketData.date}</p>
                      <p>{marketData.duration}</p>
                    </div>
                    <h2
                      className={`ef-h4 ef-card--content__title u-mb-l`}
                    >
                      {marketData.title}
                    </h2>
                    <span className='ef-card--content__link ef-link--arrow -block'>{card_button_content}</span>
                  </div>
                </div>
              </a>
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default InfomeetingCards;
