import React from 'react';

import { IStoryBlokComponent } from '~interfaces/storyblock';
import Components from '~components/components';
import { css } from '@emotion/core';
import { IAnswerProps } from './answer';

export interface IQuestionProps extends IStoryBlokComponent {
  question_title: string;
  answers: Array<IAnswerProps>;
  background: {color: string};
}

const Question = (props: IQuestionProps) => {
  const { question_title, answers, story } = props;
  const QuestionStyle = css`
    background-color: ${props.background?.color};
    & > ef-row {
      justify-content: center;
    }

    & > h4 {
      width: 100%;
      max-width: 100%;
      text-align: center;
    }
  `;

  return (
    <article css={QuestionStyle} className='u-s-pb-m u-s-pt-m'>
      <h4 className='ef-card--teaser__title u-mb-l'>{question_title}</h4>
      <div id='question' className='ef-row'>
        {answers &&
          answers.map((blok) =>
            Components({ ...blok, question_uid: props._uid }, story)
          )}
      </div>
    </article>
  );
};

export default Question;
