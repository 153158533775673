import React, { useState, useEffect, useRef } from 'react';

const LazyLoader: React.SFC = (props: any) => {
  const [lazyLoad, setLazyLoad] = useState(false);
  const node = useRef(null);
  const { ...attributes } = props;

  function observerCallback(entries: IntersectionObserverEntry[]) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setLazyLoad(true);
      }
    })
  }

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {rootMargin: '0px'});
    if (node.current) observer.observe(node.current as unknown as Element);

    return () => observer.disconnect();
  }, [node.current])

  return (
    <div
      ref={node}
      {...attributes}
      style={{minHeight: '1px'}}
    >
      {lazyLoad && props.children}
    </div>
  )
}

export default LazyLoader;
