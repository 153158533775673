import React from 'react';
import { IFocusPoint } from '~src/interfaces/storyblock';
import { DESKTOP_BREAKPOINT } from './image-constants';
import { transformImageWithAspectRatio } from '~src/services/image-service';

enum IMG_DPI {
  '2x' = 1.3
}

/**
 * Creates the <source> elements for a responsive <picture>
 * Creates 2 source elements per picture, one with webp and another without
 * Each element has resolutions for more than 1 dpi
 */
const buildSources = (focusPoint: IFocusPoint, resolutions: Array<number>, aspectRatio?: Array<number> | number, size?: number) => {
  const sources = [];
  const isImgSvg = focusPoint.image.endsWith('.svg');

  if (isImgSvg) {
    return null;
  }

  for (let index = resolutions.length - 1; index > 0; index--) {
    const minWidth = resolutions[index - 1];
    const maxWidth = resolutions[index];
    const fixedSize = size ? size : maxWidth;
    const originalSize = focusPoint.imageSize;
    let ratio = (aspectRatio && typeof aspectRatio === 'number') ? aspectRatio : originalSize.width / originalSize.height;

    if (aspectRatio && Array.isArray(aspectRatio)) {
      // If it's mobile or desktop
      ratio = maxWidth < DESKTOP_BREAKPOINT ? aspectRatio[0] : aspectRatio[1];
    }

    sources.push(
      <React.Fragment key={minWidth}>
        <source
          media={`(min-width: ${minWidth}px)`}
          srcSet={`${transformImageWithAspectRatio(focusPoint, fixedSize, ratio, 'webp')} 1x, ${transformImageWithAspectRatio(focusPoint, fixedSize * IMG_DPI['2x'], ratio, 'webp')} 2x`}
          type='image/webp'
        />
        <source
          media={`(min-width: ${minWidth}px)`}
          srcSet={`${transformImageWithAspectRatio(focusPoint, fixedSize, ratio)} 1x, ${transformImageWithAspectRatio(focusPoint, fixedSize * IMG_DPI['2x'], ratio)} 2x`}
        />
      </React.Fragment>
    )
  }
  return sources;
}

const getCurrentAspectRatio = (focusPoint: IFocusPoint, aspectRatio: Array<number> | number | undefined, currentBp: string) => {
  if (aspectRatio && typeof aspectRatio === 'number') {
    return aspectRatio;
  }

  const defaultAspectRatio = focusPoint.imageSize.width / focusPoint.imageSize.height;
  const mobileAspectRatio = Array.isArray(aspectRatio) ? aspectRatio[0] : defaultAspectRatio;
  const desktopAspectRatio = Array.isArray(aspectRatio) ? aspectRatio[1] : defaultAspectRatio;
  return (currentBp === 's') ? mobileAspectRatio : desktopAspectRatio;
}

export {
  buildSources,
  getCurrentAspectRatio,
}
