import { Surface } from "@ef-global/web-ui-react/lib/components/Surface";
import React from "react";
import { EsStudentHit } from "~components/custom/student-list/interfaces";
import { getFlagImageUrl } from "~components/custom/students/library";
import studentCardStyles from "./student.module.scss";
import IconArrowRightComponent from "@ef-global/web-ui-react/lib/icons/IconArrowRight";
import dummyFillInterests from "./interestFill";

const StudentCard = ({
  linkPrefix,
  student,
  affiliateId,
  extraUriParams,
  noClass = false,
}: {
  linkPrefix: string;
  student: EsStudentHit;
  affiliateId: string | null;
  extraUriParams: any;
  noClass?: boolean;
}) => {
  const link: string = `${linkPrefix}/${student._source.id}${
    affiliateId ? "?affiliateId=" + affiliateId : ""
  }${extraUriParams ? extraUriParams : ""}`;

  let filledInterests = [];

  if(student?._source?.interests && student?._source?.interests.length >= 4) {
    filledInterests = student?._source?.interests;
  } else if(student?._source?.interests && student?._source?.interests.length > 0) {
    filledInterests = [
      ...student?._source?.interests,
      ...dummyFillInterests
    ]
  } else {
    filledInterests = [
      ...dummyFillInterests
    ]
  }

  return (
    <div
      key={student._source.id}
      className={noClass ? "" : `ef-col -s-12 -m-4 u-mb-m`}
    >
      <Surface
        as="a"
        href={link}
        // @ts-ignore
        className={`ef-students-card`}
        shadow
        rounded
      >
        <h5
          className={studentCardStyles.efStudentName}
          style={{
            textTransform: "capitalize",
          }}
        >
          <span className={`u-mr-xs ${studentCardStyles.efStudentFlag}`}>
            <img src={getFlagImageUrl(student._source.country.toLowerCase())} />
          </span>
          {student._source?.name?.toLowerCase()}
        </h5>
        <p className={studentCardStyles.efStudentNameDescription}>
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {student._source.countryName.toLowerCase()}
          </span>{" "}
          ∙ {student._source.gender} ∙ {student._source.age} years old
        </p>
        <ul
          style={{
            listStyleType: "none",
            padding: 0,
          }}
        >
          {filledInterests.slice(0, 4).map((interest) => {
            return (
              <li key={interest.id}>
                <span className={studentCardStyles.emoji}>
                  {interest.character}
                </span>{" "}
                {interest.normalizedName}
              </li>
            );
          })}
        </ul>
        <span className={studentCardStyles.efStudentCardArrow}>
          <IconArrowRightComponent />
        </span>
      </Surface>
    </div>
  );
};

export default StudentCard;
