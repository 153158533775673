import React from "react";
import Components from "~components/components";
import FocusPointBg from "~components/utils/focusPointBg";
import { IContainer, IFocusPoint } from "~interfaces/storyblock";

enum Gradient {
  "GRADIENT_LIGHT" = "-gradient-light",
  "GRADIENT_MID_LIGHT" = "-gradient-midlight",
  "GRADIENT_MID" = "-gradient-mid",
  "GRADIENT" = "-gradient",
  "GRADIENT_DARK" = "-gradient-dark",
}

interface IFullbleedProps extends IContainer {
  image: IFocusPoint;
  mobile_image: IFocusPoint;
  content: Array<any>;
  fullbleed_height: string;
  content_alignment: string;
  gradient: Gradient;
  gradient_top: boolean;
}

export default (props: IFullbleedProps) => {
  const {
    image,
    mobile_image,
    content,
    content_alignment,
    fullbleed_height,
    gradient,
    gradient_top,
    story,
  } = props;
  return (
    <div
      className={`ef-fullbleed ${gradient + (gradient_top ? "-top" : "")}`}
      style={{ minHeight: `${fullbleed_height}vh` }}
    >
      {image ? (
        <FocusPointBg
          customTag="div"
          className={`ef-fullbleed__bg${
            mobile_image?.image ? " u-show-m-up" : ""
          }`}
          focusPoint={image}
          aspectRatio={[0.7, 1.5]}
        />
      ) : null}
      {mobile_image?.image ? (
        <FocusPointBg
          customTag="div"
          className={`ef-fullbleed__bg u-show-s-only`}
          focusPoint={mobile_image}
          aspectRatio={[0.7, 1.5]}
        />
      ) : null}
      {content && content.length > 0 ? (
        <div
          className={`ef-fullbleed__content ${content_alignment}`}
          style={{ height: "100%" }}
        >
          {content.map((blok) => Components(blok, story))}
        </div>
      ) : null}
    </div>
  );
};
