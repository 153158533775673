import React from "react";
import "./video-player.scss";
import Loadable from "react-loadable";
import { IContainer, IFocusPoint } from "~/src/interfaces/storyblock";
import "react-aspect-ratio/aspect-ratio.css";
import { css } from "@emotion/core";
import { AspectRatio } from "~/src/interfaces/components";

interface VideoPlayerProps extends IContainer {
  url: any;
  video_file: any;
  thumbnail_url: IFocusPoint;
  fullbleed_height: string;
  content_alignment: string;
  full_width: boolean;
  autoplay: boolean;
  controls: boolean;
  withouth_sound: boolean;
  loop: boolean;
  custom_aspect_ratio: boolean;
  aspect_ratio_desktop: AspectRatio;
  aspect_ratio_mobile: AspectRatio;
  player_button_position: string;
  videoNoDisplay: boolean; // TODO: This prop is used only on web-ui-modal as a fix of task MK-981
}

const ReactPlayer = Loadable({
  loader: (): any => import("react-player/lazy"),
  loading: (): any => <div />,
});

const AspectRatioContainer = Loadable({
  loader: (): any => import("react-aspect-ratio"),
  loading: (): any => <div />,
});

/**
 * TODO: Reason to use class component is due a weird error caused
 * when using hooks in functional component, the error does not make sense
 * so instead we decided to use class component which works correctly and
 * does not cause any issue.
 */
class VideoComponent extends React.Component {
  constructor(props: VideoPlayerProps) {
    super(props);
    // @ts-ignore
    this.state = {
      height: "0",
      width: "0",
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      // @ts-ignore
      this.setState((prevState) => ({
        ...prevState,
        height: window?.innerHeight,
        width: window?.innerWidth,
      }));

      // remove listener
      window?.removeEventListener("resize", this.updateDimensions);
      // Additionally I could have just used an arrow function for the binding `this` to the component...
      window?.addEventListener("resize", this.updateDimensions);
    }
  }

  componentDidUpdate() {
    if (typeof window !== `undefined`) {
      window?.removeEventListener("resize", this.updateDimensions);
      // Additionally I could have just used an arrow function for the binding `this` to the component...
      window?.addEventListener("resize", this.updateDimensions);
    }
  }

  updateDimensions() {
    if (typeof window !== `undefined`) {
      // @ts-ignore
      this.setState({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
  }

  render() {
    const { props, state }: any = this;
    const {
      full_width = false,
      autoplay = false,
      loop = false,
      controls = false,
      withouth_sound = false,
      url = "",
      thumbnail_url,
      video_file = null,
      custom_aspect_ratio = false,
      aspect_ratio_desktop = "16/9",
      aspect_ratio_mobile = "16/9",
      player_button_position,
      videoNoDisplay = false // TODO: This prop is used only on web-ui-modal as a fix of task MK-981
    } = props;

    const hasVideo: any = url || video_file?.filename || null;
    const VideoPlayerARCStyle = css`
      .react-player__preview {
        background-position: ${thumbnail_url?.focusPoint?.x ?? 50}%
          ${thumbnail_url?.focusPoint?.y ?? 50}% !important;
      }
    `;

    // Remaining components
    const ReactPlayerWithAspectRatio = (props: any): any => {
      return (
        <AspectRatioContainer
          // @ts-ignore
          ratio={state.width > 480 ? aspect_ratio_desktop : aspect_ratio_mobile}
          style={{ backgroundColor: "#000", marginBottom: "12px" }}
          className={`video-player-custom-aspect ${player_button_position}`}
        >
          <ReactPlayer {...props} />
        </AspectRatioContainer>
      );
    };

    if (hasVideo == null) {
      return (
        <div className="video-player-no-video">
          <p> Please specify a video URL </p>
          <p>
            Click here to see the component details and add a video url
            afterwards.
          </p>
        </div>
      );
    }


    // TODO: This prop is used only on web-ui-modal as a fix of task MK-981
    if(videoNoDisplay) {
      return <></>;
    }

    // Default player no aspect ratio applied.
    return (
      <div css={VideoPlayerARCStyle}>
        {custom_aspect_ratio ? (
          <ReactPlayerWithAspectRatio
            url={hasVideo}
            className="react-video-player"
            width={full_width ? "100%" : undefined}
            playing={autoplay}
            controls={controls}
            muted={withouth_sound}
            volume={withouth_sound ? 0 : 1}
            light={thumbnail_url?.image ?? false}
            loop={loop}
            playsinline
            config={{
              youtube: {
                playerVars: { showinfo: 0, modestbranding: 1, fs: 0 },
              },
            }}
          />
        ) : (
          <ReactPlayer
            // @ts-ignore
            url={hasVideo}
            className="react-video-player"
            width={full_width ? "100%" : undefined}
            playing={autoplay}
            controls={controls}
            muted={withouth_sound}
            volume={withouth_sound ? 0 : 1}
            light={thumbnail_url?.image ?? false}
            loop={loop}
            playsinline
            config={{
              youtube: {
                playerVars: { showinfo: 0, modestbranding: 1, fs: 0 },
              },
            }}
          />
        )}
      </div>
    );
  }
}

export default VideoComponent;
