import { useFormik } from "formik";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { IFormElement } from "~interfaces/components";
import { fetchFields, submitForm } from "~services/formstack-service";
import { handleErrors } from "~services/service-helpers";
import FormElements from "../../web-ui/form/form-elements";
import {
  formSubmissionReducer,
  ISubmissionAction,
  ISubmissionState,
} from "../../web-ui/form/state/form-state";
import {
  convertDataForSubmission,
  createFormikInitialValues,
  fieldValidation,
  createFormPages,
} from "../../web-ui/form/utils/form-helper";
import { renderSubmitButton } from "../../web-ui/form/utils/form-render-helpers";
import { QuizContext } from "~components/custom/quiz/quiz";
import { useLocalStorage } from "~helpers/useLocalStorage";
import { actionTypes } from "~components/custom/quiz/quiz-state";

const validateWithFormfields = (formFields: Array<IFormElement>) => {
  return (values: Record<string, string>) =>
    fieldValidation(values, formFields);
};

interface QuizFormProps {
  terms_and_conditions: string;
  submit_button_text: string;
  spacing_between_elements: string;
  enable_labels: boolean;
  form_id?: string;
  form_auth?: string;
  campaignName: string;
  parsedCampaignName: string;
  questionUids: any;
}

export const QuizForm = (props: QuizFormProps) => {
  const {
    submit_button_text,
    spacing_between_elements,
    enable_labels,
    form_id = "",
    parsedCampaignName,
    questionUids,
  } = props;
  const { quizState, quizDispatch } = useContext(QuizContext);

  const [storedState, setStoredState] = useLocalStorage(
    {},
    `ef-quiz-data-${parsedCampaignName}`
  );

  const [formFields, setFormFields] = useState<Array<IFormElement>>([]);
  const [formikInitialValues, setFormikInitialValues] = useState<
    Record<string, string>
  >({});

  const initialModalState: ISubmissionState = {
    modalIsOpen: false,
    status: "NOT_SUBMITTED",
  };
  const [modalState, modelStateDispatch] = useReducer(
    formSubmissionReducer,
    initialModalState
  );

  const [formPage, setFormPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  /**
   * Submits the form and shows a modal with success or error screen
   */
  const onSubmit = async (
    values: Record<string, string>,
    formId: string,
    dispatch: React.Dispatch<ISubmissionAction>
  ) => {
    const formattedValues = convertDataForSubmission(values);
    dispatch({ type: "SUBMITTING" });

    try {
      const response = await submitForm(formattedValues, formId);
      handleErrors(response);
      dispatch({ type: "OPEN_SUCCESS" });
      setStoredState({
        ...storedState,
        step: Number(quizState.step) + 1,
        formData: formattedValues,
        questionUids,
      });
      quizDispatch({
        type: actionTypes.saveForm,
        formData: formattedValues,
        step: Number(quizState.step) + 1,
        questionUids,
      });
      dataLayer.push({ event: "formSubmissionSuccess" });
    } catch (error) {
      dispatch({ type: "OPEN_ERROR" });
      // tslint:disable-next-line:no-console
      console.log("Error submitting the form", error);
    }
  };

  const onSubmitWithFormId = (formId: string) => (values: any) => (
    dispatch: React.Dispatch<ISubmissionAction>
  ) => onSubmit(values, formId, dispatch);

  const renderField = (field: IFormElement) => {
    if (field.type === "section") {
      return <h4 key={field.id}>{field.section_heading}</h4>;
    }
    return (
      <FormElements
        formik={formik}
        key={field.id}
        className={spacing_between_elements}
        enableLabels={enable_labels}
        {...field}
      />
    );
  };

  const renderPage: any = (
    fields: Array<IFormElement | Array<IFormElement>>,
    currentPage: number
  ) => {
    if (Array.isArray(fields[currentPage])) {
      return renderPage(fields[currentPage]);
    }

    return fields.map((field) => renderField(field as IFormElement));
  };

  useEffect(() => {
    async function fetchFormFields() {
      try {
        const response = await fetchFields(form_id);
        // handleErrors(response);
        let fieldsData = response as Array<IFormElement>;

        fieldsData = createFormPages(fieldsData);
        setFormFields(fieldsData);
        setNumberOfPages(
          fieldsData.reduce(
            (acc, currValue) => (Array.isArray(currValue) ? acc + 1 : acc),
            0
          )
        );
        // Create the initial value object for formik
        const initialFormObject = createFormikInitialValues(fieldsData);
        setFormikInitialValues(initialFormObject);
      } catch (error) {
        // tslint:disable-next-line:no-console
        console.log(error);
      }
    }

    fetchFormFields();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    onSubmit: (values, bag) => {
      if (numberOfPages > 0 && numberOfPages !== formPage + 1) {
        bag.setTouched({});
        bag.setSubmitting(false);
        setFormPage(formPage + 1);
      } else {
        onSubmitWithFormId(form_id)(values)(modelStateDispatch);
      }
    },
    validate:
      numberOfPages > 0
        ? validateWithFormfields(
            (formFields[formPage] as never) as Array<IFormElement>
          )
        : validateWithFormfields(formFields),
    // validateOnBlur: true,
    // validateOnChange: false,
  });

  return (
    <>
      {JSON.stringify(formik.values) !== "{}" ? (
        <form className="ef-form" onSubmit={formik.handleSubmit}>
          {Object.keys(formik.values).length &&
            renderPage(formFields, formPage)}
          <div className="ef-row">
            <div className="ef-col -s-12 form-style-center">
              {renderSubmitButton(
                numberOfPages,
                formPage,
                modalState,
                submit_button_text
              )}
            </div>
          </div>
        </form>
      ) : null}
    </>
  );
};
