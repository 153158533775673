import React, { useContext, useEffect } from "react";
import { IStoryBlokComponent, IStoryblokLink } from "~interfaces/storyblock";
import Components from "~components/components";
import Button from "~components/web-ui-react/button";
import { actionTypes } from "~components/custom/quiz/quiz-state";
import { useLocalStorage } from "~helpers/useLocalStorage";
import { QuizContext } from "~components/custom/quiz/quiz";
import { Button as WebUIButton } from "@ef-global/web-ui-react/lib/components/Button";
import { scroll } from "~components/utils/scroll";

interface QuizResultProps extends IStoryBlokComponent {
  results_content: Array<IStoryBlokComponent>;
  learn_more_text: string;
  learn_more_link: IStoryblokLink;
  redo_quiz_text: string;
  answer_key: string;
  view_all_steps: boolean;
  parsedCampaignName: string;
  form_position: string;
}

const QuizResult = (props: QuizResultProps) => {
  const {
    answer_key,
    results_content,
    story,
    learn_more_text,
    learn_more_link,
    redo_quiz_text,
    view_all_steps,
    parsedCampaignName,
    form_position,
  } = props;
  const [storedState, setStoredState] = useLocalStorage(
    {},
    `ef-quiz-data-${parsedCampaignName}`
  );
  const { quizState, quizDispatch } = useContext(QuizContext);

  // TODO: watch this function if we need to enable it again.
  // this was working weird on last commits.
  const isVisible = () => {
    return answer_key === quizState.result || view_all_steps;
  };

  useEffect(() => {
    scroll("quiz-results");
  }, quizState);

  // isVisible was used here.
  if (!isVisible()) {
    return null;
  }
  return (
    <div id="results-row" className="questions">
      {results_content &&
        results_content.map((blok) => Components(blok, story))}
      <section className="ef-section -pt-s -pb-s results-button text">
        <div className="ef-row -s-x-center">
          <div className="ef-col">
            {learn_more_link && (
              <Button primary text={learn_more_text} link={learn_more_link} />
            )}
          </div>
          <div className="ef-col">
            {form_position !== "last" ? (
              <WebUIButton
                secondary
                onClick={async (e) => {
                  e.preventDefault();
                  await setStoredState({
                    ...storedState,
                    step: Number(quizState.step) - 1,
                    result: "",
                    finishedQuiz: false,
                    questionUids: {},
                  });
                  await quizDispatch({
                    type: actionTypes.decrementStep,
                    result: "",
                    finishedQuiz: false,
                    questionUids: {},
                  });
                }}
                className="ef-button-pointer-events-none"
                style={{ height: "fit-content" }}
              >
                {redo_quiz_text}
              </WebUIButton>
            ) : null}
          </div>
        </div>
      </section>
    </div>
  );
};

export default QuizResult;
