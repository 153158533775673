import {css} from '@emotion/core';
import {Button as WebUIButton} from '@ef-global/web-ui-react/lib/components/Button';
import React, {useState} from 'react';
import {scroll} from '~components/utils/scroll';
import {IStoryblokLink} from '~/src/interfaces/storyblock';
import Link from '~components/utils/link';
import Components from '~components/components';

interface IButtonProps {
  text: string;
  primary?: boolean;
  cta?: boolean;
  square?: boolean;
  small?: boolean;
  scroll_to?: string;
  id?: string;
  link?: IStoryblokLink;
  background_color?: any;
  border_color?: any;
  text_color?: any;
  open_in_a_new_tab?: boolean;
  action?: any;
  story?: any;
}

const Button = (props: IButtonProps) => {
  const {
    action,
    text,
    primary,
    cta,
    small,
    square,
    scroll_to = '',
    id,
    link,
    background_color,
    border_color,
    open_in_a_new_tab,
    story,
    text_color,
  } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const buttonStyle = css`
    border: ${border_color?.color ? `${border_color?.color} solid 1px !important` : 'inherit'};
    background-color: ${background_color?.color ? `${background_color?.color} !important` : 'inherit'};
    color: ${text_color?.color ? `${text_color?.color} !important` : 'inherit'};
  `;

  const renderButton = () => (
    <WebUIButton
      {...(primary ? {primary: true} : {secondary: true})}
      cta={cta}
      small={small}
      square={square}
      onClick={() => scroll_to ? scroll(scroll_to) : handleOpen()}
      id={id}
      className='ef-button-pointer-events-none'
      style={{height: 'fit-content'}}
      css={[buttonStyle]}
    >
      {text}
    </WebUIButton>
  )

  const renderLink = () => (
    <Link
      className={`ef-button`
        + `${primary ? ' -primary' : ' -secondary'}`
        + `${small ? ' -small' : ''}`
        + `${square ? ' -square' : ''}`}
      storyblokLink={link}
      id={id}
      style={{height: 'fit-content'}}
      css={[buttonStyle]}
      openInANewTab={open_in_a_new_tab ?? false}
      onClick={handleOpen}
    >
      {text}
    </Link>
  )

  return (
    <>
      {
        action && action[0]
          ? Components({...action[0], open, handleOpen, handleClose}, story)
          : null
      }
      {link?.id || link?.url ? renderLink() : renderButton()}
    </>
  );
};

export default Button;
