import React, { useContext, useEffect, useState } from "react";
import { Button as WebUIButton } from "@ef-global/web-ui-react/lib/components/Button";
import { QuizContext } from "~components/custom/quiz/quiz";
import Components from "~components/components";
import { IQuestionProps } from "~components/custom/quiz/question";
import { IStoryblokStory } from "~interfaces/storyblock";
import { actionTypes } from "~components/custom/quiz/quiz-state";
import { useLocalStorage } from "~helpers/useLocalStorage";

interface QuestionsProps {
  submitButtonText: string;
  questions: Array<IQuestionProps>;
  story: IStoryblokStory;
  campaignName: string;
  parsedCampaignName: string;
}

export const Questions = (props: QuestionsProps) => {
  const { submitButtonText, questions, story, parsedCampaignName } = props;
  const { quizState, quizDispatch } = useContext(QuizContext);
  const [storedState, setStoredState] = useLocalStorage(
    {},
    `ef-quiz-data-${parsedCampaignName}`
  );
  const [isDisabled, setIsDisabled] = useState(true);
  // @ts-ignore
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);

  useEffect(() => {
    const totalSelected = Object.values(quizState?.questionUids).reduce(
      (acc: number, question: any) => {
        if (question.selected_answer_key) {
          return acc + 1;
        }
        return acc;
      },
      0
    );
    setSelectedCount(totalSelected);
  }, [quizState.questionUids]);

  const calculateResult = (answeredQuestions: any) => {
    const totals = Object.values(answeredQuestions).reduce(
      (acc: any, question: any) => {
        acc[question.selected_answer_key] =
          (acc[question.selected_answer_key] || 0) + 1;
        return acc;
      },
      {}
    );
    // @ts-ignore
    const maxCount = Math.max(...Object.values(totals));
    // @ts-ignore
    const mostFrequent = Object.keys(totals).filter(
      // @ts-ignore
      (result: any) => totals[result] === maxCount
    );
    return Array.isArray(mostFrequent) ? mostFrequent[0] : mostFrequent;
  };

  const submitResult = async () => {
    const result = calculateResult(quizState.questionUids);
    // Removed lead result submission while there is an issue with duplicate leads on salesforce
    await setStoredState({
      ...storedState,
      result,
      step: Number(quizState.step) + 1,
    });
    await quizDispatch({ type: actionTypes.incrementStep, result });
  };

  useEffect(() => {
    setIsDisabled(
      selectedCount === 0 ||
        selectedCount !== Object.keys(quizState.questionUids).length
    );
  }, [selectedCount]);

  return (
    <>
      <div id="quiz-questions" className="quiz-questions">
        {questions &&
          questions.map((blok) =>
            Components({ ...blok, parsedCampaignName }, story)
          )}
      </div>
      <section className="ef-section -pt-s -pb-s">
        <div>
          <div
            className="ef-col u-text-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div id="counter" className="quiz-questions-counter">
              {selectedCount}/{questions.length}
            </div>
            <WebUIButton
              onClick={() => {
                submitResult();
              }}
              className="u-text-center"
              primary
              // @ts-ignore
              disabled={isDisabled}
              loading={isLoading}
            >
              {submitButtonText ?? "Submit"}
            </WebUIButton>
          </div>
        </div>
      </section>
    </>
  );
};
