import React, {useState} from 'react';
import fill from 'lodash/fill'
import capitalize from 'lodash/capitalize'
import {Surface} from '@ef-global/web-ui-react/lib/components/Surface';

import {DEFAULT_VALUES} from './constants'
import {ISingleStudentProps, IStudent} from './types'
import SingleStudentProfileButtons from './single-student-profile-buttons'
import SingleStudentProfileModal from './single-student-profile-modal'
import SingleStudentProfileBody from './single-student-profile-body'
import IconArrowLeftComponent from '@ef-global/web-ui-react/lib/icons/IconArrowLeft';
import {IStoryBlokComponent} from '~interfaces/storyblock';

interface ISingleStudentProfileProps extends ISingleStudentProps {
  formId?: string,
  submissionMessageSuccess?: IStoryBlokComponent,
  submissionMessageError?: IStoryBlokComponent,
  personalLetterVisibleCharacters: number,
  student: IStudent
}

export default (
  {
    formId,
    submissionMessageSuccess,
    submissionMessageError,
    noOfInterests = DEFAULT_VALUES.noOfInterests,
    personalLetterVisibleCharacters = DEFAULT_VALUES.personalLetterVisibleCharacters,
    student
  }: ISingleStudentProfileProps) => {

  if (!student) return null

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };
  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const buildInterestLi = (item: any, index: number): JSX.Element => (
    <li key={index}>
      <span hidden={item}>&nbsp;</span>
      <span hidden={!item}>- </span>
      {capitalize(item)}
    </li>
  )

  const noOfInterestsInt = parseInt(String(Math.max(noOfInterests, 0)), 10);
  const computedInterests = [
    ...student.interests.filter(Boolean),
    ...new Array(noOfInterestsInt).fill('')
  ]
    .slice(0, noOfInterestsInt)
    .map(buildInterestLi)

  const interestsLi = computedInterests.length >= noOfInterestsInt ? computedInterests : fill(computedInterests, computedInterests.length, noOfInterestsInt)

  return student?.id ? (
    <Surface shadow rounded className='student student__detail'>
      <a className='u-pb-m u-show-s-only back-mobile' onClick={() => history.back()}>
        <IconArrowLeftComponent/>
      </a>

      <div className='u-show-m-up'>
        <SingleStudentProfileButtons studentName={student?.name} handleOpenModal={handleOpenModal}/>
      </div>

      <SingleStudentProfileBody
        student={student}
        interestsLi={interestsLi}
        personalLetterVisibleCharacters={personalLetterVisibleCharacters}
        computedInterests={computedInterests}
        handleOpenModal={handleOpenModal}
      />

      <SingleStudentProfileModal
        formId={formId}
        submissionMessageSuccess={submissionMessageSuccess}
        submissionMessageError={submissionMessageError}
        modalIsOpen={modalIsOpen}
        handleCloseModal={handleCloseModal}
        student={student}
        interestsLi={interestsLi}
      />
    </Surface>
  ) : null
}
