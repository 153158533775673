import React from 'react';
import { IFocusPoint } from '~src/interfaces/storyblock';
import FocusPointImg from '~components/utils/focusPointImg';
import { AspectRatio } from '~src/interfaces/components';

export interface IImageProps extends React.HTMLAttributes<HTMLImageElement> {
  image: IFocusPoint;
  alt: string;
  aspect_ratio: AspectRatio;
  aspect_ratio_mobile: AspectRatio;
  width?: number;
}

const Image = (props: IImageProps) => {
  const { image, alt, aspect_ratio, aspect_ratio_mobile, width } = props;

    // TODO: Simplify this
  const aspectRatioDesktop =
    aspect_ratio !== 'original'
      ? aspect_ratio.split(':').map(Number)
      : [image?.imageSize?.width, image?.imageSize?.height];

  const aspectRatioMobile =
    aspect_ratio_mobile !== 'original'
      ? aspect_ratio_mobile.split(':').map(Number)
      : [image?.imageSize?.width, image?.imageSize?.height];

  return (
    image && image.image ? (
      <FocusPointImg
        focusPoint={image}
        alt={alt}
        aspectRatio={image.image ? [aspectRatioMobile[0] / aspectRatioMobile[1], aspectRatioDesktop[0] / aspectRatioDesktop[1]] : [1, 1]}
        width={width}
        {...props}
      />
    ) : <div/>
  )
};

export default Image;
