import React from 'react'
import Loadable from 'react-loadable'

const EmailShareButton = Loadable({
  loader: () => import('react-share'),
  render(loaded, props) {
    let Component = loaded.EmailShareButton;
    return <Component {...props}/>;
  },
  loading: () => <div/>
});

export const Email = (props: any) => {
  const {url, email_subject = '', email_body = '', ...rest} = props
  return (
    <EmailShareButton
      url={url}
      subject={email_subject}
      body={email_body}
      separator='
'
      onClick={() => {}}
      openShareDialogOnClick
      {...rest}
    >
      <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32'>
        <g fill='none' fillRule='evenodd'>
          <circle
            cx='16'
            cy='16'
            r='15.5'
            stroke='#191919'
            strokeOpacity='0.2'
          />
          <path
            fill='#191919'
            d='M18.88 16.825l4.225 4.675H8.895l4.25-4.675 2.5 2.53a.5.5 0 00.71 0l2.525-2.53zM8 11.705l4.06 4.06L8 20.25v-8.545zm16 0v8.545l-4.06-4.485 4.06-4.06zm-.705-1.205L16 17.795 8.705 10.5h14.59z'
          />
        </g>
      </svg>
    </EmailShareButton>
  )
}
