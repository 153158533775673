import React, {useEffect, useState} from 'react';
import {
  getAllCountries,
  getFlagImageUrl,
} from '~components/custom/students/library';
import {Button} from '@ef-global/web-ui-react/lib/components/Button';
import {css} from '@emotion/core'

interface Country {
  country: string,
  countryName: string,
}

const getCountries = async (): Promise<{ country: undefined; countryName: string }[]> => {
  const countries = await getAllCountries()
  return [...countries, { country: undefined, countryName: 'Open to all'}]
}

export const CountryListFilter = (props: any) => {
  const {currentCountry, setCountry, setStep} = props
  const [countries, setCountries] = useState([])

  const CountryStyles = css`
    .ef-button {
      width: 100%;
      max-width: 100%;
      margin: 5px 0 5px 0;
      &.active {
        background: #046EA7;
        color: #f5f5f5;
      }
      .flag--image {
        width: 16px;
        height: 16px;
        border: 0.5px solid rgba(0,0,0,0.2);
        border-radius: 100px;
      }
    }
  `
  useEffect(() => {
    if (!countries || !countries.length) {
      // @ts-ignore
      getCountries().then(res => setCountries(res))
    }
  }, [countries]);

  return (
    <div className='ef-row'>
      {countries && countries.length ? countries.map((country: Country) =>
        (
          <CountryCard
            key={country.country}
            countryObj={country}
            setCountry={setCountry}
            css={CountryStyles}
            currentCountry={currentCountry}
            setStep={setStep}
          />
        )
      ) : null}
    </div>
  )
}

const CountryCard = (props: any) => {
  const {key, countryObj, currentCountry, setCountry, setStep, ...rest} = props
  const {country, countryName} = countryObj
  return (
    <div className='ef-col -s-6' key={key}>
      <div {...rest}>
        <Button
          secondary
          square
          className={`${currentCountry === country ?  'active' : ''}`}
          css={css}
          id={country}
          // @ts-ignore
          value={country}
          onClick={() => {
            setCountry(country);
            setStep(2);
          }}
        >
          {country ? <img className='flag--image' alt={`${countryName} flag`} src={getFlagImageUrl(country)}/> : null}
          <span className='flag--name'>{countryName}</span>
        </Button>
      </div>
    </div>
  )
}
