import {
  QUALITY_DEFAULT,
  RES_PER_SCREEN,
  STORYBLOK_IMAGE_SIZE_LIMIT,
  STORYBLOK_IMAGE_SERVICE_BASE,
} from "~components/utils/image-constants";
import { IFocusPoint } from "~interfaces/storyblock";

interface ISize {
  width: number;
  height: number;
}

interface IPoint {
  x: number;
  y: number;
}

interface IFocusPointObject {
  originalSize: ISize;
  focusPoint: IPoint;
}

/**
 * Converts the focus points to pixels and builds the focal string for the storyblok service
 */
const calculateFocusPoint = (imageSize: ISize, focusPoint: IPoint) => {
  // Convert focus point to pixels
  const focusInPx = {
    x: Math.round((imageSize.width * focusPoint.x) / 100),
    y: Math.round((imageSize.height * focusPoint.y) / 100),
  };

  // https://www.storyblok.com/docs/image-service
  // filters:focal(<left>x<top>:<right>x<bottom>)
  return `:focal(${focusInPx.x}x${focusInPx.y}:${focusInPx.x + 1}x${
    focusInPx.y + 1
  })`;
};

const transformImage = (
  image: string,
  focusPointObject?: IFocusPointObject,
  size?: ISize,
  format?: string,
  quality = QUALITY_DEFAULT
) => {
  if (!image || !image.length) {
    return "";
  }

  // if we are dealing with an svg we don't crop/resize the image
  if (image.endsWith(".svg")) {
    return image;
  }

  // Some images are returned by the Storyblok API with https prepended
  // Remove it
  if (image.startsWith("https:")) {
    image = image.replace("https:", "");
  }

  // Replace
  const resource = image.replace("//a.storyblok.com", "");

  let transformedImage = STORYBLOK_IMAGE_SERVICE_BASE;

  // Add resize
  if (size) {
    transformedImage += `${size.width}x${size.height}/`;
  }

  transformedImage += `filters:quality(${quality})`;

  // Add focus point
  if (focusPointObject) {
    transformedImage += calculateFocusPoint(
      focusPointObject?.originalSize,
      focusPointObject?.focusPoint
    );
  }

  if (format) {
    transformedImage += `:format(${format})`;
  }

  transformedImage += resource;
  return transformedImage;
};

const applyAspectRatio = (
  focusPoint: IFocusPoint,
  width: number,
  aspectRatio = 1
) => {
  width = Math.round(width);
  const { width: originalWidth, height: originalHeight } = focusPoint.imageSize;
  let computedWidth = width > originalWidth ? originalWidth : width;
  let computedHeight = Math.round(computedWidth / aspectRatio);
  if (computedHeight > originalHeight) {
    computedHeight = originalHeight;
    computedWidth = Math.round(originalHeight * aspectRatio);
  }
  return { width: computedWidth, height: computedHeight };
};

const transformImageWithAspectRatio = (
  focusPoint: IFocusPoint,
  width: number,
  aspectRatio = 1,
  format?: string
) => {
  return transformImage(
    focusPoint.image,
    { focusPoint: focusPoint.focusPoint, originalSize: focusPoint.imageSize },
    applyAspectRatio(focusPoint, width, aspectRatio),
    format
  );
};

const transformImageWithAspectRatioLowRes = (
  focusPoint: IFocusPoint,
  width: number,
  aspectRatio = 1,
  format?: string
) => {
  return transformImage(
    focusPoint.image,
    { focusPoint: focusPoint.focusPoint, originalSize: focusPoint.imageSize },
    applyAspectRatio(focusPoint, width, aspectRatio),
    format,
    10
  );
};

/**
 * Resize the image with Storyblok's service
 * https://www.storyblok.com/docs/image-service#resizing
 *
 * If the image has width but no height it scales proportional to width
 * If the image has height but no width it scales proportional to height
 * If the image has both width and height it adds transparent padding to avoid cropping
 */
function resize(
  image: string,
  width: number,
  height: number,
  fullWidth?: boolean
) {
  if (image === undefined || image.length === 0) {
    return "";
  }
  // No changes to SVG images
  if (image.endsWith(".svg")) {
    return image;
  }

  const imageService = "//img2.storyblok.com/";
  const path = image.replace("https:", "").replace("//a.storyblok.com", "");

  // Add a fit-in property to ensure the image is not cropped
  if (width !== 0 && height !== 0 && fullWidth) {
    return (
      imageService +
      `fit-in/${width}x${height}/filters:fill(transparent)` +
      path
    );
  }

  return imageService + `${width}x${height}` + path;
}

const withSrcSet = (srcSetMap: Array<number>) => (image: string) => {
  return srcSetMap
    .reduce((acc, srcSetWidth) => {
      return `${acc} ${resize(image, srcSetWidth, 0)} ${srcSetWidth}w, `;
    }, "")
    .slice(0, -2);
};

const resizeWithSrcSet = withSrcSet(RES_PER_SCREEN);

// TODO: Make this simpler
const withFocuspointSrcSet = (srcSetMap: Array<number>) => (
  { image, imageSize, focusPoint }: IFocusPoint,
  width = 100,
  height = 100
) => {
  return srcSetMap
    .reduce((acc, srcSetWidth) => {
      const imageWidth = Math.round(srcSetWidth * (width / 100));
      const imageHeight = Math.round(srcSetWidth * (height / 100));

      const { url } = resizeWithFocusPoint(image, imageSize, focusPoint, {
        width: imageWidth,
        height: imageHeight,
      });
      return `${acc} ${url} ${srcSetWidth}w, `;
    }, "")
    .slice(0, -2);
};

const resizeWithFocusPointSrcSet = withFocuspointSrcSet(RES_PER_SCREEN);

function resizeWithFocusPoint(
  image: string,
  originalSize: ISize,
  focusPoint: IPoint,
  size: ISize,
  quality = QUALITY_DEFAULT
) {
  if (!image || !image.length) {
    return { url: "", size: { width: 0, height: 0 } };
  }

  // if we are dealing with an svg we don't crop/resize the image
  if (image.endsWith(".svg")) {
    return {
      size,
      url: image,
    };
  }

  // as the service only allows images up to 4000x4000 we do some math to make sure the resize is correct
  size = calculateMaxSize(size, STORYBLOK_IMAGE_SIZE_LIMIT);

  // this bit is to deal with resize coordinates if image original size is bigger than STORYBLOK_IMAGE_SIZE_LIMIT
  const maxOriginalSize = calculateMaxSize(
    originalSize,
    STORYBLOK_IMAGE_SIZE_LIMIT
  );

  const sizeOption = `${size.width}x${size.height}/`;
  const focusInPx = {
    x: Math.round((maxOriginalSize.width * focusPoint.x) / 100),
    y: Math.round((maxOriginalSize.height * focusPoint.y) / 100),
  };

  // https://www.storyblok.com/docs/image-service
  // filters:focal(<left>x<top>:<right>x<bottom>)
  const focusPointOption = `filters:focal(${focusInPx.x}x${focusInPx.y}:${
    focusInPx.x + 1
  }x${focusInPx.y + 1}):quality(${quality})`;

  const imageService = "//img2.storyblok.com/";
  const path = image.replace("//a.storyblok.com", "");
  return {
    size,
    url: imageService + sizeOption + focusPointOption + path,
  };
}

function calculateMaxSize(size: ISize, sizeLimit: number) {
  const newSize = size;

  if (newSize.width > sizeLimit) {
    newSize.height = Math.round((sizeLimit * size.height) / size.width);
    newSize.width = sizeLimit;
  }

  if (newSize.height > sizeLimit) {
    newSize.width = Math.round((sizeLimit * size.width) / size.height);
    newSize.height = sizeLimit;
  }

  return newSize;
}

export {
  applyAspectRatio,
  resize,
  resizeWithFocusPoint,
  resizeWithFocusPointSrcSet,
  resizeWithSrcSet,
  transformImage,
  transformImageWithAspectRatio,
  transformImageWithAspectRatioLowRes
};
