import React, { useState } from 'react';
import Loadable from 'react-loadable';
import { IFocusPoint } from '~src/interfaces/storyblock';
import styles from './infomeeting-modal.module.scss';
import IconCalendar from '@ef-global/web-ui-react/lib/icons/IconCalendar';
import { IMarketData } from '../../interfaces';
import { Surface } from '@ef-global/web-ui-react';
import FormsX from '../../../formsx/formsx';
import InfomeetingFormSuccess from '../infomeeting-form-success/infomeeting-form-success';
import { FormStatus } from '~src/interfaces/forms';
import { getHref } from '~/src/helpers/generic-helpers';

const LoadableReactModal = Loadable({
  loader: () => import('react-modal'),
  loading: () => <div/>
});

const INFOMEETING_FORM_TYPE = 'infomeeting';
const FORM_FIELDS = [
  'firstName',
  'lastName',
  'mobilePhone',
  'email',
  'birthDate',
  'checkboxCombined'
]

export interface IInfomeetingModalProps {
  isOpen: boolean;
  toggleModal: (index?: number) => void;
  card_image: IFocusPoint;
  infomeeting: IMarketData;
  market: string;
  form_type: string;
  form_recording_button: string;
  form_share_button: string;
  form_title: string;
  form_success_screen: any;
  form_edit_submission_screen: FormStatus;
}

const InfomeetingModal = (props: IInfomeetingModalProps) => {
  const {
      isOpen,
      toggleModal,
      card_image,
      infomeeting,
      market,
      form_share_button,
      form_title,
      form_success_screen,
      form_edit_submission_screen,
    } = props;

  const [formStatus, setFormStatus] = useState(FormStatus.NOT_SUBMITTED);

  const developFormStatus = form_edit_submission_screen || FormStatus.NOT_SUBMITTED;

  // Enables the preview screens during Storyblok editing
  if (getHref().includes('editor') && developFormStatus !== formStatus) {
    setFormStatus(FormStatus[developFormStatus]);
  }

  return (
    <LoadableReactModal
      className='ef-modal -l'
      isOpen={isOpen}
      onRequestClose={() => toggleModal()}
      overlayClassName={{
        afterOpen: 'ef-modal__overlay--after-open',
        base: 'ef-modal__overlay -lightbox',
        beforeClose: 'ef-modal__overlay--before-close',
      }}
      htmlOpenClassName='ef-modal--active'
      ariaHideApp={false}
      closeTimeoutMS={150}
    >
      <button className='ef-modal__dismiss' onClick={() => toggleModal()} />
      {formStatus === FormStatus.SUBMITTED && (
        <InfomeetingFormSuccess
          section={form_success_screen[0]}
          formShareButton={form_share_button}
        />
      )}

      {infomeeting && formStatus === FormStatus.NOT_SUBMITTED && (
        <div className={`${styles.infomeetingModal} ef-row`}>
          <div className='ef-col -s-12 -l-8 u-s-p-m u-l-pt-xxl u-l-pb-xxl'>
            <div className={'ef-row -x-center'}>
              <div className={'ef-col -s-12 -l-8'}>
                <img src={card_image.image} alt='Todo'/>
              </div>
              {/* Information */}
              <article className={`ef-col ef-col -s-12 -l-8 u-mt-xxs`}>
                <span className={styles.infomeetingModalIcons}>
                  <IconCalendar/>
                  <p>{infomeeting.date}</p>
                </span>
              </article>

              {/* Title */}
              <h1 className={`ef-h4 ef-col -s-12 -l-8 ${styles.infomeetingModalTitle}`}>{infomeeting.title}</h1>

              {/* Description */}
              <p className='ef-col -s-12 -l-8'>{infomeeting.description}</p>
            </div>
          </div>

          <Surface
            deepShadow={true}
            className='ef-col -s-12 -l-4 u-l-pt-xxl'
          >
            {/* Title */}
            <h2 className='ef-h4'>{form_title}</h2>
            <FormsX
              market={market}
              form_type={INFOMEETING_FORM_TYPE}
              eventId={infomeeting.id}
              visibleFields={FORM_FIELDS}
              onSubmissionCompleted={() => setFormStatus(FormStatus.SUBMITTED)}
            />
          </Surface>
        </div>
      )}
    </LoadableReactModal>
  )
}

export default InfomeetingModal;
