import React, { useEffect } from 'react'
import { Form } from '@ef-global/web-ui-react/lib/components/Form'
import ReactMarkdown from 'react-markdown'

import { ageOptions, genderOptions, getValueOrUndefinedFromEvent, getLocationSearch, updateLocation } from './library';
import Countries from './countries';

interface FilterProps {
  title: string;
  setGender: any;
  setAge: any;
  minAge: string;
  maxAge: string;
  countryTitle: string;
  country: string | undefined;
  setCountry: any;
}

export default ({ title, setGender, setAge, minAge, maxAge, countryTitle, country, setCountry }: FilterProps) => {
    const setGenderAndSaveFilter = (event: any) => {
        const value = getValueOrUndefinedFromEvent(event);
        updateLocation({ gender: value });
        setGender(value);
    }

    const setAgeAndSaveFilter = (event: any) => {
        const value = getValueOrUndefinedFromEvent(event);
        updateLocation({ age: value });
        setAge(value);
    }

    const setCountryAndSaveFilter = (value: string) => {
        updateLocation({ country: value });
        setCountry(value);
    }

    return (
        <div className='ef-row students-search--filters'>
            <div className='ef-col -l-9 -m-12 -s-12'>
                <Countries
                    title={countryTitle}
                    currentValue={country}
                    setCountry={setCountryAndSaveFilter}
                />
            </div>
            <div className='ef-col -l-3 -m-12 -s-12'>
                <ReactMarkdown source={title} className='u-show-l-up'/>
                <Form.Select
                    id='filter-gender'
                    floatingLabel={!getLocationSearch().gender}
                    placeholder='Male or female'
                    onChange={setGenderAndSaveFilter}
                    defaultValue={getLocationSearch().gender}
                >
                    {genderOptions}
                </Form.Select>

                <Form.Select
                    id='filter-age'
                    floatingLabel={!getLocationSearch().age}
                    placeholder='Age'
                    onChange={setAgeAndSaveFilter}
                    className='u-mt-xs'
                    defaultValue={getLocationSearch().age}
                >
                    {ageOptions(minAge, maxAge)}
                </Form.Select>
            </div>
        </div>
    );
};
