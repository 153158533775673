import { IPOSEIDONInfomeetingData, ISFInfomeetingData, IMarketData } from '../interfaces';
import { format, differenceInMinutes, isWithinInterval, differenceInWeeks, parse } from 'date-fns';

const getDate = (data: IPOSEIDONInfomeetingData | ISFInfomeetingData) => {
  if ('start_Date_Time__c' in data) {
    return new Date(data.start_Date_Time__c);
  }
  if ('Day' in data && 'Month' in data && 'Year' in data) {
    // Need to parse the date since IOS does not support some date formats
    const date = parse(`${data.Year} ${data.Month} ${data.Day} ${data.Starttime}`, 'yyyy MMMM dd H:mm', new Date());
    return new Date(date);
  }
  // tslint:disable-next-line:no-console
  console.warn('Date data not found');
  return new Date(Date.now());
}

/**
 * Merges date data from different sources into one specific format
 * More information about the date format can be seen here: https://date-fns.org/v2.11.1/docs/format
 */
const mergeMonth = (data: IPOSEIDONInfomeetingData | ISFInfomeetingData) => {
  const dateFormat = `EEEE, MMMM d 'at' ha`;
  return format(getDate(data) as Date, dateFormat);
}

const mergeDuration = (data: IPOSEIDONInfomeetingData | ISFInfomeetingData) => {
  // console.log('data', data)
  if ('start_Date_Time__c' in data && 'end_Date_Time__c' in data) {
    return differenceInMinutes(
      new Date(data.end_Date_Time__c),
      new Date(data.start_Date_Time__c)
    ) + 'min';
  }
  if ('Day' in data && 'Month' in data && 'Year' in data && 'Starttime' in data && 'Endtime' in data) {
    const startTime = parse(`${data.Year} ${data.Month} ${data.Day} ${data.Starttime}`, 'yyyy MMMM dd H:mm', new Date());
    const endTime = parse(`${data.Year} ${data.Month} ${data.Day} ${data.Endtime}`, 'yyyy MMMM dd H:mm', new Date());
    return differenceInMinutes(endTime, startTime) + 'min';
  }
  // tslint:disable-next-line:no-console
  console.warn('Duration data not found');
  return data;
}

const isLive = (dateStart: Date, dateEnd: Date) => {
  try {
    return isWithinInterval(
      new Date(Date.now()),
      { start: dateStart, end: dateEnd }
    );
  } catch {
    return false;
  }
}

export const isInfomeetingLive = (data: IPOSEIDONInfomeetingData | ISFInfomeetingData) => {
  if ('start_Date_Time__c' in data && 'end_Date_Time__c' in data) {
    return isLive(
      new Date(data.start_Date_Time__c),
      new Date(data.end_Date_Time__c)
    );
  }
  if ('Day' in data && 'Month' in data && 'Year' in data && 'Starttime' in data && 'Endtime' in data) {
    return isLive(
      new Date(`${data.Year} ${data.Month} ${data.Day} ${data.Starttime}`),
      new Date(`${data.Year} ${data.Month} ${data.Day} ${data.Endtime}`)
    );
  }
  return false;
}

/**
 * Merges market data from Poseidon and Salesforce data into one
 */
export const mergeMarketData = (data: Array<IPOSEIDONInfomeetingData | ISFInfomeetingData>): Array<IMarketData> => {
  return data.reduce((acc, curr) => {
    return [
      ...acc,
      {
        id: (curr as IPOSEIDONInfomeetingData).InfomeetingId || (curr as ISFInfomeetingData).id,
        title: (curr as IPOSEIDONInfomeetingData).Topic || (curr as ISFInfomeetingData).topic__c,
        date: mergeMonth(curr),
        description: (curr as IPOSEIDONInfomeetingData).Comment || (curr as ISFInfomeetingData).additional_Information__c,
        type: (curr as IPOSEIDONInfomeetingData).MeetingTypeCode || (curr as ISFInfomeetingData).meeting_Type__c,
        duration: mergeDuration(curr),
        isLive: isInfomeetingLive(curr),
        differenceInWeeks: differenceInWeeksFromNow(getDate(curr)),
      }
    ];
  }, [] as Array<any>)
}

export const differenceInWeeksFromNow = (date: Date) => {
  const now = new Date(Date.now());
  return differenceInWeeks(date, now);
}
