import { IPOSEIDONInfomeetingData, ISFInfomeeting } from '../interfaces';
import { mergeMarketData } from '../helpers/infomeeting-service-helpers';
import {isGatsbyProduction} from '~components/utils/env-helpers';

const SALESFORCE_MARKETS = [
  'atv',
  'brs',
  'frp',
  'nla',
  'ses',
  'chd',
];

const POSEIDON_MARKETS = [
  'dk',
  'de',
  'it',
  'jp',
  'kr',
  'no',
  'es',
  'tw',
  'th'
]

const SF_BASE_URL = isGatsbyProduction() ? 'https://hsey-api.azurewebsites.net' : 'https://hsey-api-dev.azurewebsites.net';
const POSEIDON_BASE_URL = 'https://www.ef.com/sites/languagesites/ef-services/languagesitesservice.svc/getinfomeetings/?itemId=0eb59533-f3cb-4ca3-99a4-62c8f12fd37a'

const HSEY_DASH_CLIENT_ID = 'd10e9eb5-4670-41e9-8860-7c498b603676';
const HSEY_DASH_CLIENT_SECRET = '8b09ead6-44d5-4932-81a5-643322cc0ad6';

interface IAuthSFToken {
  access_token: string;
  token_type: string;
  expires_in: number;
}

/**
 * Gets bearer token for the SF API
 * Fetches it and stores it in the browser's session storage
 */
const getSFAuthToken = () => {
  const sfAuthToken = sessionStorage.getItem('sf_auth_token');

  if (sfAuthToken) {
    return Promise.resolve(sfAuthToken);
  }

  const params: any = {
    client_id: HSEY_DASH_CLIENT_ID,
    client_secret: HSEY_DASH_CLIENT_SECRET,
    grant_type: 'client_credentials'
  }

  const esc = encodeURIComponent;
  const query = Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');

  return fetch(`${SF_BASE_URL}/token`, {
    method: 'POST',
    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    body: query,
  }).then(response => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<IAuthSFToken>;
  }).then(authToken => {
    sessionStorage.setItem('sf_auth_token', authToken.access_token);
    return authToken.access_token;
  })
}

/**
 * Fetches the infomeeting data for a specific SalesForce market
 */
const getSFMarket = (market: string) => {
  return getSFAuthToken().then(authToken => {
    return fetch(`${SF_BASE_URL}/api/infomeetings/getAllInfomeetings?mc=${market}`, {
      method: 'GET',
      headers: {'Authorization': `bearer ${authToken}`}
    }).then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json() as Promise<ISFInfomeeting>;
    }).then(sfInfomeeting => {
      return sfInfomeeting.data;
    })
  })
}


/**
 * Fetches the infomeeting data for a specific Poseidon market
 */
const getPoseidonMarket = (market: string) => {
  return fetch(`${POSEIDON_BASE_URL}&marketcode=${market}`)
    .then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json() as Promise<Array<IPOSEIDONInfomeetingData>>;
    });
}


/**
 * Fetches all infomeeting data for a specific market
 */
const getAllInfomeetingsForMarket = (market: string) => {
  if (SALESFORCE_MARKETS.includes(market)) {
    return getSFMarket(market).then(data => mergeMarketData(data));
  } else if (POSEIDON_MARKETS.includes(market)) {
    return getPoseidonMarket(market).then(data => mergeMarketData(data));
  } else {
    throw new Error('This market is not supported');
  }
}

export {
  getAllInfomeetingsForMarket,
  getSFAuthToken,
}
