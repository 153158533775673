import React from "react";
import Components from "~components/components";
import { css } from "@emotion/core";

import { IStoryBlokComponent } from "~interfaces/storyblock";
import Loadable from "react-loadable";

const WebUICarousel = Loadable({
  loader: async () => {
    const { Carousel: component } = await import(
      /* webpackChunkName: "Carousel" */ "@ef-global/web-ui-react/lib/components/Carousel"
    );

    return component;
  },
  loading: () => <div />,
});

const GALLERY_ITEMS_PER_ROW_MOBILE = 1;
const CAROUSEL_BREAKPOINT = 1025;

const swiperConfig = ({
  centeredSlides,
  loop,
  galleryItemsPerRow: slidesPerView,
  breakpoints = {},
}: any) => ({
  centeredSlides,
  loop,
  slideClass: "u-height-auto",
  slidesPerView: GALLERY_ITEMS_PER_ROW_MOBILE,
  spaceBetween: 8,
  paginationIsClickable: false,
  breakpoints: {
    [CAROUSEL_BREAKPOINT]: {
      slidesPerView,
    },
    ...breakpoints,
  },
});

const Carousel = (props: any) => {
  const { dotsColor } = props;

  const carouselStyle = css`
    .swiper-pagination-bullet {
      background-color: ${dotsColor};
    }
  `;

  return (
    <WebUICarousel css={carouselStyle} swiperConfig={swiperConfig(props)}>
      {props.items &&
        props.items.map((blok: IStoryBlokComponent) => Components(blok))}
    </WebUICarousel>
  );
};

export const CarouselComponent = (props: any) => {
  const { dotsColor } = props;

  const carouselStyle = css`
    .swiper-pagination-bullet {
      background-color: ${dotsColor};
    }
    .ef-carousel__btn {
      pointer-events: none;
      .ef-carousel__btn-icon {
        pointer-events: all;
      }
    }
    @media screen and (min-width: 600px) {
      .swiper-button-disabled {
        display: none;
      }
    }
  `;

  return (
    <WebUICarousel css={carouselStyle} swiperConfig={props}>
      {props.children ? props.children : <></>}
    </WebUICarousel>
  );
};

export default Carousel;
