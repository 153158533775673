import React from 'react';
import { IStoryBlokComponent } from '~interfaces/storyblock';

const ComponentNotFound = (props: IStoryBlokComponent) => (
  <div>
    Component {props.component} is not defined. Add it to components.tsx
  </div>
);

export default ComponentNotFound;
