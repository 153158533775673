import React, { useState } from 'react';
import { IMarketData } from '../../interfaces';
import styles from './infomeeting-list.module.scss';
import IconChevronDown from '@ef-global/web-ui-react/lib/icons/IconChevronDown';
import { Button } from '@ef-global/web-ui-react';

const showMore = (shortDataLength: number, fullDataLength: number) => {
  if (shortDataLength < fullDataLength) return true;
  return false
}

const renderJoinButton = (index: number, openModal: (index: number) => void, joinButtonText: string) => {
  return (
    <>
      {/* Link button for mobile */}
      <button
        className={`u-show-s-only ${styles.infomeetingListJoin}`}
        onClick={() => openModal(index)}
      >
        {joinButtonText}
      </button>
      {/* Secondary button for non mobile */}
      <Button
          secondary={true}
          className='u-show-m-up'
          onClick={() => openModal(index)}
      >
        {joinButtonText}
      </Button>
    </>
  )
}

export interface IInfomeetingList {
  list_header_text?: string;
  list_join_button_text: string;
  list_showall_button_text: string;
  infomeetings: Array<IMarketData>;
  openModal: (index: number) => void;
}

const InfomeetingList = (props: IInfomeetingList) => {
  const { infomeetings, list_header_text, list_showall_button_text, openModal, list_join_button_text } = props;
  const LIST_THRESHOLD = 2;

  const infomeetingsShow = infomeetings.slice(0, LIST_THRESHOLD);
  const [infomeetingsState, setInfomeetingsState] = useState(infomeetingsShow);

  const showAllListElements = () => {
    setInfomeetingsState(infomeetings);
  }

  return (
    <section className='u-mt-xxl'>
      {/* HEADER */}
      {list_header_text && <h2 className='ef-h4'>{list_header_text}</h2>}
      <div className={`ef-row ${styles.infomeetingListRow}`}>
        {infomeetings.length > 0 && (
          <ul className={styles.infomeetingList}>
            {infomeetingsState.map((marketData, index) => (
              <li
                key={marketData.date}
                className={`${styles.infomeetingListItem} ef-col -s-12`}
              >
                <span className={styles.infomeetingListDate}>
                  <p className='u-f-bold'> {marketData.date} </p>
                  <p>{marketData.title}</p>
                </span>
                {renderJoinButton(index, openModal, list_join_button_text)}
              </li>
            ))}
          </ul>
        )}
        {showMore(infomeetingsState.length, infomeetings.length) && (
          <button className={`u-mt-xxl ${styles.infomeetingListShowMore}`} onClick={showAllListElements}>
            {list_showall_button_text}
            <IconChevronDown className={styles.infomeetingListChevron}/>
          </button>
        )}
      </div>
    </section>
  )
}

export default InfomeetingList;
