import React, {useState, useEffect, useCallback} from 'react';
import ReactMarkdown from 'react-markdown'

import { Form } from '@ef-global/web-ui-react/lib/components/Form'
import { ButtonGroup } from '@ef-global/web-ui-react/lib/components/Button';

import SingleCountryButton from './single-country-button'
import { getAllCountries, getValueOrUndefinedFromEvent } from './library';
import {SingleCountryButtonSkeleton} from '~components/custom/students/skeletons/single-country-button-skeleton';

export default ({ title, setCountry = () => ({}), currentValue }: {title: string, setCountry: any, currentValue: string | undefined}) => {
    const emptyOption = { country: undefined, countryName: 'All countries' };
    const [listOfCountries, setListOfCountries] = useState([])

    const getAllCountriesCallback = useCallback(async () => setListOfCountries(await getAllCountries()), [listOfCountries]);

    useEffect(() => {
        if (!listOfCountries || !Array.isArray(listOfCountries) || listOfCountries.length === 0) {
            getAllCountriesCallback()
        }
    }, [listOfCountries]);

    const countryOnChange = (value: any) => setCountry(getValueOrUndefinedFromEvent(value))

    const allCountries = [emptyOption, ...listOfCountries].sort(({ countryName: countryName1 }, { countryName: countryName2 }) => countryName1.localeCompare(countryName2))

    const buttonsForNonMobile = () => (
        <ButtonGroup className='flags'>
            {allCountries.map((item, index) => (
                <SingleCountryButton
                    key={index}
                    country={item.country}
                    countryName={item.countryName}
                    onChange={countryOnChange}
                    currentValue={currentValue}
                />
            ))}
        </ButtonGroup>
    )

    const selectForMobile = () => (
        <Form.Select
            id='filter-countries'
            placeholder='Country'
            onChange={countryOnChange}
            className='u-mb-xs'
            defaultValue={currentValue}
        >
            {allCountries.map((item, index) => (
                <option key={index} value={item.country}>
                    {item.countryName}
                </option>
            ))}
        </Form.Select>
    )

    return (
        <div className='countries'>
            <div className='u-show-m-up'>
                <ReactMarkdown source={title} />
              {
                !Array.isArray(listOfCountries) || listOfCountries.length === 0 ? (
                  <ButtonGroup className='flags'>
                    <SingleCountryButtonSkeleton />
                    <SingleCountryButtonSkeleton />
                    <SingleCountryButtonSkeleton />
                    <SingleCountryButtonSkeleton />
                    <SingleCountryButtonSkeleton />
                    <SingleCountryButtonSkeleton />
                    <SingleCountryButtonSkeleton />
                    <SingleCountryButtonSkeleton />
                    <SingleCountryButtonSkeleton />
                    <SingleCountryButtonSkeleton />
                    <SingleCountryButtonSkeleton />
                    <SingleCountryButtonSkeleton />
                    <SingleCountryButtonSkeleton />
                  </ButtonGroup>
                  ) : buttonsForNonMobile()
              }
            </div>
            <div className='u-show-s-only'>
                {selectForMobile()}
            </div>
        </div>
    );
}
