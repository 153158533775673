import React from "react";
import {
  IStoryblokColorPicker,
  IStoryBlokComponent,
} from "~interfaces/storyblock";
import Components from "~components/components";
import { Accordion } from "@ef-global/web-ui-react";
import styles from "./faqs-container-item.module.scss";
import css from "@emotion/css";
import { IFaqsContainerItem } from "~/src/components/custom/faqs/faqs-container-item";

interface IBulletPointsSection extends IStoryBlokComponent {
  faqs_items: Array<IFaqsContainerItem>;
  border_bottom_separator_color: IStoryblokColorPicker;
  chevron_text_item_color: IStoryblokColorPicker;
  story: any;
  accordion_mobile: boolean;
}

/**
 * TODO:
 * This component is copied from sales site, if you edit
 * or want to edit this component for production site, make
 * sure to bring the changes to sales site also ( incase they need to keep in sync ).
 */

const FaqsContainer = (props: IBulletPointsSection) => {
  const {
    faqs_items,
    border_bottom_separator_color,
    chevron_text_item_color,
    story,
  } = props;

  const acordionSectionCss = css`
    ${!!border_bottom_separator_color?.color &&
    `.ef-accordion__item {
        border-bottom-color: ${border_bottom_separator_color?.color} !important;
      }`}
    ${!!chevron_text_item_color?.color &&
    `h1,h2,h3,h4,h5,h6, p, button, ul, li {
        color: ${chevron_text_item_color?.color} !important;
    }.ef-collapsible__toggle {
      > svg {
        fill: ${chevron_text_item_color?.color} !important;
        path {
          fill: ${chevron_text_item_color?.color} !important;
        }
      }
    }`}
    .ef-collapsible__toggle {
      min-width: 100%;
    }
    .ef-collapsible__content {
      p {
        max-width: 630px;
        margin-bottom: 0px;
        padding-bottom: 16px;
      }
    }
  `;

  const renderItems = Array.isArray(faqs_items)
    ? faqs_items.map((blok) => Components(blok, story))
    : null;

  return (
    <Accordion
      css={acordionSectionCss}
      className={`rendering__faqs u-mb-xl full-width ${styles.bulletColumn}`}
    >
      {renderItems}
    </Accordion>
  );
};

export { FaqsContainer };
export default FaqsContainer;
