import React from 'react';
import {Table as WebUITable} from '@ef-global/web-ui-react/lib/components/Table';
import {classNames} from '~helpers/generic-helpers';
import SbEditable from 'storyblok-react';

const Table = (props: any) => {
  const {
    bordered_table,
    striped_table,
    row_highlight,
    col_highlight,
    font_size,
    content,
    remove_horizontal_dividers,
    alignment
  } = props;
  const classes = classNames(
    bordered_table ? '-bordered' : '',
    striped_table ? '-striped' : '',
    row_highlight ? '-unset-header-styles' : '',
    col_highlight ? '-unset-col-header-styles' : '',
    font_size
  );

  const isTableHeadEmpty = () => {
    return (
      typeof content?.thead?.find((th: any) => th.value !== '') !== 'undefined'
    );
  };

  const cellStyles = {textAlign: alignment, color: 'inherit', borderBottom: remove_horizontal_dividers ? 'none' : '1px solid var(--color-ui-border)'}

  return (
    <SbEditable content={props}>
      <WebUITable className={classes}>
        {isTableHeadEmpty() && (
          <WebUITable.Head>
            <WebUITable.Row>
              {content.thead &&
              content.thead.map((th: any) => (
                <WebUITable.Cell style={cellStyles} key={th._uid}>{th.value}</WebUITable.Cell>
              ))}
            </WebUITable.Row>
          </WebUITable.Head>
        )}
        <WebUITable.Body>
          {content.tbody &&
          content.tbody.map((tr: any) => {
            return (
              <WebUITable.Row key={tr._uid}>
                {tr.body &&
                tr.body.map((td: any) => <WebUITable.Cell style={cellStyles} key={td._uid}>{td.value}</WebUITable.Cell>)}
              </WebUITable.Row>
            );
          })}
        </WebUITable.Body>
      </WebUITable>
    </SbEditable>
  );
};

export default Table;
