import React, { useEffect, useState } from 'react';
import LazyLoader from '~helpers/lazy-loader';
import { buildSources, getCurrentAspectRatio } from './image-helpers';
import { RES_PER_SCREEN, PLACEHOLDER_IMAGE_DIMENSIONS, BLUR_INTENSITY } from './image-constants';
import { transformImageWithAspectRatio, transformImageWithAspectRatioLowRes } from '~services/image-service';
import { css } from '@emotion/core';
import { IFocusPoint } from '~src/interfaces/storyblock';
import { BreakpointContext } from '@ef-global/web-ui-react/lib/components/BreakpointContext';

interface IFocusPointImgProps extends React.HTMLAttributes<HTMLImageElement> {
  focusPoint: IFocusPoint;
  alt: string;
  aspectRatio?: Array<number> | number;
  width?: number;
}

const FocusPointImg = (props: IFocusPointImgProps) => {
  const {focusPoint, alt, aspectRatio, className, style, width, ...attributes} = props;
  const [enabled, setEnabled] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const { currentBp } = React.useContext(BreakpointContext);
  const currentAspectRatio = getCurrentAspectRatio(focusPoint, aspectRatio, currentBp);
  const fallbackLowResSrc = transformImageWithAspectRatioLowRes(focusPoint, PLACEHOLDER_IMAGE_DIMENSIONS, currentAspectRatio);
  const fallbackSrc = transformImageWithAspectRatio(focusPoint, focusPoint.imageSize.width, currentAspectRatio);
  const isImgSvg = focusPoint.image.endsWith('.svg');

  const Container = css`
    overflow: hidden;
    filter: blur(${firstLoad ? 0 : BLUR_INTENSITY}px);
    transition: filter 1s ease-in-out;
    position: relative;
  `;

  const Img = css`
    width: ${width ? width + 'px' : '100%' };
  `;

  const Placeholder = css`
    ${Img};
    position: absolute;
    top: 0;
  `;

  const Original = css`
    opacity: 0;
  `;

   /**
   * FIX for when there is no JS enabled
   * if loads the useEffect of enabled, means JS is
   * enabled, otherwise use default image loading on GatsbyJS.
   */
   useEffect(() => {
    setEnabled(true);
  }, []);

  return (
    !isImgSvg ? (
      <div css={enabled ? Container : {}} className={enabled ? "focus-point-container" : ""}>
        <LazyLoader>
          <picture
            onLoad={() => {
              setFirstLoad(true);
            }}
            css={Original}
            {...firstLoad && {style: {...style, opacity: '1'}}}
            {...attributes}
          >
            {buildSources(props.focusPoint, RES_PER_SCREEN, aspectRatio)}
            <img src={fallbackSrc} alt={alt} css={Img}/>
          </picture>
        </LazyLoader>
        <img
          src={fallbackLowResSrc}
          alt={alt}
          css={enabled ? Placeholder : {}}
          className={className}
          {...firstLoad && {style: { ...style, opacity: '0'}}}
        />
      </div>
    ) : (
      // TODO: Fix the styling for the lazy loader
      // <LazyLoader>
        <img
          src={fallbackSrc}
          alt={alt}
          css={Img}
        />
      // </LazyLoader>
    )
  )
}

export default FocusPointImg;
