import React, { useContext, useEffect, useState } from "react";
import { IFocusPoint, IStoryBlokComponent } from "~interfaces/storyblock";
import { QuizContext } from "./quiz";
import { BreakpointContext } from "@ef-global/web-ui-react/lib/components/BreakpointContext";
import FocusPointBgWithResponsiveRender from "~components/utils/focusPointBg";

export interface IAnswerProps extends IStoryBlokComponent {
  answer_text: string;
  image: IFocusPoint;
  answer_key: string;
  question_uid?: string;
}

const Answer = (props: IAnswerProps) => {
  const { answer_text, image, answer_key, _uid, question_uid = "" } = props;
  const { quizState, quizDispatch } = useContext(QuizContext);
  const [isSelected, setIsSelected] = useState(false);
  const { currentBp } = React.useContext(BreakpointContext);
  const cardType = currentBp === "s" ? "teaser" : "teaser";

  const handleClick = (
    questionUid: string,
    answerUid: string,
    answerKey: string
  ) => {
    quizDispatch({
      type: "SELECT_ANSWER",
      question_info: {
        question_uid: questionUid,
        answer_uid: answerUid,
        answer_key: answerKey,
      },
    });
  };

  useEffect(() => {
    if (quizState?.questionUids[question_uid]?.selected_answer_uid === _uid) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [quizState]);

  const hasSelectedAnswer =
    quizState?.questionUids?.[question_uid]?.selected_answer_uid?.length > 0;

  return (
    <article
      className={`ef-col -x-m-center -m-4 -m-y-center -s-12 u-s-mb-m quiz-answers ${
        isSelected ? "selected-box" : ""
      } ${hasSelectedAnswer ? "greyed-on-answer" : ""}`}
      onClick={() => handleClick(question_uid, _uid, answer_key)}
    >
      <div className={`ef-card--${cardType} ${isSelected ? "selected" : ""}`}>
        {image.image && (
          <FocusPointBgWithResponsiveRender
            focusPoint={image}
            alt={``}
            style={{
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: `${image?.focusPoint?.x ?? "50"}% ${
                image?.focusPoint?.y ?? "50"
              }%`,
            }}
            className="ef-card--teaser__img"
            aspectRatio={1}
          />
        )}
        <div className={`ef-card--${cardType}__inner`}>
          <div className={`ef-card--${cardType}__content`}>
            <h5 className={`ef-card--${cardType}__title`}>{answer_text}</h5>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Answer;
