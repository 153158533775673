// tslint:disable-next-line:no-submodule-imports
import animateScroll from 'react-scroll/modules/mixins/animate-scroll';

const scroll = (id: string) => {
  const element = document.getElementById(id);
  if (!id || !element) { return; }

  animateScroll.scrollTo(element.offsetTop, {
    delay: 0,
    duration: element.offsetTop / 5,
    smooth: 'easeInOutQuart',
  });
};

export {
  scroll,
};
