import {
  IStoryblokColorPicker,
  IStoryBlokComponent,
} from "~interfaces/storyblock";
import React from "react";
import { IIconProps } from "~components/custom/icon";
import { IText } from "~interfaces/components";
import styles from "./faqs-container-item.module.scss";
import { Accordion } from "@ef-global/web-ui-react";
import css from "@emotion/css";
import Markdown from "~/src/components/custom/markdown";
import Components from "~/src/components/components";

export interface IFaqsContainerItem extends IStoryBlokComponent {
  icon: Array<IIconProps>;
  title: IText;
  title_header: string;
  faqs_title_color: IStoryblokColorPicker;
  text: string;
  content: string;
  blok_content: IStoryBlokComponent[];
  // color styles
  faqs_subtitle_color: IStoryblokColorPicker;
  border_bottom_separator_color: IStoryblokColorPicker;
  chevron_text_item_color: IStoryblokColorPicker;
  // Sb obj
  story: any;
}

/**
 * TODO:
 * This component is copied from sales site, if you edit
 * or want to edit this component for production site, make
 * sure to bring the changes to sales site also ( incase they need to keep in sync ).
 */
const FaqsContainerItem = (props: IFaqsContainerItem) => {
  const {
    title,
    title_header,
    content,
    blok_content,
    border_bottom_separator_color,
    chevron_text_item_color,
    _uid,
    story,
  } = props;

  const faqsItemStyle = css`
    ${!!border_bottom_separator_color?.color &&
    `.ef-accordion__item {
      border-bottom-color: ${border_bottom_separator_color?.color} !important;
    }`}
    ${!!chevron_text_item_color?.color &&
    `h1,h2,h3,h4,h5,h6, p, button, ul, li {
        color: ${chevron_text_item_color?.color} !important;
    }
    .ef-collapsible__toggle {
      > svg {
        fill: ${chevron_text_item_color?.color} !important;
        path {
          fill: ${chevron_text_item_color?.color} !important;
        }
      }
    }`}
  `;

  return (
    <div
      key={_uid}
      className={`faqs__block`}
      // @ts-ignore
      itemscope="true"
      itemProp="mainEntity"
      itemtype="https://schema.org/Question"
      css={faqsItemStyle}
    >
      <Accordion.Item
        // @ts-ignore
        icon
        className={`${styles.accordionItem} faqs__block `}
      >
        <Accordion.ItemTitle
          as={title_header as any}
          icon
          inline
          className={`full-width u-mb-0`}
          itemProp="name"
        >
          <div className={`${styles.bulletIcon} ${styles.bulletIconNoIcon}`}>
            {title}
          </div>
        </Accordion.ItemTitle>
        <div
          //@ts-ignore
          itemscope="true"
          itemProp="acceptedAnswer"
          itemtype="https://schema.org/Answer"
        >
          <Accordion.ItemContent>
            {content !== "" && typeof content !== "undefined" ? (
              <Markdown source={content} />
            ) : (
              blok_content &&
              blok_content.map((blok) => Components(blok, story))
            )}
          </Accordion.ItemContent>
        </div>
      </Accordion.Item>
    </div>
  );
};

export { FaqsContainerItem };
export default FaqsContainerItem;
