import { Surface as WebUISurface } from '@ef-global/web-ui-react/lib/components/Surface';
import React from 'react';
import Components from '~components/components';
import { IStoryBlokComponent } from '~interfaces/storyblock';

interface ISurfaceProps extends IStoryBlokComponent {
  name: string;
  border: 'outline' | 'shadow' | 'deepShadow';
  rounded: boolean;
  content: any;
}

const Surface = (props: ISurfaceProps) => {
  return (
    <div>
      {props.content !== undefined &&
        props.content.map((blok: IStoryBlokComponent) => (
          <WebUISurface
            outline={props.border === 'outline'}
            shadow={props.border === 'shadow'}
            deepShadow={props.border === 'deepShadow'}
            rounded={props.rounded}
            key={blok._uid}
          >
            {Components(blok)}
          </WebUISurface>
        ))}
    </div>
  );
};

export default Surface;
