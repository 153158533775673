import React, {useState, useEffect} from 'react';
import {Modal} from '@ef-global/web-ui-react';
import {useCookies} from 'react-cookie';
import {Button} from '@ef-global/web-ui-react/lib/components/Button';
import {ProgressBars} from './progress-bars';
import {CountryListFilter} from '~components/custom/students/student-search-form/country-list-filter';
import {updateLocation} from '~components/custom/students/library';
import {GenderAgeFilters} from '~components/custom/students/student-search-form/gender-age-filters';
import {StudentSearchForm} from '~components/custom/students/student-search-form/student-search-form';

export const StudentSearchFormModal = (props: any) => {
  const {minAge, maxAge, setCountry, setAge, setGender, country, form_id, disable_form_lock, query_disable_form} = props

  const [cookies, setCookie] = useCookies(['ef-student-search']);
  const [step, setStep] = useState(1);

  const setGenderAndSaveFilter = (value: string) => {
    const parsedValue = value.toLowerCase() === 'open to all' || value.toLowerCase() === 'no preference' ? undefined : value
    updateLocation({ gender: parsedValue });
    setGender(parsedValue);
  }

  const setAgeAndSaveFilter = (value: number) => {
    const parsedValue = isNaN(value) ? undefined : value
    // @ts-ignore
    updateLocation({ age: parsedValue });
    setAge(parsedValue);
  }

  const setCountryAndSaveFilter = (value: string) => {
    updateLocation({country: value});
    setCountry(value);
  }

  useEffect(() => {
    if (query_disable_form === 'true') {
      setCookie('ef-student-search', {hasFilledForm: true}, {
        secure: true,
      })
    }
  }, [query_disable_form]);

  return (
    <Modal
      open={(!cookies['ef-student-search']?.hasFilledForm && !disable_form_lock && query_disable_form !== 'true') ?? true}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      bodyOpenClassName='ReactModal__Body--open-students'
    >
      {/*
        // @ts-ignore */}
      <Modal.Header>
        {/*
          // @ts-ignore */}
        <Modal.Title>Find your Student</Modal.Title>
      </Modal.Header>
      <Modal.Content>
        <ProgressBars
          currentStep={step}
          totalSteps={3}
        />
        {
          step === 1 ? (
            <>
              <p>Prefer a student from a specific country?</p>
              <CountryListFilter
                currentCountry={country}
                setCountry={setCountryAndSaveFilter}
                currentStep={step}
                setStep={setStep}
              />
            </>
          )
          : null
        }
        {
          step === 2 ? (
            <>
              <p>Select your preference</p>
              <GenderAgeFilters
                setStep={setStep}
                minAge={minAge}
                maxAge={maxAge}
                setAge={setAgeAndSaveFilter}
                setGender={setGenderAndSaveFilter}
              />
            </>
          )
          : null
        }
        {
          step === 3 ? (
            <>
              <div className='ef-row u-text-center'>
                <div className='ef-col'>
                  <StudentSearchForm
                    form_id={form_id ?? '3758620'}
                    submit_button_text='Show Students'
                    spacing_between_elements='u-mb-xs'
                    setCookie={setCookie}
                  />
                </div>
              </div>
            </>
          )
          : null
        }
      </Modal.Content>
      {
        step !== 3 ? (
            <Modal.Footer>
              <div className='ef-row -x-between -y-center'>
                <div className='ef-col -s-shrink'>
                  <Button
                    secondary
                    square
                    className='skip-button'
                    onClick={() => setStep(step + 1)}
                  >
                    Skip
                  </Button>
                </div>
                <div className='ef-col -s-shrink'>
                  <Button
                    primary
                    square
                    onClick={() => setStep(step + 1)}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Modal.Footer>
          )
          : null
      }
    </Modal>
  )
}
