import { css } from '@emotion/core';
import React from 'react';
import Components from '~components/components';
import { createSpacingClass } from '~components/utils/spacing';
import { ISpacing, IAlignmentItem } from '~interfaces/components';
import { IStoryBlokComponent } from '~interfaces/storyblock';
import { IBoxModel } from '~interfaces/plugins';
import { buildAlignmentClass } from '../utils/alignment';
import withSpacing from '@hsey/components/src/components/utils/withSpacingHOC';

interface IColumnProps extends IStoryBlokComponent {
  components: Array<any>;
  column_alignment_item: Array<IAlignmentItem>;
  content_alignment: Array<string>;
  spacing: Array<ISpacing>;
  boxModel: string;
  box_model: IBoxModel;
}

const Column = (props: IColumnProps) => {
  const columnSpacing = createSpacingClass(props.spacing);

  const alignmentClasses = props.column_alignment_item?.reduce((acc, current) => {
    const alignmentClass = acc + buildAlignmentClass(current) + ' ';
    return alignmentClass;
  }, '').trimRight();

  const contentAlignment = props.content_alignment && css`
    justify-content: ${props.content_alignment};
  `;

  return (
    <div
      className={`ef-col ${alignmentClasses} ${columnSpacing} ${props.content_alignment && 'display-flex'} ${props.boxModel && props.boxModel}`}
      css={contentAlignment}
    >
      {props.components && props.components.map((blok) => Components(blok, props.story))}
    </div>
  );
};

export default withSpacing(Column);
