import React from 'react';
import Components from '~components/components';
import { IStoryBlokComponent } from '~interfaces/storyblock';

interface IFormSubmissionProps extends IStoryBlokComponent {
  content: Array<IStoryBlokComponent>;
}

const FormSubmission: React.SFC<IFormSubmissionProps> = (props) => {
  const { content } = props;
  return (
      content?.length > 0 ? (
        <>
          {content.map((blok) => Components(blok))}
        </>
      ) : <div/>
  );
};

export default FormSubmission;
