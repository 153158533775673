import { isProduction } from "~components/utils/env-helpers";

const NETLIFY_FORMST_FN_URI = isProduction()
  ? "/.netlify/functions/fetchForm"
  : "https://hsey-host-family-us.netlify.app/.netlify/functions/fetchForm";

const HSEY_UTILS_CACHE =
  "https://hsey.efexchangeyear.org/api/marketing/redis_cache";

/**
 * Function called when generating forms or when loading then async.
 * @param formId field id to fetch
 * @returns form data.
 */
const fetchFields = async (formId: string) => {
  let cachedForm: any;
  let key: string = `formstack-${formId}`;

  // 1. First call the API to ensure form is cached in REDIS
  const cacheResponse: any = await fetch(`${HSEY_UTILS_CACHE}?key=${key}`, {
    method: "GET",
  });
  const { cached, cachedData } = await cacheResponse.json();

  // 2. If form is not catched, call form to formstack api and
  // send it to te redis cache and store, then return the form.
  if (!cached) {
    cachedForm = await fetch(
      `${NETLIFY_FORMST_FN_URI}/fetchForm?formid=${formId}?cached=${
        cached ? "yes" : "no"
      }`
    );
    const cachedJSONResponse = await cachedForm.json();

    // 2.1 Store new fetched form to redis cache
    const cacheStoreResponse: any = await fetch(`${HSEY_UTILS_CACHE}`, {
      method: "POST",
      body: JSON.stringify({ key, data: cachedJSONResponse }),
    });
    const { cachedData } = await cacheStoreResponse.json();
    cachedForm = cachedData;
  } else {
    // If form is still catched, return it.
    cachedForm = cachedData;
  }

  return cachedForm?.fields ?? [];
};

const submitForm = (body: any, formId: string) => {
  return fetch(`${NETLIFY_FORMST_FN_URI}?formid=${formId}`, {
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });
};

export { fetchFields, submitForm };
