import React, { Component } from "react";
import { css } from "@emotion/core";
import { isGatsbyProduction } from "~/src/components/utils/env-helpers";
import { DummyHeader } from "./DummyHeader";

export const getHref = () => {
  if (typeof window !== "undefined") return window?.location?.href;
  return "";
};

const DummyComponentCss = css`
  border: 1px solid blue;
`;

/**
 * TODO: Component not finished, paused.
 * Let the code here to finish once we can retake the task.
 */
interface CefcomHeaderCustomizerInterface {
  is_transparent: boolean;
  appearance: "dark" | "white";
}

/**
 * TODO: Reason to use class component is due a weird error caused
 * when using hooks in functional component.
 */

class CefcomHeaderCustomizer extends Component {
  constructor(props: CefcomHeaderCustomizerInterface) {
    super(props);
    // @ts-ignore
    this.state = {};
    this.hotReloadStyles = this.hotReloadStyles.bind(this);
    this.windowCheckInterval = null;
  }

  componentDidMount() {
    const _this = this;
    if (this.windowCheckInterval) {
      clearInterval(this.windowCheckInterval);
      this.windowCheckInterval = null;
    } else {
      this.windowCheckInterval = setInterval(() => {
        if (typeof window !== `undefined`) {
          _this.hotReloadStyles();
        }
      }, 100);
    }
  }

  componentDidUpdate() {
    const _this = this;
    clearInterval(this.windowCheckInterval);
    this.windowCheckInterval = setInterval(() => {
      if (typeof window !== `undefined`) {
        _this.hotReloadStyles();
      }
    }, 100);
  }

  hotReloadStyles = () => {
    const { is_transparent, appearance, absoluted } = this.props;

    if (typeof window !== `undefined`) {
      // @ts-ignore
      const header = window.document.querySelector("#cefcom-globalheader");
      if (header) {
        // Reset class before re-apply
        header.classList.remove("transparent-b");
        // apply appearance first, then transparent.
        if (appearance == "B") {
          header.classList.add("dark");
          header.classList.remove("white");
          header.classList.remove("transparent");
          if (is_transparent) {
            header.classList.add("transparent-b");
            header.classList.remove("solid");
          } else {
            header.classList.add("solid");
            header.classList.remove("transparent-b");
          }
        } else if (appearance == "W") {
          header.classList.add("white");
          header.classList.remove("dark");
          header.classList.remove("transparent-b");
          if (is_transparent) {
            header.classList.remove("solid");
            header.classList.add("transparent");
          } else {
            header.classList.add("transparent");
            header.classList.add("solid");
          }
        } else {
          header.classList.remove("dark");
          header.classList.remove("white");
          if (is_transparent) {
            header.classList.remove("solid");
            header.classList.add("transparent");
          } else {
            header.classList.add("solid");
            header.classList.remove("transparent");
          }
        }

        if (absoluted) {
          header.classList.add("absoluted");
        } else {
          header.classList.remove("absoluted");
        }
      }
      clearInterval(this.windowCheckInterval);
      this.windowCheckInterval = null;
    }
  };

  render() {
    const isDev = !isGatsbyProduction();
    if (isDev && getHref().includes("editor")) {
      return (
        <div css={DummyComponentCss}>
          <DummyHeader />
        </div>
      );
    }
    // When live show no component

    return <></>;
  }
}

export default CefcomHeaderCustomizer;
