import React, {useState} from 'react';
import {IStoryblokColorPicker, IStoryBlokComponent} from '~interfaces/storyblock';
import Components from '~components/components';
import styles from './sticky-cta.module.scss'
import {useScrollPosition} from '@n8tb1t/use-scroll-position'
import {BreakpointContext} from '@ef-global/web-ui-react/lib/components/BreakpointContext';

interface StickyCtaProps extends IStoryBlokComponent {
  hide: boolean;
  sticky_position: string;
  background_color?: IStoryblokColorPicker;
  appear_at: string;
  stop_appearing_at: string;
  content: any;
}

const StickyCta = (props: StickyCtaProps) => {
  const {hide, sticky_position, background_color, appear_at, stop_appearing_at, content, story} = props
  const {currentBp} = React.useContext(BreakpointContext);

  if (hide || currentBp !== 's') {
    return null
  }

  const [showOnScroll, setShowOnScroll] = useState(false)

  useScrollPosition(({currPos}: {currPos: {x: number, y: number}}) => {
    const showAfter = (Number(appear_at || 0))
    const hideAfter = (Number(stop_appearing_at || showAfter + 20000))
    const isShow = Math.abs(currPos.y) >= showAfter && Math.abs(currPos.y) <= hideAfter
    if (isShow !== showOnScroll) setShowOnScroll(isShow)
  }, [showOnScroll])

  return (
    <div
      className={`${styles.stickyCta}
        ${showOnScroll
        ? (sticky_position === 'bottom' ? styles.stickyCtaVisibleBottom : styles.stickyCtaVisibleTop)
        : (sticky_position === 'bottom' ? styles.stickyCtaHiddenBottom : styles.stickyCtaHiddenTop)}`}
      style={{
        backgroundColor: background_color?.color || '#fff'
      }}
    >
      {content && content.map((blok: any) => Components(blok, story))}
    </div>
  )
}

export default StickyCta
