import React from 'react';
import {Surface} from '@ef-global/web-ui-react/lib/components/Surface';

export const SingleStudentRowSkeleton = (props: any) => {
  const {classNameForNoOfColumns} = props

  return (
    <div className='ef-row students--results'>
      <div className={`ef-col -s-${classNameForNoOfColumns}`}>
        <SingleStudentSkeleton />
      </div>
      {classNameForNoOfColumns <= 6 ? (
        <div className={`ef-col -s-${classNameForNoOfColumns}`}>
          <SingleStudentSkeleton />
        </div>
      ) : null }
      {classNameForNoOfColumns <= 4 ? (
        <div className={`ef-col -s-${classNameForNoOfColumns}`}>
          <SingleStudentSkeleton />
        </div>
      ) : null }
    </div>
  )
}

const SingleStudentSkeleton = () => (
  <Surface
    shadow
    rounded
    className='student'
    style={{
      maxHeight: '255px',
    }}
  >
    <div className='ef-row'>
      <div className='ef-col -s-3'>
        <div
          style={{
            width: '48px',
            maxWidth: '48px',
            height: '48px',
            margin: 'auto',
          }}
        >
          <div className='pulse' style={{borderRadius: '999px'}}>&nbsp;</div>
        </div>
      </div>
      <div className='ef-col -s-9'>
        <div
          style={{
            display: 'block',
            height: '20px',
            width: '80%',
            background: 'lightgray'
          }}
        >
          <div className='pulse'>&nbsp;</div>
        </div>
        <br/>
        <div
          className='student--gender-and-country'
          style={{
            width: '60%',
            height: '20px',
            background: 'lightgray',
          }}
        >
          <div className='pulse'>&nbsp;</div>
        </div>
      </div>
    </div>
    <div className='ef-row student--interests'>
      <div className='ef-col -s-9 -s-offset-3'>
        <ul>
          <li style={{height: '15px', width: '40%', background: 'lightgray', marginBottom: '5px'}}>
            <div className='pulse'>&nbsp;</div>
          </li>
          <li style={{height: '15px', width: '40%', background: 'lightgray', marginBottom: '5px'}}>
            <div className='pulse'>&nbsp;</div>
          </li>
          <li style={{height: '15px', width: '40%', background: 'lightgray', marginBottom: '5px'}}>
            <div className='pulse'>&nbsp;</div>
          </li>
        </ul>
      </div>
    </div>
    <div className='ef-row student--footer'>
      <div className='ef-col -s-9 -s-offset-3'>
        <div style={{height: '20px', width: '50%', background: 'lightgray', marginBottom: '5px'}}><div className='pulse'>&nbsp;</div></div>
      </div>
    </div>
  </Surface>
)
