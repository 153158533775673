interface ISubmissionState {
  modalIsOpen: boolean;
  status: 'SUCCESS' | 'ERROR' | 'NOT_SUBMITTED' | 'SUBMITTING';
}

interface ISubmissionAction {
  type: 'OPEN_SUCCESS' | 'OPEN_ERROR' | 'CLOSE' | 'SUBMITTING' | 'TEST';
}

function formSubmissionReducer(state: ISubmissionState, action: ISubmissionAction): ISubmissionState {
  switch (action.type) {
    case 'OPEN_SUCCESS':
      return { modalIsOpen: true, status: 'SUCCESS' };
    case 'OPEN_ERROR':
      return { modalIsOpen: true, status: 'ERROR' };
    case 'CLOSE':
      return { ...state, modalIsOpen: false };
    case 'SUBMITTING':
      return { ...state, status: 'SUBMITTING' };
    default:
      return state;
  }
}

export {
  ISubmissionState,
  ISubmissionAction,
  formSubmissionReducer,
};
