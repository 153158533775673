import React, { useEffect, useState } from "react";
import { IStoryBlokComponent } from "~interfaces/storyblock";
import axios from "axios";
import { isGatsbyProduction } from "~components/utils/env-helpers";
import { EsStudentHit } from "~components/custom/student-list/interfaces";
import StudentCard from "~components/custom/student-list/StudentCard";
import { CarouselComponent } from "../../web-ui-react/carousel";

enum StudentListCarrouselOption {
  default = "default",
  mobile = "mobile",
  both = "both",
}
interface StudentListProps extends IStoryBlokComponent {
  qty: "3" | "6";
  listUID: "";
  extraUriParams: "";
  affiliateId: null;
  enableMobileCarrousel: StudentListCarrouselOption;
}

interface StudentResponse {
  data: Array<EsStudentHit>;
}

/**
 * This component is basically a copy from the old one found in FYS.
 * @param qty number of students to consume and display from elasticsearch FYS.
 * @param listUID when specified prints the following list id.
 * @param affiliateId adds ?affiliateId=X to uri
 * @param extraUriParams extra uri params to concat ex: &test=2
 * @returns
 */
const StudentList = (props: StudentListProps) => {
  const {
    qty,
    listUID,
    extraUriParams,
    affiliateId,
    enableMobileCarrousel = StudentListCarrouselOption.default,
  } = props;
  const apiUrlPrefix: string = isGatsbyProduction()
    ? "https://my.efexchangeyear.org"
    : "https://localhost:3000";
  const [students, setStudents] = useState<Array<EsStudentHit>>([]);

  // consume different urls depending on supplied props.
  const genericList: string = `${apiUrlPrefix}/api/student/students/?size=${qty}`;
  const specificList: string = `${apiUrlPrefix}/api/lists/getListStudents/?uid=${listUID}${
    affiliateId ? "&affiliateId=" + affiliateId : ""
  }&from=0&size=${qty}`;
  const isSpecific: boolean = typeof listUID == "string" && listUID !== "";

  // On mount component load list
  useEffect(() => {
    axios
      .get(isSpecific ? specificList : genericList)
      .then((res: StudentResponse) => {
        const list = res?.data ?? [];
        // If list is not empty, set it
        // else if is specific and coming empty set generic student list.
        if (list && list.length > 0) {
          setStudents(list);
        } else if (isSpecific) {
          // If no data coming from list, recall with generic list of students
          axios.get(genericList).then((res: StudentResponse) => {
            const list = res?.data ?? [];
            if (list && list.length > 0) {
              setStudents(list);
            }
          });
        }
      })
      .catch((exception) => {
        console.log(
          "Error on studentList:",
          exception?.message ?? "No message."
        );
      });
  }, []);

  const printStudentCards = (
    student: EsStudentHit,
    props: any = {}
  ): JSX.Element => {
    return (
      <StudentCard
        key={student._source.id}
        linkPrefix={apiUrlPrefix}
        affiliateId={affiliateId}
        student={student}
        extraUriParams={extraUriParams}
        {...props}
      />
    );
  };

  const printStudentListOption = (): JSX.Element => {
    if (students && students.length == 0) {
      return <p> No students found.</p>;
    }
    switch (enableMobileCarrousel) {
      case StudentListCarrouselOption.default:
        return <div className="ef-row">{students.map(printStudentCards)}</div>;
      case StudentListCarrouselOption.mobile:
        return (
          <>
            <div className="u-show-s-only">
              <CarouselComponent>
                {students.map((item) =>
                  printStudentCards(item, { noClass: true })
                )}
              </CarouselComponent>
            </div>
            <div className="u-show-m-up ef-row">
              {students.map(printStudentCards)}
            </div>
          </>
        );
      case StudentListCarrouselOption.both:
        return (
          <CarouselComponent
            centeredSlides={false}
            {...{
              breakpoints: {
                768: {
                  slidesPerView: 1,
                },
                1024: {
                  slidesPerView: 3,
                },
              },
            }}
          >
            {students.map((item) => printStudentCards(item, { noClass: true }))}
          </CarouselComponent>
        );
    }
  };

  return printStudentListOption();
};

export default StudentList;
