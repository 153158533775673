import React from 'react';

interface IInfomeetingErrorProps {
  errorMessage: string;
}

const InfomeetingError = ({errorMessage}: IInfomeetingErrorProps) => {
  return (
    <div className={`u-bg-first-paper u-p-xxl u-text-center`}>
      <p>{errorMessage}</p>
    </div>
  )
}

export default InfomeetingError;
