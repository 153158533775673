// Based on https://github.com/ILC-Technology/rhps-pages/blob/dev/components/styles/form.styles.js
import styled from '@emotion/styled';

export default styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .ef-form-submit {
    width: 100%;
    margin-bottom: 1em;
  }

  .ef-form-text-wrapper,
  .ef-form-number-wrapper {
    .ef-form-label {
      display: inline-block;
      position: absolute;
      left: 17px;
      top: 12px;
      color: currentColor;
      opacity: .5;
      pointer-events: none;
      cursor: text;
      transition: all 150ms ease-in;
    }
  }

  input:focus ~ .ef-form-label,
  .ef-form-text-wrapper:not(.ef-form-empty) > .ef-form-label,
  .ef-form-number-wrapper:not(.ef-form-empty) > .ef-form-label {
    color: #979797;
    transform: translateY(-25px);
    font-size: 90%;
    line-height: .9;
    opacity: 1;
    text-shadow: 1px 0 0 var(--input-bg), -1px 0 0 var(--input-bg), 2px 0 0 var(--input-bg), -2px 0 0 var(--input-bg), 0 1px 0 var(--input-bg), 0 -1px 0 var(--input-bg), 0 2px 0 var(--input-bg), 0 -2px 0 var(--input-bg);
    background-color: var(--input-bg);
    padding: 0 4px;
    top: 18px;
    border-radius: 4px;
  }

  .ef-form-control {
    margin-bottom: 1em;
  }

  .ef-form-control {
    flex-wrap: wrap;

    &.ef-form-checkbox-wrapper {
      & > .label-text {
        flex-basis: 10%;
      }

      & > .ef-boolean__label {
        flex-basis: 90%;
      }
    }

    ::after {
      position: absolute !important;
      top: 16px;
      right: 15px;
    }
  }

  /* For the dropdown the svg is placed in a different position */
  .ef-form-dropdown-wrapper,
  .ef-form-date-wrapper {
    ::after {
      top: 45px;
    }
  }

  .ef-form-radio-wrapper,
  .ef-form-date-wrapper,
  .ef-form-dropdown-wrapper {
    flex-wrap: wrap;
  }

  .ef-form-birthDate-wrapper {
    input {
      /* Disable default ios style */
      -webkit-appearance: none;
    }
  }

  .ef-form-validation.-is-invalid {
      display: block;
      & > * {
        color: inherit;
        font-size: inherit;
        margin: 8px 0 0 0;
      }
  }

  /* Handle the submission button when the form is submitting */
  .ef-form-submitting {
    .ef-form-submit {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .ef-form-birthDate-wrapper {
    label {
      width: 100%;
    }

    select {
      width: calc(33.33% - 8px);
      margin-right: 8px;
      background-color: #fff !important;
      padding-right: 0 !important;
      &:last-of-type {
        margin-right: 0;
        width: 33.33%;
      }
    }
  }

`
