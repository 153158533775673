/**
 * Simple debounce fn
 * @param fn
 * @param ms
 * @returns {Function}
 */
export const debounce = (fn: (...args: any[]) => void, ms: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: any[]) => {
    const next = () => fn(...args);
    clearTimeout(timeout);
    timeout = setTimeout(next, ms);
  };
};
