import { ISpacing } from '~interfaces/components';

/**
 * Creates a Spacing item based on Device, Type, Space and Position
 * Template: u-<Device>-<Type + Position>-<Size>
 * Example: u-s-pt-xl
 */
const createSpacingItem = (spacing: ISpacing) => {
  const { device = '', position, size = '', type = '', alignment  } = spacing;

  let className = '';

  if (alignment) {
    className += `u${device}${alignment} `;
  }

  // Creates a spacing class for each position
  // This allows one spacing item to have, for example, both padding top and bottom
  return position?.reduce((acc, current) => {
    return acc + `u${device}${type}${current}${size} `;
  }, `${className}`);
};

/**
 * Returns a string with all the classes generated from the spacing utility
 */
export const createSpacingClass = (spacingItems: Array<ISpacing>) => {
  return spacingItems?.reduce((acc, curr) => {
    return `${acc} ${createSpacingItem(curr)}`;
  }, '').trim();
};
