import React, { useState } from 'react';
import { BreakpointContext } from '@ef-global/web-ui-react/lib/components/BreakpointContext';
import { IFocusPoint } from '~src/interfaces/storyblock';
import { RES_PER_SCREEN, PLACEHOLDER_IMAGE_DIMENSIONS, BLUR_INTENSITY } from './image-constants';
import LazyLoader from '~src/helpers/lazy-loader';
import { css } from '@emotion/core';
import { transformImage } from '~src/services/image-service';
import { buildSources, getCurrentAspectRatio } from '~components/utils/image-helpers';

/**
 * Gets the source of the loaded img in <picture>
 */
const getLoadedImg = (img: any) => {
  return img.currentSrc || img.src;
}

interface IFocusPointBgProps extends React.HTMLAttributes<HTMLElement> {
  focusPoint: IFocusPoint;
  customTag?: any;
  alt?: string;
  aspectRatio?: Array<number> | number;
}

const FocusPointBg = (props: IFocusPointBgProps, initialAspectRatio: number) => {
  const {customTag= 'div', focusPoint, alt= '', className, aspectRatio, style, ...attributes} = props;
  const CustomTag = customTag;
  const PlaceholderSrc = transformImage(
    focusPoint.image,
    {focusPoint: focusPoint.focusPoint, originalSize: focusPoint.imageSize},
    {width: PLACEHOLDER_IMAGE_DIMENSIONS, height: Math.round(PLACEHOLDER_IMAGE_DIMENSIONS / initialAspectRatio)}
  );
  const [bgImg, setBgImg] = useState('');
  const [firstLoad, setFirstLoad] = useState(false);

  const BGImg = css`
    background-image: url("${bgImg}");
    opacity: 0;
    transition: .5s opacity ease-in-out;
  `;

  const Placeholder = css`
    background-image: url("${PlaceholderSrc}");
    filter: blur(${BLUR_INTENSITY}px);
    transition: 1s opacity ease-in-out;
    height: 100%;
    position: absolute !important;
  `;

  return (
    <>
      <CustomTag
        className={className}
        css={BGImg}
        {...firstLoad && {style: {...style, opacity: '1'}}}
        {...attributes}
      >
        <LazyLoader>
          <picture
            onLoad={(event) => {
              setBgImg(getLoadedImg(event.target))
              setFirstLoad(true);
            }}
            style={{display: 'none'}}
          >
            {buildSources(props.focusPoint, RES_PER_SCREEN, aspectRatio)}
            <img src={focusPoint.image} alt={alt}/>
          </picture >
        </LazyLoader>
      </CustomTag>
      <CustomTag
        className={className}
        css={Placeholder}
        {...firstLoad && {style: {...style, opacity: '0'}}}
        {...attributes}
      />
    </>
  )
}

/**
 * Retrieves the current breakpoint, checks if its mobile or desktop and passes the correct aspect ratio to FocuspointBg
 */
const FocusPointBgWithResponsiveRender = (props: IFocusPointBgProps) => {
  const { currentBp } = React.useContext(BreakpointContext);
  const { focusPoint, aspectRatio } = props;
  const finalAspectRatio = getCurrentAspectRatio(focusPoint, aspectRatio, currentBp);
  return FocusPointBg(props, finalAspectRatio);
}

export default FocusPointBgWithResponsiveRender;
