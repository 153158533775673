import { BreakpointContext } from "@ef-global/web-ui-react/lib/components/BreakpointContext";
import { css } from "@emotion/core";
import React, { useState } from "react";
import Loadable from "react-loadable";
import FocusPointBg from "~components/utils/focusPointBg";
import { IStoryBlokComponent, IFocusPoint } from "~interfaces/storyblock";
// @ts-ignore
import ReactModal from "react-modal";

const ReactTiktokEmbeded: any = Loadable({
  loader: async () => {
    const ReactTikTok = require("react-tiktok").TikTok;
    return ReactTikTok;
  },
  loading: () => <div />,
});

const ReactPlayer = Loadable({
  loader: () => import("react-player/lazy"),
  loading: () => <div />,
});

const VERTICAL_ASPECT_RATIO = 111 / 131;
const DEFAULT_ASPECT_RATIO = 16 / 9;

interface IVideoCardProps extends IStoryBlokComponent {
  image: IFocusPoint;
  video_link: any;
  video_link_tiktok: any;
  vertical: string[];
  title: string;
}

const VideoCard = (props: IVideoCardProps) => {
  if (!props.image) return <div />;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { currentBp } = React.useContext(BreakpointContext);

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const modalPlayerWrap = css`
    position: relative;
    padding-top: 56.25%;
  `;

  const modalPlayerTiktokWrap = css`
    position: relative;
    padding-top: 0px;
  `;

  const modalPlayer = css`
    position: absolute;
    top: 0;
    left: 0;
  `;

  const centerReactModal = css`
    margin: auto 0;
  `;

  const titleStyle = css`
    position: absolute;
    z-index: 1;
    width: 100%;
    max-width: 100%;
    text-align: center;
    bottom: 10%;
    color: white;
  `;

  const isVertical = (options: string[], currentBreakpoint: string) => {
    if (options.length > 0) {
      if (currentBreakpoint === "s" && options.find((item) => item === "s")) {
        return "-vertical";
      }
      if (currentBreakpoint !== "s" && options.find((item) => item === "m")) {
        return "-vertical";
      }
    }
    return "";
  };

  const renderPlayer: React.FC<IVideoCardProps> = (props) => {
    return props?.video_link_tiktok ? (
      <ReactTiktokEmbeded url={props.video_link_tiktok ?? ""} />
    ) : (
      <ReactPlayer
        url={props.video_link.cached_url}
        playing={true}
        css={modalPlayer}
        width="100%"
        height="100%"
      />
    );
  };

  return (
    <div>
      <div
        className={`ef-card--video ${isVertical(props.vertical, currentBp)}`}
        onClick={handleOpenModal}
      >
        <FocusPointBg
          focusPoint={props.image}
          className="ef-card--video__img"
          tabIndex={-1}
          aspectRatio={
            isVertical(props.vertical, currentBp)
              ? VERTICAL_ASPECT_RATIO
              : DEFAULT_ASPECT_RATIO
          }
        />
        <div className="ef-card--video__icon-w">
          <svg
            className="ef-icon ef-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            width="32"
            height="32"
          >
            <path
              d="M26.55 15.17l-18-12a1 1 0 00-1 0A1 1 0 007 4v24a1 1 0 00.53.88 1 1 0 001-.05l18-12a1 1 0 000-1.66z"
              fill="#191919"
            />
          </svg>
        </div>

        <p className="u-f-bold" css={titleStyle}>
          {props.title}
        </p>
      </div>

      <ReactModal
        className="ef-modal -l"
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        overlayClassName={{
          afterOpen: "ef-modal__overlay--after-open",
          base: "ef-modal__overlay -lightbox -inv",
          beforeClose: "ef-modal__overlay--before-close",
        }}
        htmlOpenClassName="ef-modal--active"
        ariaHideApp={false}
        closeTimeoutMS={150}
        css={centerReactModal}
      >
        <button className="ef-modal__dismiss" onClick={handleCloseModal} />
        <div
          css={
            props?.video_link_tiktok ? modalPlayerTiktokWrap : modalPlayerWrap
          }
        >
          {renderPlayer(props)}
        </div>
      </ReactModal>
    </div>
  );
};

// Export
export default VideoCard;
