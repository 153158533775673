import React, {useState} from 'react';
import {IGetStudentsPayload, IStudentsSearchProps} from './types';

// @ts-ignore
import {DEFAULT_VALUES} from './constants';
import {getResponsiveValue, getLocationSearch} from './library';

import Filters from './filters';
import Students from './students';
import './student.scss';
import {StudentSearchFormModal} from '~components/custom/students/student-search-form/student-search-form-modal';

const getFiltersFromQuerystring = () => {
  const {country, gender, age} = getLocationSearch();
  return {country, gender, age}
}

export default (
  {
    no_of_columns_mobile,
    no_of_students_per_page_mobile = DEFAULT_VALUES.noOfStudentsPerPageMobile,
    no_of_columns_tablet,
    no_of_students_per_page_tablet = DEFAULT_VALUES.noOfStudentsPerPageTablet,
    no_of_columns,
    no_of_students_per_page = DEFAULT_VALUES.noOfStudentsPerPage,
    min_age = DEFAULT_VALUES.minAge,
    max_age = DEFAULT_VALUES.maxAge,
    no_of_interests,
    choose_a_country_title = DEFAULT_VALUES.chooseACountryTitle,
    filter_title = DEFAULT_VALUES.filterTitle,
    form_id,
    disable_form_lock = false,
  }: IStudentsSearchProps) => {
  const qsValues = getFiltersFromQuerystring();

  const [noOfPage, setNoOfPage] = useState(0)
  const [country, setCountry] = useState(qsValues.country)
  const [gender, setGender] = useState(qsValues.gender)
  const [age, setAge] = useState(qsValues.age)
  const incrementPage = () => setNoOfPage(noOfPage + 1)
  const {getDirectStudents} = getLocationSearch()

  const noOfStudentsPerPage = getResponsiveValue(no_of_students_per_page_mobile, no_of_students_per_page_tablet, no_of_students_per_page);

  const filter: IGetStudentsPayload = {
    // @ts-ignore
    gender,
    // @ts-ignore
    country,
    // @ts-ignore
    age,
    page: noOfPage,
    size: noOfStudentsPerPage
  }

  const resetPage = () => setNoOfPage(0);

  const resetPageAndSetCountry = (value: string) => {
    resetPage();
    setCountry(value)
  }
  const resetPageAndSetGender = (value: string) => {
    resetPage();
    setGender(value)
  }
  const resetPageAndSetAge = (value: string) => {
    resetPage();
    setAge(value)
  }

  return (
    <>
      <StudentSearchFormModal
        setGender={resetPageAndSetGender}
        setAge={resetPageAndSetAge}
        setCountry={resetPageAndSetCountry}
        country={country}
        minAge={min_age}
        maxAge={max_age}
        form_id={form_id}
        disable_form_lock={disable_form_lock}
        query_disable_form={getDirectStudents}
      />
      <div className='students-search'>
        <Filters
          setGender={resetPageAndSetGender}
          setAge={resetPageAndSetAge}
          setCountry={resetPageAndSetCountry}
          minAge={min_age}
          maxAge={max_age}
          title={filter_title}
          countryTitle={choose_a_country_title}
          // @ts-ignore
          country={country}
        />

        <Students
          filter={filter}
          noOfColumnsMobile={no_of_columns_mobile}
          noOfColumnsTablet={no_of_columns_tablet}
          noOfColumns={no_of_columns}
          // @ts-ignore
          noOfInterests={no_of_interests}
          incrementPage={incrementPage}
        />
      </div>
    </>
  )
};
