import React from 'react';

export const SingleCountryButtonSkeleton = () => (
  <div
    className='flag ef-button -secondary -small flag'
    style={{
      width: '90px',
      height: '32px',
      padding: '0',
      transition: 'none',
    }}
  >
    <div className='pulse--darker' style={{borderRadius: '15px'}}>
      &nbsp;
    </div>
  </div>
)
