import React from 'react';

export const useLocalStorage = (defaultValue: any, key: string) => {
  const [value, setValue] = React.useState(() => {
    const valueToStore = typeof window !== 'undefined' && window.localStorage ? window.localStorage.getItem(key) : null
    return valueToStore !== null
      ? JSON.parse(valueToStore)
      : defaultValue;
  });
  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}
