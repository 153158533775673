import React, { useState } from 'react';
import Section from '~components/layout/section';
import { Button } from '@ef-global/web-ui-react';
import { shareLink } from '../infomeeting-modal/infomeeting-modal-helpers';
import { css } from '@emotion/core';

interface IInfomeetingFormSuccess {
  section: any;
  formShareButton: string;
}

const InfomeetingFormSuccess = (props: IInfomeetingFormSuccess) => {
  const [shared, setShared] = useState(false);

  const toggleShared = () => {
    setShared(true);
    setTimeout(() => setShared(false), 2000);
  }

  const buttonStyle = css`
    transition: color 0.5s ease-in-out;
    color: ${shared ? '#5DA335 !important' : ''};
  `;

  return (
    <Section {...props.section}>
      <Button
        secondary={true}
        onClick={() => shareLink(toggleShared)}
        css={buttonStyle}
      >
        {props.formShareButton}
      </Button>
    </Section>
  )
}

export default InfomeetingFormSuccess;
