import React from 'react';
import {getHref} from '../utils/helpers/generic-helpers';
import {IStoryBlokComponent} from '~interfaces/storyblock';
import {Facebook} from './facebook';
import {Whatsapp} from './whatsapp';
import {Email} from './email';
import {Line} from './line';
import {css} from '@emotion/core'

interface SocialShareProps extends IStoryBlokComponent {
  social_sites: string[]
  centered: boolean
}

const SocialShare = (props: SocialShareProps) => {
  const {social_sites = [], centered = false, story, ...rest} = props
  const url = getHref()
  const title = story?.content?.seo_data?.title ?? ''

  const socialStyles = css`
  text-align: ${!!centered ? 'center' : 'inherit'};
  & > button {
    margin-left: 5px;
    margin-right: 5px;
    &:first-child { margin-left: 0; }
    &:last-child { margin-right: 0; }
  }
`

  const SocialComponentMapping: { [key: string]: React.FunctionComponent<any> } = {
    facebook: Facebook,
    whatsapp: Whatsapp,
    email: Email,
    line: Line,
  };

  return (
    <div css={socialStyles}>
      {
        social_sites.map(social => {
          const SocialSiteComponent = SocialComponentMapping[social]
          return <SocialSiteComponent key={social} url={url} title={title} {...rest} />
        })
      }
    </div>

  );
};

export default SocialShare;
