import React  from 'react';

import { Surface } from '@ef-global/web-ui-react/lib/components/Surface';

import fill from 'lodash/fill'
import capitalize from 'lodash/capitalize'

import { getFlagImageUrl } from './library'
// @ts-ignore
import { DEFAULT_VALUES } from './constants'
import { ISingleStudentProps } from './types'
import IconArrowRightComponent from '@ef-global/web-ui-react/lib/icons/IconArrowRight';

export default ({
    noOfInterests = DEFAULT_VALUES.noOfInterests,
    student,
}: ISingleStudentProps) => {
    const {id, name, interests, age, gender, country, countryName} = student
    if (!id) {
        return <div />
    }

    const noOfInterestsInt = parseInt(String(Math.max(noOfInterests, 0)), 10);
    const computedInterests = [
        ...interests.filter(Boolean),
        ...new Array(noOfInterestsInt).fill('')
    ]
        .slice(0, noOfInterestsInt)
        .map((item, index) => (
            <li key={index}>
                <span hidden={item}>&nbsp;</span>
                <span hidden={!item}>- </span>
                {capitalize(item)}
            </li>
        ))

    return (
        <Surface shadow rounded className='student'>
            <div className='ef-row'>
                <div className='ef-col -s-3'>
                    <img className='student--flag' src={getFlagImageUrl(country)} />
                </div>
                <div className='ef-col -s-9'>
                    <strong>{name}<span hidden={!age}>, {age}</span></strong><br />
                    <small className='student--gender-and-country'>
                        {gender}
                        <span hidden={!countryName}> from</span>
                        <span className='student--country' hidden={!countryName}> {countryName}</span>
                    </small>
                </div>
            </div>
            <div className='ef-row student--interests'>
                <div className='ef-col -s-offset-3 -s-9'>
                    <ul>
                        {computedInterests.length >= noOfInterestsInt ? computedInterests : fill(computedInterests, computedInterests.length, noOfInterestsInt)}
                    </ul>
                </div>
            </div>
            <div className='ef-row student--footer'>
                <div className='ef-col -s-9 -s-offset-3' style={{display: 'flex', alignItems: 'center'}}>
                    <span>See profile</span>
                    <IconArrowRightComponent style={{display: 'inline-block', marginLeft: '5px'}} />
                </div>
            </div>
        </Surface>
    )
}
