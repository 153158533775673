import React from "react";
import SbEditable from "storyblok-react";
import ActionButton from "./custom/buttons/action-button";
import IconButton from "./custom/buttons/icon-button";
import Banner from "./custom/banner";
import Gallery from "./custom/Gallery";
import Icon from "./custom/icon";
import Image from "./custom/image";
import StudentsSearch from "./custom/students/students-search";
import StudentDetail from "./custom/students/student-detail";
import Column from "./layout/column";
import Row from "./layout/row";
import Section from "./layout/section";
import Surface from "./layout/surface";
import Page from "./pages/page";
import ComponentNotFound from "./utils/component_not_found";
import { isProduction } from "./utils/env-helpers";
import Accordion from "./web-ui-react/accordion/accordion";
import Button from "./web-ui-react/button";
import Carousel from "./web-ui-react/carousel";
import Card from "./web-ui/card";
import Form from "./web-ui/form/form";
import FormElements from "./web-ui/form/form-elements";
import FormSubmission from "./web-ui/form/form-submission";
import VideoCard from "./web-ui/video-card";
import Breadcrumbs from "~src/components/web-ui-react/breadcrumbs/breadcrumbs";
import Infomeeting from "./custom/infomeeting/infomeeting";
import FormsX from "~components/custom/formsx/formsx";
import {
  InlineVideo,
  TextBlock,
  Fullbleed,
  SocialShare,
  VideoPlayer,
} from "@hsey/components";
import IconList, { IconListItem } from "./custom/icon-list";
import VideoComponent from "~components/custom/video-component/video-component";
import ButtonGroup from "./web-ui-react/button-group";
import { Quiz, Question, Answer, QuizResult } from "~components/custom/quiz";
import Table from "~components/web-ui/table/table";
import Calendly from "~components/custom/calendly/calendly";
import StudentList from "~components/custom/student-list/StudentList";
import StickyCta from "~components/custom/sticky-cta/sticky-cta";
import ActionModal from "~/storyblok/components/actions/web-ui-action-modal";
import FaqsContainer from "~/src/components/custom/faqs/faqs-container";
import FaqsContainerItem from "~/src/components/custom/faqs/faqs-container-item";

const LayoutComponents: { [key: string]: React.FunctionComponent<any> } = {
  column: Column,
  page: Page,
  row: Row,
  section: Section,
  surface: Surface,
};

const Buttons: { [key: string]: React.FunctionComponent<any> } = {
  "email-button": ActionButton,
  "icon-button": IconButton,
  "phone-button": ActionButton,
};

const CustomComponents: { [key: string]: React.FunctionComponent<any> } = {
  "focuspoint-image": Image,
  "text-block": TextBlock,
  "students-search": StudentsSearch,
  "student-detail": StudentDetail,
  banner: Banner,
  "inline-video": InlineVideo,
  "video-player": VideoPlayer,
  "icon-list": IconList,
  "icon-list-item": IconListItem,
  "video-component": VideoComponent,
  "social-share": SocialShare,
  calendly: Calendly,
  "student-list": StudentList,
  "sticky-cta": StickyCta,
  "web-ui-action-modal": ActionModal,
  "faqs-container": FaqsContainer,
  "faqs-container-item": FaqsContainerItem,
};

const CardComponents: { [key: string]: React.FunctionComponent<any> } = {
  "advanced-teaser-card": Card,
  "content-card": Card,
  "custom-card": Card,
  "teaser-card": Card,
  "video-card": VideoCard,
  infomeeting: Infomeeting,
};

const FormComponents: { [key: string]: React.FunctionComponent<any> } = {
  form: Form,
  "form-input": FormElements,
  "form-submission": FormSubmission,
  formsx: FormsX,
};

const WebUiReactComponents: { [key: string]: React.FunctionComponent<any> } = {
  accordion: Accordion,
  breadcrumbs: Breadcrumbs,
  button: Button,
  carousel: Carousel,
  "button-group": ButtonGroup,
  fullbleed: Fullbleed,
  gallery: Gallery,
  icon: Icon,
  image: Image,
  table: Table,
  ...CardComponents,
};

const QuizComponents: { [key: string]: React.FunctionComponent<any> } = {
  quiz: Quiz,
  question: Question,
  answer: Answer,
  "quiz-result": QuizResult,
};

const allComponents: any = {
  ...CustomComponents,
  ...Buttons,
  ...FormComponents,
  ...LayoutComponents,
  ...WebUiReactComponents,
  ...QuizComponents,
};

const Components = (content: any, story?: any, settings?: any) => {
  if (typeof allComponents[content?.component] !== "undefined") {
    return (
      <SbEditable content={content} key={content._uid}>
        {React.createElement(allComponents[content.component], {
          ...content,
          settings,
          story,
        })}
      </SbEditable>
    );
  }

  return React.createElement(
    () => (isProduction() ? null : <ComponentNotFound {...content} />),
    { key: content?._uid }
  );
};

export default Components;
