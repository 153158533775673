import React from 'react';
import { IStoryBlokComponent } from '~src/interfaces/storyblock';
import { filterPath } from './breadcrumbs-helper';
import { Breadcrumb } from '@ef-global/web-ui-react/lib/components/Breadcrumb';

interface IBreadcrumbs extends IStoryBlokComponent {
  color: any;
}

const Breadcrumbs = (props: IBreadcrumbs) => {
  const { story, color = '#000000' } = props;
  const breadcrumbs = filterPath(story);

  return (
    <Breadcrumb style={{color: color.color}}>
    {breadcrumbs.map((breadcrumb, index) => {
      const path = breadcrumbs.slice(0, index + 1).join('/');
      const isLastElement = index === breadcrumbs.length - 1;
      return (
        <Breadcrumb.Item
          active={isLastElement}
          as='a'
          key={`${breadcrumb}_${index}`}
          link={!isLastElement ? `/${path}` : undefined}
        >
          {breadcrumb}
        </Breadcrumb.Item>
      )
    }
    )}
    </Breadcrumb >
  )
}

export default Breadcrumbs;
