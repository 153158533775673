import React from "react";
import { Helmet } from "react-helmet";
import { graphql, StaticQuery } from "gatsby";
import { ISeoMetatags } from "~src/interfaces/plugins";

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
  }
`;

interface ISEOProps {
  lang?: string;
  keywords?: string[]; // TODO:
  image?: string;
  seoData: ISeoMetatags;
  googleSiteVerification: string;
  noindex: boolean;
}

function SEO(props: ISEOProps) {
  const {
    image,
    seoData,
    keywords,
    lang = "en",
    googleSiteVerification,
    noindex = false,
  } = props;

  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const { siteMetadata: gatsbyConfigMetadata } = data.site;

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={seoData.title || gatsbyConfigMetadata.title}
            meta={[
              {
                content: gatsbyConfigMetadata.author,
                name: "author",
              },
              // Meta Description - Search Engines
              {
                content:
                  seoData.description || gatsbyConfigMetadata.description,
                name: "description",
              },
              // OG Description - Social Platforms
              {
                content: seoData.og_description,
                property: "og:description",
              },
              {
                content: seoData.og_title,
                property: "og:title",
              },
              {
                content: image,
                property: "og:image",
              },
              {
                content: "website",
                property: "og:type",
              },
              {
                content: seoData.twitter_title,
                name: "twitter:title",
              },
              {
                content: seoData.twitter_description,
                name: "twitter:description",
              },
              {
                content: googleSiteVerification,
                name: "google-site-verification",
              },
              {
                content: noindex ? "noindex" : null,
                name: "robots",
              },
              {
                name: "referrer",
                content: "no-referrer",
              },
              {
                name: "Referrer-Policy",
                content: "same-origin",
              },
              {
                name: "X-Frame-Options",
                content: "SAMEORIGIN",
              },
            ]
              .filter((e) => e.content)
              .concat(
                keywords && keywords.length > 0
                  ? {
                      content: keywords.join(", "),
                      name: "keywords",
                    }
                  : []
              )}
          />
        );
      }}
    />
  );
}

export default SEO;
