import React from 'react';
import { IStoryBlokComponent } from '~interfaces/storyblock';
import withSpacing from '@hsey/components/src/components/utils/withSpacingHOC';
import Components from '~components/components';

interface IIconListItemProps extends IStoryBlokComponent {
  content: Array<any>;
}

interface IIconListProps extends IStoryBlokComponent {
  items: Array<any>;
  boxModel: string;
}

export const IconList = (props: IIconListProps) => {
  return (
    <ul className={`hsey-icon-list ${props.boxModel && props.boxModel}`}>
      {props.items && props.items.map((blok) => Components(blok, props.story))}
    </ul>
  )
};

export const IconListItem = (props: IIconListItemProps) => {
  return (
    <li className='hsey-icon-list__item'>
      {props.content && props.content.map((blok) => Components(blok, props.story))}
    </li>
  )
};

export default withSpacing(IconList);
