import { FormWrapper } from '@ilc-technology/form-renderer/react';
import React, { useState, useReducer, useEffect } from 'react';
import StyledForm from './formsx.styles';
import { loader } from '../../web-ui/form/utils/form-styles';
import { ISubmissionState, formSubmissionReducer } from '~components/web-ui/form/state/form-state';
import Loadable from 'react-loadable';
import Components from '../../components';
import { FormStatus } from '~src/interfaces/forms';
import { getHref } from '~/src/helpers/generic-helpers';


const ReactModal = Loadable({
  loader: () => import(/* webpackChunkName: "ReactModal" */ 'react-modal'),
  loading: () => <></>
});

interface IInfomeetingFormProps {
  market: string;
  eventId?: string;
  visibleFields?: Array<string>;
  onSubmissionCompleted?: () => void;
  story?: any;
  form_type: string;
  form_success_screen?: any;
  form_error_screen?: any;
  form_edit_submission_screen?: 'SUCCESS' | 'ERROR';
}

// Currently FormsX only supports ids without chars, so a dummy id is provided when needed.
const ensureIdIsANumber = (id?: string) => {
  if (!id || isNaN(parseInt(id, 10))) return '1000';
  return id;
}

/**
 * Adds an event listener to the rendered form that sends a tracking event when the user first interacts with the form
 */
const addFormStartEventListeners = (form: HTMLFormElement) => {
  const addEvtLst = () => {
    dataLayer.push({ event: 'formx.started' });
    form.removeEventListener('change', addEvtLst);
  }

  form.addEventListener('change', addEvtLst)
}

const FormsX = (props: IInfomeetingFormProps) => {
  const {
    form_type,
    eventId,
    visibleFields,
    onSubmissionCompleted,
    form_success_screen,
    form_error_screen,
    form_edit_submission_screen
  } = props;
  const [loading, setLoading] = useState(true);

  const initialModalState: ISubmissionState = { modalIsOpen: false, status: FormStatus.NOT_SUBMITTED };
  const [modalState, modelStateDispatch] = useReducer(formSubmissionReducer, initialModalState);

  const market: string = props.market || props.story?.lang;
  let convertedMarket = ''

  const updatedInfomeetingId = ensureIdIsANumber(eventId);

  // Enables the preview screens during Storyblok editing
  useEffect(() => {
    if (getHref().includes('editor')) {
      switch (form_edit_submission_screen) {
        case 'SUCCESS':
          modelStateDispatch({type: 'OPEN_SUCCESS'});
          break;
        case 'ERROR':
          modelStateDispatch({type: 'OPEN_ERROR'});
          break;
        default:
          modelStateDispatch({type: 'CLOSE'});
          break;
      }
    }
  }, [form_edit_submission_screen])


  // Assign the formsX german language code for the Swiss market
  // tslint:disable-next-line:prefer-conditional-expression
  if (market?.toLowerCase() === 'chd') {
    convertedMarket = 'cs'
  } else {
    // Ensures the market always respects the 2 char standard for FormsX
    // Example: SalesForce Dutch market 'nla' becomes 'nl'
    convertedMarket = market?.substr(0, 2);
  }

  return (
    <>
      <StyledForm>
        {loading && <span style={{width: '150px', height: '150px'}} css={loader} />}
        <FormWrapper
          id={form_type}
          formWrapperId={form_type}
          marketCode={convertedMarket}
          formWrapperCSSClass={`ef-rhps-form`}
          // QA Endpoint
          // onPostSubmissionObjectReady={(form: any) => {
          //   form.formConfiguration.submissionEndpoint = `https://qa.ef.com/secureformsapi/Infomeeting/?mc=${convertedMarket}`;
          // }}
          onFormView={(form: any) => form.setInfomeetingId(updatedInfomeetingId)}
          plugins={{
            legacySettings: {
              productCode: 'HSY',
              programCode: 'HSY',
            }
          }}
          onConfigurationDownloaded={(form: any) => {
            // Remove unnecessary fields, ideally the form would not provide these for HSY
            if (form_type === 'brochure') {
              const fieldsToRemove = ['wantsToTravelSoonTitle', 'wantsToTravelSoonDescription', 'wantsToTravelSoon'];
              form.formConfiguration.fields = form.formConfiguration.fields
                .filter((e: any) => !fieldsToRemove.includes(e.name))
            }
            if (visibleFields) {
              form.formConfiguration.fields = form.formConfiguration.fields
                .filter((e: any) => visibleFields.includes(e.name))
            }
            // Remove unwanted extensions
            form.formConfiguration.scriptFiles = form.formConfiguration.scriptFiles
              .filter((e: any) => !e.pluginName.includes('google'))
          }}
          onFormRendered={(form) => {
            addFormStartEventListeners(form.htmlElement as HTMLFormElement);
            setLoading(false);
          }}
          onSubmissionCompleted={() => {
            dataLayer.push({ event: 'formx.submitted' });
            if (onSubmissionCompleted) {
              onSubmissionCompleted();
            } else {
              modelStateDispatch({type: 'OPEN_SUCCESS'});
            }
          }}
        />
      </StyledForm>

      <ReactModal
        className='ef-modal -l'
        isOpen={modalState.modalIsOpen}
        onRequestClose={() => modelStateDispatch({ type: 'CLOSE' })}
        overlayClassName={{
          afterOpen: 'ef-modal__overlay--after-open',
          base: 'ef-modal__overlay -lightbox',
          beforeClose: 'ef-modal__overlay--before-close',
        }}
        htmlOpenClassName='ef-modal--active'
        ariaHideApp={false}
        closeTimeoutMS={150}
      >
        <button className='ef-modal__dismiss' onClick={() => modelStateDispatch({ type: 'CLOSE' })} />

        {(modalState.modalIsOpen && modalState.status === 'SUCCESS')
          && form_success_screen?.map((blok: any) => Components(blok))}

        {(modalState.modalIsOpen && modalState.status === 'ERROR')
          && form_error_screen?.map((blok: any) => Components(blok))}

      </ReactModal>
    </>
  )
}

export default FormsX;
