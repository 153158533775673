import React from "react";
import { IStoryBlokComponent } from "~interfaces/storyblock";
import Loadable from "react-loadable";
import withSpacing from "@hsey/components/src/components/utils/withSpacingHOC";

export interface IIconProps extends IStoryBlokComponent {
  icon: any;
  icon_size: string;
  icon_color: any;
  icon_bg_color: any;
  boxModel: string;
}

/**
 * Displays an icon with a round background
 * Icon list can be seen here: https://code.ef.design/core/icons/
 */
const Icon = (props: IIconProps) => {
  const { icon, icon_size, icon_color, icon_bg_color, boxModel } = props;
  const IconComponent = Loadable({
    loader: () =>
      import(
        `@ef-global/web-ui-react/lib/icons/${icon?.selectedIcon?.componentName}`
      ),
    loading: () => <></>,
  });
  const bgClasses = `ef-icon__container ${boxModel && boxModel}`;
  return (
    <span
      className={bgClasses}
      style={{ backgroundColor: icon_bg_color?.color }}
    >
      <IconComponent
        className={icon_size}
        style={{ color: icon_color?.color }}
      />
    </span>
  );
};

export default withSpacing(Icon);
