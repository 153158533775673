import React from 'react'
import { IBoxModel } from '../../interfaces/plugins'

const classNames = (...items: any[]) => items.filter(a => a).join(' ')

const getSpacingClass = (prop: string, breakpoint: string, spacingType: string) => {
    switch (prop) {
        case 'xs':
            return `u-${breakpoint}-${spacingType}-${prop}`
        case 's':
            return `u-${breakpoint}-${spacingType}-${prop}`
        case 'm':
            return `u-${breakpoint}-${spacingType}-${prop}`
        case 'l':
            return `u-${breakpoint}-${spacingType}-${prop}`
        case 'xl':
            return `u-${breakpoint}-${spacingType}-${prop}`
        case 'xxl':
            return `u-${breakpoint}-${spacingType}-${prop}`
        case 'custom':
            return ''
        case 'none':
        default:
            return `u-${breakpoint}-${spacingType}-0`
    }
}

/*
    Documentation:
        To use WithSpacing HOC
        1) wrap component you like to have box model spacing,
        2) add spacing-plugin schema to component
        3) add props.spacing variable to component className prop

        example for Row component:
        1. export default WithSpacing(Row)
        2.
            box_model: {
                type: 'custom',
                field_type: 'spacing-plugin',
                options: []
            },
        3. <div className={`ef-row ${alignmentClass} ${props.spacing && props.spacing}`}>
*/
const WithSpacing = (WrappedComponent: any) => {
    return (props: { box_model: IBoxModel }) => {
        const box_model = props.box_model
        /* tslint:disable: variable-name */
        // getting spacing for xs / s / m / l / xl breakpoints
        const spacing_xs =
            box_model && box_model.xs
                ? Object.entries(box_model.xs)
                    .filter((item: any[]) => item[0] !== '_uid') // we don't need _uid of box_model field to determine what classes to apply
                    .map((item: any[]) =>
                        item[1] !== '-' ? getSpacingClass(item[1], 'xs', item[0]) : false
                    )
                : [false]

        const spacing_s =
            box_model && box_model.s
                ? Object.entries(box_model.s)
                    .filter((item: any[]) => item[0] !== '_uid') // we don't need _uid of box_model field to determine what classes to apply
                    .map((item: any[]) =>
                        item[1] !== '-' ? getSpacingClass(item[1], 's', item[0]) : false
                    )
                : [false]
        const spacing_m =
            box_model && box_model.m
                ? Object.entries(box_model.m)
                    .filter((item: any[]) => item[0] !== '_uid')
                    .map((item: any[]) =>
                        item[1] !== '-' ? getSpacingClass(item[1], 'm', item[0]) : false
                    )
                : [false]
        const spacing_l =
            box_model && box_model.l
                ? Object.entries(box_model.l)
                    .filter((item: any[]) => item[0] !== '_uid')
                    .map((item: any[]) =>
                        item[1] !== '-' ? getSpacingClass(item[1], 'l', item[0]) : false
                    )
                : [false]

        const spacing_xl =
            box_model && box_model.xl
                ? Object.entries(box_model.xl)
                    .filter((item: any[]) => item[0] !== '_uid') // we don't need _uid of box_model field to determine what classes to apply
                    .map((item: any[]) =>
                        item[1] !== '-' ? getSpacingClass(item[1], 'xl', item[0]) : false
                    )
                : [false]

        /* tslint:enable */
        const classesToApply = [...spacing_xs, ...spacing_s, ...spacing_m, ...spacing_l, ...spacing_xl]

        const spacing = classNames(...classesToApply)
        return <WrappedComponent {...props} boxModel={spacing} />
    }
}

export default WithSpacing
