import { useFormik } from "formik";
import React, { useEffect, useReducer, useState } from "react";
import Components from "~components/components";
import { IFormElement } from "~interfaces/components";
import { IStoryBlokComponent } from "~interfaces/storyblock";
import { fetchFields, submitForm } from "~services/formstack-service";
import { handleErrors } from "~services/service-helpers";
import FormElements from "./form-elements";
import {
  formSubmissionReducer,
  ISubmissionAction,
  ISubmissionState,
} from "./state/form-state";
import {
  convertDataForSubmission,
  createFormikInitialValues,
  fieldValidation,
  createFormPages,
} from "./utils/form-helper";
import { centerReactModal, dismissButton } from "./utils/form-styles";
import {
  renderSubmitButton,
  formStepsCounter,
} from "./utils/form-render-helpers";
import { getHref } from "~/src/helpers/generic-helpers";
import queryString from "query-string";
import { setCookie } from "~helpers/cookieHelper";
import Modal from "react-modal";

/**
 * Submits the form and shows a modal with success or error screen
 */
const onSubmit = async (
  values: Record<string, string>,
  formId: string,
  dispatch: React.Dispatch<ISubmissionAction>
) => {
  let formattedValues = convertDataForSubmission(values);
  const currQuery = queryString?.parse(location.search);
  const affId = currQuery?.amb || currQuery?.affId;
  formattedValues = Object.fromEntries(
    Object.entries(formattedValues).map(([key, value]) => {
      if (value === "affiliateId") {
        return [key, affId ?? "0"];
      }
      return [key, value];
    })
  );
  dispatch({ type: "SUBMITTING" });
  try {
    const response = await submitForm(formattedValues, formId);
    handleErrors(response);
    dispatch({ type: "OPEN_SUCCESS" });
    dataLayer.push({ event: "formSubmissionSuccess" });
  } catch (error) {
    dispatch({ type: "OPEN_ERROR" });
    // tslint:disable-next-line:no-console
    console.log("Error submitting the form", error);
  }
};

const onSubmitWithFormId = (formId: string) => (values: any) => (
  dispatch: React.Dispatch<ISubmissionAction>
) => onSubmit(values, formId, dispatch);

const validateWithFormfields = (formFields: Array<IFormElement>) => {
  return (values: Record<string, string>) =>
    fieldValidation(values, formFields);
};

interface IFormProps {
  terms_and_conditions?: string;
  submit_button_text?: string;
  spacing_between_elements?: string;
  enable_labels?: boolean;
  form_id?: string;
  form_auth?: string;
  submission_message_success: Array<IStoryBlokComponent>;
  submission_message_error: Array<IStoryBlokComponent>;
  edit_submission?: "success" | "error";
}

const Form = (props: IFormProps) => {
  const {
    submit_button_text,
    spacing_between_elements,
    enable_labels,
    form_id = "",
    submission_message_success,
    submission_message_error,
    edit_submission,
  } = props;
  const [formFields, setFormFields] = useState<Array<IFormElement>>([]);
  const [formikInitialValues, setFormikInitialValues] = useState<
    Record<string, string>
  >({});

  const initialModalState: ISubmissionState = {
    modalIsOpen: false,
    status: "NOT_SUBMITTED",
  };
  const [modalState, modelStateDispatch] = useReducer(
    formSubmissionReducer,
    initialModalState
  );

  const [formPage, setFormPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const renderField = (field: IFormElement) => {
    if (field.type === "section") {
      return <h4 key={field.id}>{field.section_heading}</h4>;
    }
    return (
      <FormElements
        formik={formik}
        key={field.id}
        className={spacing_between_elements}
        enableLabels={enable_labels}
        {...field}
      />
    );
  };

  const renderPage: any = (
    fields: Array<IFormElement | Array<IFormElement>>,
    currentPage: number
  ) => {
    if (Array.isArray(fields[currentPage])) {
      return renderPage(fields[currentPage]);
    }

    return fields.map((field) => renderField(field as IFormElement));
  };

  useEffect(() => {
    let clientId = "";
    let checkIfAnalyticsLoaded = () => {
      return new Promise((resolve, reject) => {
        let timeStart = Date.now();
        const TIMEOUT = 12000;

        let _isLoaded = function () {
          if (Date.now() - timeStart > TIMEOUT) {
            reject("Timeout. Google analytics not injected!");
            return;
          }
          if (window.ga && ga.create) {
            resolve(ga);
            return;
          } else {
            setTimeout(_isLoaded, 500);
          }
        };

        _isLoaded();
      });
    };

    checkIfAnalyticsLoaded()
      .then((ga: any) => {
        // save analytics ID to retrieve in formstack (not pretty but was optional chaining)
        try {
          clientId =
            typeof window !== "undefined"
              ? (window &&
                  ga &&
                  ga.getAll()[0] &&
                  ga.getAll()[0].get("clientId")) ??
                ""
              : "";
          setCookie("ga_clean", clientId, 1);
        } catch (e) {
          console.error(e);
        }
      })
      .catch(console.error);

    async function fetchFormFields() {
      try {
        const response = await fetchFields(form_id);
        // handleErrors(response);
        let fieldsData = response as Array<IFormElement>;

        fieldsData = createFormPages(fieldsData);
        setFormFields(fieldsData);
        setNumberOfPages(
          fieldsData.reduce(
            (acc, currValue) => (Array.isArray(currValue) ? acc + 1 : acc),
            0
          )
        );
        // Create the initial value object for formik
        const initialFormObject = createFormikInitialValues(fieldsData);
        setFormikInitialValues(initialFormObject);
      } catch (error) {
        // tslint:disable-next-line:no-console
        console.log(error);
      }
    }

    fetchFormFields();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    onSubmit: (values, bag) => {
      if (numberOfPages > 0 && numberOfPages !== formPage + 1) {
        bag.setTouched({});
        bag.setSubmitting(false);
        setFormPage(formPage + 1);
      } else {
        onSubmitWithFormId(form_id)(values)(modelStateDispatch);
      }
    },
    validate:
      numberOfPages > 0
        ? validateWithFormfields(
            (formFields[formPage] as never) as Array<IFormElement>
          )
        : validateWithFormfields(formFields),
    // validateOnBlur: true,
    // validateOnChange: false,
  });

  /**
   * Toggles the form submission modal
   *
   * Enables the modal if it is being edited during development
   */
  const toggleModal = () => {
    if (
      edit_submission === "success" &&
      getHref().includes("editor") &&
      !modalState.modalIsOpen
    ) {
      modelStateDispatch({ type: "OPEN_SUCCESS" });
    } else if (
      edit_submission === "error" &&
      getHref().includes("editor") &&
      !modalState.modalIsOpen
    ) {
      modelStateDispatch({ type: "OPEN_ERROR" });
    }
  };

  toggleModal();

  return (
    <>
      <form className="ef-form" onSubmit={formik.handleSubmit}>
        {/* Steps Counter */}
        {formStepsCounter(numberOfPages, formPage, setFormPage)}
        {Object.keys(formik.values).length
          ? renderPage(formFields, formPage)
          : null}
        <div className="ef-row">
          <div className="ef-col -s-12 form-style-center">
            {renderSubmitButton(
              numberOfPages,
              formPage,
              modalState,
              submit_button_text
            )}
          </div>
        </div>
      </form>

      <Modal
        className="ef-modal -l"
        isOpen={modalState.modalIsOpen}
        onRequestClose={() => modelStateDispatch({ type: "CLOSE" })}
        overlayClassName={{
          afterOpen: "ef-modal__overlay--after-open",
          base: "ef-modal__overlay -lightbox -inv",
          beforeClose: "ef-modal__overlay--before-close",
        }}
        htmlOpenClassName="ef-modal--active"
        ariaHideApp={false}
        closeTimeoutMS={150}
        css={centerReactModal}
      >
        <button
          css={dismissButton}
          onClick={() => modelStateDispatch({ type: "CLOSE" })}
        >
          Close
          <span className={"ef-icon -close -white"} />
        </button>

        {modalState.modalIsOpen &&
          modalState.status === "SUCCESS" &&
          submission_message_success?.map((blok) => Components(blok))}

        {modalState.modalIsOpen &&
          modalState.status === "ERROR" &&
          submission_message_error?.map((blok) => Components(blok))}
      </Modal>
    </>
  );
};

export default Form;
