
export default [
    {
      "character": "🎶",
      "name": "Concerts",
      "uriName": "Concerts",
      "id": 29,
      "categories": [
        {
          "filterable": true,
          "character": "😛",
          "name": "friends and social",
          "uriName": "friends and social",
          "id": 4,
          "value": "Friends and social"
        }
      ],
      "normalizedName": "Concerts",
      "value": "Concerts",
      "characterCode": "U+1F3B6",
      "slug": "concerts"
    },
    {
      "character": "⚜️",
      "name": "Scouting",
      "uriName": "Scouting",
      "id": 70,
      "categories": [
        {
          "filterable": true,
          "character": "⛰",
          "name": "outdoors",
          "uriName": "outdoors",
          "id": 6,
          "value": "Outdoors"
        },
        {
          "filterable": true,
          "character": "🏃",
          "name": "sports and fitness",
          "uriName": "sports and fitness",
          "id": 8,
          "value": "Sports and fitness"
        }
      ],
      "normalizedName": "Scouting",
      "value": "Scouting",
      "characterCode": "U+269C",
      "slug": "scouting"
    },
    {
      "character": "🎵",
      "name": "Listening to Music",
      "uriName": "Listening to Music",
      "id": 118,
      "categories": [
        {
          "filterable": true,
          "character": "🎶",
          "name": "music and instruments",
          "uriName": "music and instruments",
          "id": 5,
          "value": "Music and instruments"
        }
      ],
      "normalizedName": "Music",
      "value": "Listening to Music",
      "characterCode": "U+1F3B5",
      "slug": "music"
    },
    {
      "character": "🍕",
      "name": "Cooking",
      "uriName": "Cooking",
      "id": 122,
      "categories": [
        {
          "filterable": true,
          "character": "🍕",
          "name": "food and cooking",
          "uriName": "food and cooking",
          "id": 3,
          "value": "Food and cooking"
        }
      ],
      "normalizedName": "Cooking",
      "value": "Cooking",
      "characterCode": "U+1F355",
      "slug": "cooking"
    },
    {
      "character": "🎭",
      "name": "Acting",
      "uriName": "Acting",
      "id": 138,
      "categories": [
        {
          "filterable": true,
          "character": "🎨",
          "name": "creative and artistic",
          "uriName": "creative and artistic",
          "id": 2,
          "value": "Creative and artistic"
        }
      ],
      "normalizedName": "Acting",
      "value": "Acting",
      "characterCode": "U+1F3AD",
      "slug": "acting"
    },
    {
      "character": "⛰️",
      "name": "Nature",
      "uriName": "Nature",
      "id": 140,
      "categories": [
        {
          "filterable": true,
          "character": "⛰",
          "name": "outdoors",
          "uriName": "outdoors",
          "id": 6,
          "value": "Outdoors"
        }
      ],
      "normalizedName": "Nature",
      "value": "Nature",
      "characterCode": "U+26F0, U+FE0F",
      "slug": "nature"
    }
  ];