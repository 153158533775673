declare const navigator: any;

export const shareLink = (callback: () => void) => {
  // For browsers that support the share API: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share
  if ('share' in navigator) {
    const shareData = {
      url: window.location.href,
    }
    navigator.share(shareData)
  } else {
    // Otherwise we copy the url to the clipboard
    const dummy = document.createElement('input');
    const currentUrl = window.location.href;
    document.body.appendChild(dummy);
    dummy.value = currentUrl;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    callback();
  }

}
