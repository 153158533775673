import React from 'react'
import {css} from '@emotion/core'

export const ProgressBars = (props: { currentStep: number, totalSteps: number }) => {
  const {currentStep = 1, totalSteps = 1} = props
  const BarStyle = css`
    margin-bottom: 40px;
    &:first-child {
      padding-right: 0;
    }
    &:last-child {
      padding-left: 0;
    }
    .progress-bar {
      background: #EFEFEF;
      height: 5px;
      border-radius: 100px;

      &.active {
        background: #046EA7;
      }
    }
  `

  return (
    <div className='ef-row'>
      {[...Array(totalSteps)].map((e, i) => {
        return (
          <div
            key={i}
            className='ef-col'
            css={BarStyle}
          >
            <div
              className={`progress-bar ${currentStep >= (i + 1) ? 'active' : ''}`}
            />
          </div>
        )
      })}
    </div>
  )
}
