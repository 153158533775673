import styled from "@emotion/styled";
import React, { useState } from "react";
import { IStoryBlokComponent, IStoryblokLink } from "~interfaces/storyblock";
import Link from "~components/utils/link";
import { css } from "@emotion/core";
import { createSpacingClass } from "~components/utils/spacing";
import Components from "~components/components";
import { scroll } from "~components/utils/scroll";

interface IIconButtonProps extends IStoryBlokComponent {
  image: string;
  text: string;
  link: IStoryblokLink;
  scroll_to?: string;
  text_size: string;
  text_color?: any;
  icon_width?: string;
  icon_height?: string;
  open_in_a_new_tab: boolean;
  text_spacing: any;
  action?: any;
  story?: any;
}

const IconButton = (props: IIconButtonProps) => {
  const {
    action,
    text = null,
    image,
    link,
    scroll_to = "",
    icon_width = "25",
    icon_height = "20",
    text_size,
    text_color,
    open_in_a_new_tab,
    text_spacing,
    story,
  } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const textSpacingClass = createSpacingClass(text_spacing);
  const Alignment = css`
    display: flex;
    align-items: center;
    cursor: ${link?.url || link?.cached_url || scroll_to
      ? `pointer`
      : `default`};
    width: fit-content;
  `;

  const P = styled.p`
    margin: 0 0 0 7px;
    font-size: ${text_size}px;
    color: ${text_color ? text_color?.color : "#000000"};
  `;

  const renderContent = () => {
    return (
      <>
        {image && (
          <img
            alt={``}
            src={image}
            style={{
              width: `${parseInt(icon_width, 10)}px`,
              height: `${parseInt(icon_height, 10)}px`,
            }}
          />
        )}
        {!!text && <P>{text}</P>}
      </>
    );
  };

  return (link?.url || link?.cached_url) && action?.length == 0 ? (
    <Link
      storyblokLink={link}
      className={`ef-button-pointer-events-none ${textSpacingClass}`}
      css={Alignment}
      openInANewTab={open_in_a_new_tab}
    >
      {renderContent()}
    </Link>
  ) : (
    <>
      {action && action[0]
        ? Components({ ...action[0], open, handleOpen, handleClose }, story)
        : null}
      <div
        className={textSpacingClass}
        css={Alignment}
        onClick={() => {
          if (scroll_to) {
            scroll(scroll_to);
          } else {
            handleOpen();
          }
        }}
      >
        {renderContent()}
      </div>
    </>
  );
};

export default IconButton;
