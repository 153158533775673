import React from 'react';
import Components from '~components/components';
import { IStoryBlokComponent } from '~interfaces/storyblock';

interface IRowProps extends IStoryBlokComponent {
  columns: Array<any>;
  horizontal_alignment: string;
  vertical_alignment: string;
}

const createAlignmentClass = (horizontalAlignment: string, verticalAlignment: string) => {
  return  (
    `${horizontalAlignment ? horizontalAlignment + ' ' : ''}`
    + `${verticalAlignment ? verticalAlignment : ''}`
  );
};

/**
 * Row from the grid system seen here: https://code.ef.design/layout/grid
 */
const Row = (props: IRowProps) => {
  const { columns, horizontal_alignment, vertical_alignment, story } = props;

  const alignmentClass = createAlignmentClass(horizontal_alignment, vertical_alignment);

  return (
    <div
      className={`ef-row full-height ${alignmentClass}`}
    >
      {columns && columns.map((blok) => Components(blok, story))}
    </div>
  );
};

export default Row;
