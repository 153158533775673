import React from 'react';
import {Button, ButtonGroup} from '@ef-global/web-ui-react/lib/components/Button';
import IconArrowLeftComponent from '@ef-global/web-ui-react/lib/icons/IconArrowLeft';

export default ({studentName, handleOpenModal}: { studentName: string, handleOpenModal: any }) => (
  <div className='ef-row -x-around'>
    <div className='ef-col -s-12 -m-4'>
      <div className='student--header'>
        <div className='student--header--back'>
          <div className='u-show-m-up'>
            <a onClick={() => history.back()}>
              <IconArrowLeftComponent
                className='ef-icon -arrow-left'
                style={{
                  display: 'inline',
                }}
              />
              <span>Back</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className='ef-col -s-12 -m-8 student__detail--buttons student__detail--buttons-desktop u-show-m-up'>
      <ButtonGroup>
        <Button className='full-width-s' primary onClick={handleOpenModal}>
          Learn more about {studentName}
        </Button>
      </ButtonGroup>
    </div>
  </div>
)
