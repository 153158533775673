import { ButtonGroup as WebUIButtonGroup } from "@ef-global/web-ui-react/lib/components/Button";
import { ButtonProps as IButtonProps } from "@ef-global/web-ui-react/lib/components/button";
import css from "@emotion/css";
import React, { useState, useLayoutEffect, useRef } from "react";
import Components from "../components";

interface IButtonGroupProps {
  buttons: Array<IButtonProps>;
  button_direction: "row" | "row-reverse" | "column" | "column-reverse";
  button_match_width: boolean;
  button_full_width: boolean;
  alignment: any;
  justify: any;
  story: any;
}

const ButtonGroup = ({
  buttons,
  button_direction,
  button_match_width,
  button_full_width,
  alignment,
  justify,
  story,
}: IButtonGroupProps) => {
  const wrapperRef: any = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(null);
  const autoAdjustWidth =
    button_match_width && buttonWidth && !button_full_width;

  // If option selected on mount
  // get widdest element and apply to other
  // button elements.
  useLayoutEffect(() => {
    if (button_match_width && !button_full_width) {
      let buttons = wrapperRef.current.querySelectorAll("button");
      let width: any = 0;
      buttons.forEach((button: any) => {
        if (button.offsetWidth > width) {
          width = button.offsetWidth;
        }
      });
      setButtonWidth(width);
    } else {
      // reset default when not using auto width adjustment
      setButtonWidth(null);
    }
  }, [button_match_width]);

  let buttonGroupWrapper = css`
    .ef-button-group {
      ${alignment && button_direction == "column"
        ? `align-items: ${alignment};`
        : ""}
      ${justify && button_direction == "row"
        ? `justify-content:${justify};`
        : ""}
      ${button_direction
        ? `
        display: flex;
        flex-direction: ${button_direction};
        > button {
         ${
           autoAdjustWidth
             ? "width: " + (buttonWidth + 8) + "px !important;"
             : ""
         }
         ${button_full_width ? "width: 100% !important;" : ""}
        }
      `
        : ""}
    }
  `;
  return (
    <div css={buttonGroupWrapper} ref={wrapperRef}>
      <WebUIButtonGroup className="-x-end">
        {buttons && buttons.map((blok) => Components(blok, story))}
      </WebUIButtonGroup>
    </div>
  );
};

export default ButtonGroup;
