import { IAlignmentItem } from '~/src/interfaces/components';

export const buildAlignmentClass = (alignmentItem: IAlignmentItem) => {
  const targetDevice = alignmentItem.target_device ? alignmentItem.target_device : '';
  let alignmentClass = '';

  if (alignmentItem.behaviour) {
    alignmentClass += `${targetDevice}${alignmentItem.behaviour} `;
  }

  if (alignmentItem.horizontal_alignment) {
    alignmentClass += `${targetDevice}${alignmentItem.horizontal_alignment} `;
  }

  if (alignmentItem.offset) {
    alignmentClass += `${targetDevice}-offset${alignmentItem.offset} `;
  }

  if (alignmentItem.reorder) {
    alignmentClass += `${targetDevice}${alignmentItem.reorder} `;
  }

  if (alignmentItem.size) {
    alignmentClass += `${targetDevice}${alignmentItem.size} `;
  }

  if (alignmentItem.vertical_alignment) {
    alignmentClass += `${targetDevice}${alignmentItem.vertical_alignment} `;
  }

  return alignmentClass;
};
