export const IMAGE_MAX_SIZE = {
  height: 1600,
  width: 1600,
};

export const STORYBLOK_IMAGE_SIZE_LIMIT = 4000;
export const STORYBLOK_IMAGE_SERVICE_BASE = "//img2.storyblok.com/";
export const QUALITY_DEFAULT = 75;
export const RES_PER_SCREEN = [0, 480, 768, 1024, 1264, 1824];
export const PLACEHOLDER_IMAGE_DIMENSIONS = 45;
export const BLUR_INTENSITY = 10;
export const DESKTOP_BREAKPOINT = 768;
