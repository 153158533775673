import React from "react";
import Loadable from "react-loadable";

const InlineVideo = (props: any) => {
  const ReactPlayer = Loadable({
    loader: () => import("react-player/lazy"),
    loading: () => <div />,
  });
  const autoplay = !!props.autoplay;
  const loop = !!props.loop;

  const url = (props.file && props.file.filename && props.file.filename) || "";

  return (
    <ReactPlayer
      // @ts-ignore
      url={url || ""}
      width="100%"
      height="100%"
      playing={autoplay}
      controls={false}
      muted={autoplay}
      loop={loop}
      playsinline
    />
  );
};

export default InlineVideo;
