import React, { useState, useCallback, useEffect } from 'react';
import { css } from '@emotion/core';

import queryString from 'query-string'

import {IGetStudentsPayload, IStudent, IStudentsSearchProps} from './types';

import { DEFAULT_VALUES, getBackgroundImagePath } from './constants';
import { getResponsiveValue, getStudent, getCountry } from './library';

import Students from './students';
import SingleStudentProfile from './single-student-profile';

import './student.scss';

const getStudentId = () => {
    try {
        return queryString.parse(location.search);
    } catch (error) {
        return {};
    }
}

export default ({
    personalLetterVisibleCharacters = DEFAULT_VALUES.personalLetterVisibleCharacters,
    no_of_columns_mobile = DEFAULT_VALUES.noOfColumnsMobile,
    no_of_students_per_page_mobile = DEFAULT_VALUES.noOfStudentsPerPageMobile,
    no_of_columns_tablet = DEFAULT_VALUES.noOfColumnsTablet,
    no_of_students_per_page_tablet = DEFAULT_VALUES.noOfStudentsPerPageTablet,
    no_of_columns = DEFAULT_VALUES.noOfColumns,
    no_of_students_per_page = DEFAULT_VALUES.noOfStudentsPerPage,
    noOfInterests = DEFAULT_VALUES.noOfInterests,
    form_id,
    submission_message_success,
    submission_message_error,
}: IStudentsSearchProps) => {
    const [noOfPage, setNoOfPage] = useState(0)
    const [student, setStudent] = useState<IStudent>()
    const [countryContent, setCountryContent] = useState('')
    const { studentid } = getStudentId();
    const incrementPage = () => setNoOfPage(noOfPage + 1)

    const getStudentDetailCallback = useCallback(() => {
        return getStudent(studentid).then((response) => {
            // @ts-ignore
            setStudent(response || {});
            getCountryCallback(response.country)
        })
    }, [studentid]);

    const getCountryCallback = useCallback((countryCode) => {
        return getCountry(countryCode).then(({ description }) => setCountryContent(description))
    }, [studentid]);

    useEffect(() => {
        getStudentDetailCallback()
    }, [studentid]);


    const centeredColumnClass = 'ef-col'

    const backgroundImage = getBackgroundImagePath(student?.country);

    const containerStyle = css`
        background-image: url('${backgroundImage}');
    `;

    const filter: IGetStudentsPayload = {
        gender: undefined,
        country: student?.country,
        age: undefined,
        page: noOfPage,
        size: getResponsiveValue(no_of_students_per_page_mobile, no_of_students_per_page_tablet, no_of_students_per_page),
        idToExclude: student?.id
    }

    return (
        <div>
            <div css={containerStyle} className='student-details-background' />
            <div className='students-details'>
                <div className={centeredColumnClass}>
                    <SingleStudentProfile
                        noOfInterests={noOfInterests}
                        personalLetterVisibleCharacters={personalLetterVisibleCharacters}
                        // @ts-ignore
                        student={student}
                        formId={form_id}
                        submissionMessageSuccess={submission_message_success}
                        submissionMessageError={submission_message_error}
                    />
                </div>
                {student?.country && (
                    <div className='country-content'>
                        <h5 className='student__detail--title'>About {student?.countryName}
                        </h5>
                        <p className='student__detail--gender-and-country'>
                            {countryContent}
                        </p>
                    </div>
                )}
            </div>

            {student?.country && (
                <div>
                    <h5 className='more-students-title'>More students from {student?.countryName}</h5>
                    <Students
                        filter={filter}
                        noOfColumnsMobile={no_of_columns_mobile}
                        noOfColumnsTablet={no_of_columns_tablet}
                        noOfColumns={no_of_columns}
                        noOfInterests={noOfInterests}
                        incrementPage={incrementPage}
                    />
                </div>
            )}
        </div>
    )
};
