import styled from '@emotion/styled';
import React from 'react';
import { IStoryBlokComponent } from '~interfaces/storyblock';

interface IEmailButtonProps extends IStoryBlokComponent {
  email: string;
  white?: boolean;
}

const Button = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Span = styled.span`
  margin-right: .5em;
  font-size: 24px;
`;

const emailButton = (props: IEmailButtonProps) => {
  const { white, email } = props;

  return (
    <Button>
      <Span className={`ef-icon -email ${white ? '-white' : ''}`}/>
      <a href={`mailto:${email}`}>
        {email}
      </a>
    </Button>
  );
};

interface IPhoneButtonProps extends IStoryBlokComponent {
  phone_number: string;
  white?: boolean;
}

const phoneButton = (props: IPhoneButtonProps) => {
  const { white, phone_number } = props;

  return (
    <Button>
      <Span className={`ef-icon -phone ${white ? '-white' : ''}`}/>
      <a href={phone_number}>
        {phone_number}
      </a>
    </Button>
  );
};

const ActionButton = (props: IEmailButtonProps | IPhoneButtonProps) => {
  switch (props.component) {
    case 'email-button':
      return emailButton(props as IEmailButtonProps);
      case 'phone-button':
        return phoneButton(props as IPhoneButtonProps);
    default:
      // tslint:disable-next-line:no-console
      console.log('This button is not implemented');
      return <div />;
  }
};

export default ActionButton;
