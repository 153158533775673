import React, { useReducer, useEffect, useState } from 'react';
import { IMarketData } from './interfaces';
import { getAllInfomeetingsForMarket } from './services/infomeeting-service';
import { IStoryBlokComponent } from '~src/interfaces/storyblock';
import InfomeetingCards, { IInfomeetingCards } from './components/infomeeting-cards/infomeeting-cards';
import InfomeetingList, { IInfomeetingList } from './components/infomeeting-list/infomeeting-list';
import styles from './infomeeting.module.scss';
import { loader } from '~src/components/web-ui/form/utils/form-styles';
import InfomeetingModal, { IInfomeetingModalProps } from './components/infomeeting-modal/infomeeting-modal';
import { useLocation, navigate } from '@reach/router';
import queryString from 'query-string';
import InfomeetingError from './components/infomeeting-error/infomeeting-error';

type StatusType = 'NOT_SET' | 'FETCHING' | 'SUCCESS' | 'ERROR';
const NUMBER_OF_WEEKS_FROM_NOW = 0;
const INFOMEETINGID_QUERY_PARAM = 'infomeetingId';

interface IInfomeetingState {
  data: Array<IMarketData>;
  status: StatusType;
}

interface IInfomeetingAction {
  type: 'FETCHING' | 'SUCCESS' | 'ERROR';
  payload?: Array<IMarketData>;
}

const infomeetingReducer = (state: IInfomeetingState, action: IInfomeetingAction): IInfomeetingState => {
  switch (action.type) {
    case 'FETCHING':
      return { data: state.data, status: 'FETCHING' };
    case 'SUCCESS':
      return { data: action.payload as Array<IMarketData>, status: 'SUCCESS' };
    case 'ERROR':
      return { data: [], status: 'ERROR' };
    default:
      return state;
  }
}

const renderLoader = () => {
  return (
    <div className={styles.infomeetingLoaderContainer} role='alert' aria-live='assertive'>
      <span style={{width: '150px', height: '150px'}} className={styles.infomeetingCardsLoader} css={loader}/>
    </div>
  )
}

interface IInfomeetingProps extends IStoryBlokComponent, IInfomeetingCards, IInfomeetingList, IInfomeetingModalProps {
  no_data_message: string;
}

/**
 * Depending on the current status renders:
 * - NOT_SET/FETCHING -> A loader
 * - SUCCESS -> Infomeeting cards and list
 * - ERROR -> Infomeeting error component
 */
const renderInfomeeting = (
  state: IInfomeetingState,
  props: IInfomeetingProps,
  openModal: (index: number) => void
) => {
  const infomeetingCardsData: Array<IMarketData> = [];
  const infomeetingListData: Array<IMarketData> = [];

  // For the cards component we only show the infomeetings for this week
  // For the list component we only show the infomeetings for the next weeks
  state.data.forEach(e => {
    e.differenceInWeeks <= NUMBER_OF_WEEKS_FROM_NOW
      ? infomeetingCardsData.push(e)
      : infomeetingListData.push(e);
  });

  switch (state.status) {
    case 'SUCCESS':
      return (
        <>
          <InfomeetingCards
            infomeetings={infomeetingCardsData}
            openModal={openModal}
            {...props}
          />
          {infomeetingListData?.length > 0 && (
            <InfomeetingList
              infomeetings={infomeetingListData}
              openModal={openModal}
              {...props}
            />
          )}
        </>
      )
    case 'ERROR':
      return <InfomeetingError errorMessage={props.no_data_message}/>
    default: // NOT_SET and FETCHING
      return renderLoader();
  }
}

const getInfomeetingIndex = (params: any, data: Array<IMarketData>) => {
  const parsedQueryParams = queryString.parse(params);
  // If the link is being shared with a specific infomeeting
  if (INFOMEETINGID_QUERY_PARAM in parsedQueryParams) {
    return data.findIndex(e => String(e.id) === parsedQueryParams[INFOMEETINGID_QUERY_PARAM]);
  }
  return -1;
}

const Infomeeting = (props: IInfomeetingProps) => {
  const initialState: IInfomeetingState = { data: [], status: 'NOT_SET' };
  const [infomeetingState, infomeetingDispatch] = useReducer(infomeetingReducer, initialState);
  const [modalState, setModalState] = useState({index: -1, isOpen: false});
  const [queryParams] = useState(useLocation().search)
  const [market] = useState(props.story?.lang)

  useEffect(() => {
    async function fetchInfomeetings() {
      infomeetingDispatch({ type: 'FETCHING' });
      try {
        const payload = await getAllInfomeetingsForMarket(market);
        infomeetingDispatch({ type: 'SUCCESS', payload });
        const infomeetingIdx = getInfomeetingIndex(queryParams, payload);
        if (infomeetingIdx !== -1) {
          setModalState({index: infomeetingIdx, isOpen: true})
        }
      } catch (error) {
        infomeetingDispatch({ type: 'ERROR' });
        // tslint:disable-next-line:no-console
        console.log('Error Fetching Infomeeting Data', error)
      }
    }

    fetchInfomeetings();
  }, []);

  const toggleModal = (index?: number) => {
    setModalState({
      index: index !== undefined ? index : modalState.index,
      isOpen: !modalState.isOpen,
    })

    if (!modalState.isOpen && index !== undefined) {
      const id = infomeetingState.data[index].id;
      navigate(queryParams ? `${queryParams}&${INFOMEETINGID_QUERY_PARAM}=${id}` : `?${INFOMEETINGID_QUERY_PARAM}=${id}`);
    } else {
      navigate(queryParams);
    }
  }

  return (
    <section>
      {renderInfomeeting(infomeetingState, props, toggleModal)}
      <InfomeetingModal
        isOpen={modalState.isOpen}
        toggleModal={toggleModal}
        infomeeting={infomeetingState.data[modalState.index]}
        market={market}
        {...props}
      />
    </section>
  );
}

export default Infomeeting;

// TODO

// - Date translation
// - Solve interface importing
