import { css } from '@emotion/core';

const loader = css`
  height: 100%;
  width: 100%;
  color: #5a5a5a;
  position: relative;
  display: inline-block;
  border: 5px solid;
  border-radius: 50%;
  border-top-color: transparent;
  animation: rotate 1s linear infinite;

  @keyframes rotate {
    0% { transform: rotate(0); }
    100% { transform: rotate(360deg); }
  }
`;

const centerReactModal = css`
  margin: auto 0;
`;

// Override the current web-ui positioning for the dismiss button
const dismissButton = css`
  &&& {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;

    .ef-icon {
      margin-left: 1em;
    }
  }
`;

export {
  centerReactModal,
  dismissButton,
  loader,
};
