import React from 'react';
import ReactMarkdown from 'react-markdown';
import {filterRelativeLinks, addTrailingSlash} from '~/src/helpers/generic-helpers';

const Markdown = (props: ReactMarkdown.ReactMarkdownProps) => {
  const isLinkRelative = (link: string) => {
    return !link?.includes('http') && !link?.includes('mailto') && !link?.includes('tel:');
  }

  return (
    <ReactMarkdown
      transformLinkUri={(link) => isLinkRelative(link) ? filterRelativeLinks(link, process.env.GATSBY_MARKET) : addTrailingSlash(link)}
      {...props}
    />
  )
}

export default Markdown;
