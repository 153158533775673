import React, { useContext } from 'react'
import queryString from 'query-string'

import range from 'lodash/range'
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

import { BreakpointContext } from '@ef-global/web-ui-react/lib/components/BreakpointContext';
// @ts-ignore
import { FLAGS_PATH, API_SETTINGS, EMPTY_OPTION, GENDERS, MOBILE_BREAKPOINTS, TABLET_BREAKPOINTS, RESOLUTIONS } from './constants';
import { IGetStudentsPayload, IStudent } from './types'

const { url, method, headers } = API_SETTINGS

const composeUrl = (path: string) => `${url}${path}`

const jsonToQueryString = (json: IGetStudentsPayload) => {
    const jsonWithoutFalsey = pickBy(json, identity);


    return '?' +
        Object.keys(jsonWithoutFalsey).map((key) =>
            encodeURIComponent(key) + '=' +
            // @ts-ignore
            encodeURIComponent(jsonWithoutFalsey[key])).join('&');
}

const getResolution = (): number => {
    const { currentBp } = useContext(BreakpointContext);

    if (MOBILE_BREAKPOINTS.includes(currentBp))
        return RESOLUTIONS.mobile
    else if (TABLET_BREAKPOINTS.includes(currentBp))
        return RESOLUTIONS.tablet

    return RESOLUTIONS.desktop
}

export const getStudents = async (request: IGetStudentsPayload = {}): Promise<{length: number, students?: IStudent[]}> => {
    const response = await fetch(composeUrl('get-students') + jsonToQueryString(request), {
        method,
        headers
    })
    return (await response.json()).body
}

export const getStudent = async (id: string[] | string | null | undefined): Promise<IStudent> => {
    const response = await fetch(composeUrl(`get-student/${id}`), {
        method,
        headers
    })

    return (await response.json()).body
}

export const getAllCountries = async (): Promise<[]> => {
    const response = await fetch(composeUrl('get-countries'), {
        method,
        headers
    })

    return (await response.json()).body;
}

export const getCountry = async (code: string): Promise<{ country: string, countryName: string, description: string }> => {
    const response = await fetch(composeUrl(`get-country?countryCode=${code}`), {
        method,
        headers
    })

    return (await response.json()).body;
}

export const getFlagImageUrl = ((countryCode: string) => `${FLAGS_PATH}${countryCode}.svg`)

export const ageOptions = (minAge: string, maxAge: string) => [
    EMPTY_OPTION,
    ...range(parseInt(minAge, 10), parseInt(maxAge, 10) + 1).map((item) => <option value={item} key={item}>{item}</option>)
]

export const genderOptions = [
    EMPTY_OPTION,
    <option key={GENDERS.female} value={GENDERS.female}>Female</option>,
    <option key={GENDERS.male} value={GENDERS.male}>Male</option>
]

export const getValueOrUndefinedFromEvent = (event: any) => event?.currentTarget?.value || undefined

export const getResponsiveValue = (mobile: number, tablet: number, desktop: number) => {
    switch (getResolution()) {
        case RESOLUTIONS.mobile:
            return mobile;
        case RESOLUTIONS.tablet:
            return tablet;
        default:
            return desktop;
    }
}

export const getLocationSearch = () => {
    try {
        return queryString.parse(location.search);
    } catch (error) {
        return {};
    }
}

type UpdateLocationQueryParams = {
  country?: string | undefined,
  gender?: string | undefined,
  age?: string | undefined,
}

export const updateLocation = (queryParamToAdd: UpdateLocationQueryParams) => {
    const buildQueryString = (queryParam: UpdateLocationQueryParams) => queryString.stringify({
        ...queryString.parse(location.search),
        ...queryParam
    })

    return history.pushState(null, '', `?${buildQueryString(queryParamToAdd)}`);
};
