import React from 'react';
import styled from '@emotion/styled';
import Markdown from '~components/custom/markdown';

export default ({
    text = 'Message',
    text_color = { color: '#ffffff' },
    background_color = { color: '#e20079' },
    height = '1rem',
    open_in_a_new_tab = false
}) => {
    const Banner = styled.div`
        color: ${text_color?.color};
        background-color: ${background_color?.color};
        padding: ${height} 0;
        vertical-align: middle;
        text-align: center;

        * {
            color: ${text_color?.color} !important;
        }

        p {
            max-width: 100%;
        }
    `;

    return (
        <Banner className='banner'>
            <Markdown
                source={text}
                css={{ color: text_color?.color }}
                linkTarget={open_in_a_new_tab ? '_blank' : null}
                renderers={{ link: props => <a {...props} rel='noopener noreferrer nofollow' /> }}
            />
        </Banner>
    )
}
