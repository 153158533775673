import { Reducer } from "react";

interface QuizState {
  step: number;
  questionUids?: Array<any>;
  results?: Array<any>;
  result: string;
}

interface QuizAction {
  type: string;
  step?: number;
  questionUids?: Array<any>;
  results?: Array<any>;
  question_info?: any;
  result?: string;
  formData?: Array<any>;
}

export const actionTypes = {
  initial: "INITIAL",
  incrementStep: "INCREMENT_STEP",
  decrementStep: "DECREMENT_STEP",
  selectAnswer: "SELECT_ANSWER",
  setResult: "SET_RESULT",
  saveForm: "SAVE_FORM",
  redo: "REDO_QUIZ",
};

const quizReducer: Reducer<any, any> = (
  state: QuizState,
  action: QuizAction
) => {
  switch (action.type) {
    case actionTypes.initial:
      return { ...state, ...action };
    case actionTypes.incrementStep:
      return { ...state, step: state.step + 1, type: action.type };
    case actionTypes.decrementStep:
      return {
        ...state,
        questionUids: {},
        step: state.step - 1,
        type: action.type,
      };
    case actionTypes.selectAnswer:
      const { question_uid, answer_uid, answer_key } = action.question_info;
      return {
        ...state,
        questionUids: {
          ...state.questionUids,
          [question_uid]: {
            selected_answer_uid: answer_uid,
            selected_answer_key: answer_key,
          },
        },
        type: action.type,
      };
    case actionTypes.setResult:
      return { ...state, ...action };
    case actionTypes.saveForm:
      return { ...state, ...action };
    case actionTypes.redo:
      return { ...state, ...action };
    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
};

export { QuizState, QuizAction, quizReducer };
