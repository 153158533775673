import React, {useState, useEffect} from 'react'
import {GENDERS} from '~components/custom/students/constants';
import {Button} from '@ef-global/web-ui-react/lib/components/Button';
import {css} from '@emotion/core'

export const GenderAgeFilters = (props: any) => {
  const {minAge, maxAge, setAge, setGender, setStep} = props
  const [ageAndGender, setAgeAndGender] = useState({age: undefined, gender: undefined});

  useEffect(() => {
    if (typeof ageAndGender.age !== 'undefined' && typeof ageAndGender.gender !== 'undefined') {
      setAge(ageAndGender.age)
      setGender(ageAndGender.gender)
      setStep(3)
    }
  }, [ageAndGender]);


  const ButtonCss = css`
    .ef-button {
      text-transform: capitalize;
      width: 100%;
      &.active {
        background: #046EA7;
        color: #f5f5f5;
      }
    }
  `

  return (
    <div css={ButtonCss}>
      <small>Gender</small>
      <GenderFilter
        ageAndGender={ageAndGender}
        setAgeAndGender={setAgeAndGender}
      />
      <small>Age</small>
      <AgeFilter
        minAge={minAge}
        maxAge={maxAge}
        ageAndGender={ageAndGender}
        setAgeAndGender={setAgeAndGender}
      />
    </div>
  )
}

const GenderFilter = ({ageAndGender, setAgeAndGender}: {ageAndGender: any, setAgeAndGender: any}) => {
  const genderOptions = {...GENDERS, open: 'Open to all', nopref: 'No Preference'}
  return (
    <div className='ef-row u-mt-xxs u-mb-l'>
      {
        Object.entries(genderOptions).map(option => {
          const [key, gender] = option
          return (
            <div key={key} className='ef-col -s-6 u-mb-xs'>
              <Button
                secondary
                square
                className={`${ageAndGender.gender === gender ?  'active' : ''}`}
                onClick={() => setAgeAndGender({...ageAndGender, gender})}
              >
                {gender}
              </Button>
            </div>
          )
        })
      }
    </div>
  )
}

const AgeFilter = ({minAge, maxAge, ageAndGender, setAgeAndGender}: any) => {
  const length = (Number(maxAge) - Number(minAge)) + 1;
  const ageOptions = [...Array.from({ length }, (_, i) => Number(minAge) + i), 'Any'];
  return (
    <div className='ef-row u-mt-xxs'>
      {ageOptions.map(age => {
        return (
          <div key={age.toString()} className='ef-col -s-4 u-mb-xs'>
            <Button
              secondary
              square
              className={`${ageAndGender.age === age ?  'active' : ''}`}
              onClick={() => setAgeAndGender({...ageAndGender, age})}
            >
              {age.toString()}
            </Button>
          </div>
        )
      })}
    </div>
  )
}
