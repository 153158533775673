import React from 'react'
import Loadable from 'react-loadable'

const FacebookShareButton = Loadable({
  loader: () => import('react-share'),
  render(loaded, props) {
    let Component = loaded.FacebookShareButton;
    return <Component {...props}/>;
  },
  loading: () => <div/>
});

export const Facebook = (props: any) => {
  const {url, ...rest} = props
  return (
    <FacebookShareButton url={url} {...rest}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
        <g fill="none" fillRule="evenodd">
          <circle
            cx="16"
            cy="16"
            r="15.5"
            stroke="#191919"
            strokeOpacity="0.2"
          />
          <path
            fill="#191919"
            d="M18.155 11.16h1.215V9.09c-.591-.06-1.186-.09-1.78-.09-1.765 0-2.975 1.075-2.975 3.055v1.705h-2v2.32h2V22H17v-5.92h2l.3-2.32H17v-1.475c0-.67.195-1.125 1.155-1.125z"
          />
        </g>
      </svg>
    </FacebookShareButton>
  )
}
