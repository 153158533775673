import { Button } from '@ef-global/web-ui-react/lib/components/Button';
import { ISubmissionState } from '../state/form-state';
import React from 'react';
import styles from './form-render-helpers.module.scss';

const PREVIOUS_BUTTON_TEXT = 'Back';
const NEXT_BUTTON_TEXT = 'Next';

const renderSubmitButton = (
    nrOfPages: number,
    currentPage: number,
    modalState: ISubmissionState,
    submitButtonText?: string,
    square?: boolean
  ) => {
    return nrOfPages === 0 || nrOfPages === (currentPage + 1) ?
   (
    <Button
      primary={true}
      cta={true}
      type={'submit'}
      disabled={modalState.status === 'SUCCESS'}
      className={styles.formButton}
      square={square ?? false}
      loading={modalState.status === 'SUBMITTING'}
      style={{
        width: '100%'
      }}
    >
      {submitButtonText}
    </Button>
  ) : (
    <Button
      primary={true}
      cta={true}
      square={square ?? false}
      type='submit'
      className={`${styles.formButton} ${styles.formButtonNext} display-flex`}
      style={{
        width: '100%'
      }}
    >
      <span>{NEXT_BUTTON_TEXT}</span>
      <span className={`ef-icon -arrow-right -white`}/>
    </Button>
  )
}

const setStepColor = (currentStep: number, currentListElement: number) => {
  return currentStep === currentListElement ? 'u-bg-first-blue' : 'u-bg-first-paper';
}

const formStepsCounter = (numberOfSteps: number, currentStep: number, setFormPage: (n: number) => void) => {
  return (
    <nav className={`display-flex`}>
      {currentStep !== 0 && (
        <button
          className={`ef-link ${styles.link}`}
          onClick={(event) => {
            event.preventDefault();
            setFormPage(currentStep - 1);
          }}
        >
          <span className='ef-icon -arrow-left'/>
          <p>{PREVIOUS_BUTTON_TEXT}</p>
        </button>
        )
      }
      <ul className={`${styles.stepsCounter}`}>
        {Array(numberOfSteps).fill(0).map((_: any, i: number) => {
          return (
            <li
              className={`${styles.stepsCounterElement} ${setStepColor(currentStep, i)}`}
              key={i}
            >
              {i + 1}
            </li>
          )
        })}
      </ul>
    </nav>
  );
}

export {
  renderSubmitButton,
  formStepsCounter
}
