import React, { useReducer } from "react";
import { IStoryBlokComponent } from "~interfaces/storyblock";
import { IQuestionProps } from "./question";
import Components from "~components/components";
import { quizReducer } from "./quiz-state";
import { QuizForm } from "./quiz-form";
import "./quiz-styles.scss";
import { Questions } from "~components/custom/quiz/questions";
import { useLocalStorage } from "~helpers/useLocalStorage";
import { isGatsbyProduction } from "../../utils/env-helpers";

interface IQuizProps extends IStoryBlokComponent {
  questions: Array<IQuestionProps>;
  results: Array<any>;
  questions_submit_button_text: string;
  form_type: string;
  campaign_name: string;
  view_all_steps: boolean;
  submit_button_text: string;
  spacing_between_elements: string;
  enable_labels: boolean;
  form_id: string;
  form_title?: string;
  form_position?: "first" | "last" | "removed";
}

const QuizContext = React.createContext<any>({});

const Quiz = (props: IQuizProps) => {
  const {
    questions,
    results,
    story,
    questions_submit_button_text,
    campaign_name,
    view_all_steps,
    submit_button_text,
    spacing_between_elements,
    enable_labels,
    form_id = "",
    form_title = "",
    form_position = "first",
  } = props;
  const parsedCampaignName = campaign_name.replace(/\\s+/g, "-").toLowerCase();

  const questionUids = questions.reduce((acc, question) => {
    const { _uid } = question;
    // @ts-ignore
    acc[_uid] = {
      selected_answer_key: "",
      selected_answer_uid: "",
    };
    return acc;
  }, {});

  // @ts-ignore
  const [storedState, setStoredState] = useLocalStorage(
    {
      step: 1,
      questionUids,
      result: "",
    },
    `ef-quiz-data-${parsedCampaignName}`
  );

  const forceVisible = () => {
    return isGatsbyProduction() ? false : view_all_steps;
  };

  const [quizState, quizDispatch] = useReducer(quizReducer, storedState);

  const renderQuestions = () => {
    return (
      <Questions
        submitButtonText={questions_submit_button_text}
        questions={questions}
        story={story}
        parsedCampaignName={parsedCampaignName}
        campaignName={campaign_name}
      />
    );
  };

  const renderQuizForm = () => {
    return (
      <div className="ef-row -x-center">
        <div className="ef-col -s-12 -m-8">
          <h4 className="u-text-center">{form_title}</h4>
          <QuizForm
            campaignName={campaign_name}
            parsedCampaignName={parsedCampaignName}
            questionUids={questionUids}
            submit_button_text={submit_button_text}
            spacing_between_elements={spacing_between_elements}
            enable_labels={enable_labels}
            form_id={form_id}
            terms_and_conditions="here"
          />
        </div>
      </div>
    );
  };

  return (
    <QuizContext.Provider value={{ quizState, quizDispatch }}>
      <div className="quiz ef-container">
        {form_position !== "removed" ? (
          <>
            {(forceVisible() || quizState.step === 1) &&
              (form_position === "first"
                ? renderQuizForm()
                : renderQuestions())}
            {(forceVisible() || quizState.step === 2) &&
              (form_position === "last" && !quizState.finishedQuiz
                ? renderQuizForm()
                : renderQuestions())}
            {(forceVisible() || quizState.step === 3) && (
              <div id="quiz-results" className="quiz">
                {results &&
                  results.map((blok) =>
                    Components(
                      {
                        ...blok,
                        view_all_steps,
                        parsedCampaignName,
                        form_position,
                      },
                      story
                    )
                  )}
              </div>
            )}
          </>
        ) : (
          <>
            {(forceVisible() || quizState.step === 1) && renderQuestions()}
            {(forceVisible() || quizState.step === 2) && (
              <div id="quiz-results" className="quiz">
                {results &&
                  results.map((blok) =>
                    Components(
                      {
                        ...blok,
                        view_all_steps,
                        parsedCampaignName,
                        form_position,
                      },
                      story
                    )
                  )}
              </div>
            )}
          </>
        )}
      </div>
    </QuizContext.Provider>
  );
};

export { QuizContext, Quiz };
