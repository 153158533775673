import React, { useState } from "react";
import { getFlagImageUrl } from "./library";
import {
  FacebookIcon,
  FacebookShareButton,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import { Helmet } from "react-helmet";
import {
  Button,
  ButtonGroup,
} from "@ef-global/web-ui-react/lib/components/Button";

// @ts-ignore
export default ({
  student: {
    country,
    countryName,
    name,
    gender,
    age,
    personalLetter,
    answerPersonalOutlook,
    answerPersonalityHabits,
  },
  interestsLi,
  personalLetterVisibleCharacters,
  computedInterests,
  handleOpenModal,
}) => {
  const [showMore, setShowMore] = useState(false);
  const url = typeof window !== "undefined" ? window.location.href : "";
  const shareButtonProps = {
    borderRadius: 100,
    size: 30,
  };
  const pageTitle = `Consider hosting ${name} from ${countryName}`;
  const pageDescription = `We only accept students who will flourish on an intercultural exchange. Our thorough screening and preparation process set our students up for success. Together, we will find the perfect match for your family.`;
  const buildReadMore = (text: string) => {
    const ellipsis = " ...";
    const showShowMore = text.length > personalLetterVisibleCharacters;
    const personalLetter1 = text.substring(0, personalLetterVisibleCharacters);
    const personalLetter2 = text.substring(personalLetterVisibleCharacters);

    const showMoreButton = (
      <div className="student--show-more" key="showMoreButton">
        <a onClick={() => setShowMore(true)}>
          Show more <span className="ef-icon -arrow-down" />
        </a>
      </div>
    );

    const showLessButton = (
      <div className="student--show-more" key="showLessButton">
        <a onClick={() => setShowMore(false)}>
          Show less <span className="ef-icon -arrow-up" />
        </a>
      </div>
    );

    const secondPart =
      showShowMore && !showMore
        ? [ellipsis, showMoreButton]
        : [personalLetter2, showLessButton];
    if (showShowMore) {
      return [personalLetter1, secondPart];
    } else {
      return text;
    }
  };

  return (
    <>
      <Helmet
        defer={false}
        meta={[
          {
            content: pageTitle,
            name: "og:title",
          },
          {
            content: pageDescription,
            name: "og:description",
          },
          {
            content: pageTitle,
            name: "twitter:title",
          },
          {
            content: "summary_large_image",
            name: "twitter:card",
          },
          {
            content: "@ef",
            name: "twitter:site",
          },
          {
            content: pageDescription,
            name: "twitter:description",
          },
          {
            name: "referrer",
            content: "no-referrer",
          },
          {
            name: "Referrer-Policy",
            content: "same-origin",
          },
          {
            name: "X-Frame-Options",
            content: "SAMEORIGIN",
          },
        ]}
        title={pageTitle}
      />
      <div>
        <div className="ef-row -x-around student__detail--body">
          <div className="ef-col -s-12 -m-8">
            <img
              className="student__detail--flag"
              src={getFlagImageUrl(country)}
            />
            <h4 className="student__detail--name">
              {name}
              <span hidden={!age}>, {age}</span>
              <small className="student__detail--gender-and-country">
                {gender}
                <span hidden={!countryName}> from</span>
                <span
                  className="student__detail--country"
                  hidden={!countryName}
                >
                  {" "}
                  {countryName}
                </span>
              </small>
            </h4>
          </div>
          <div className="student--interests ef-col -s-12 -m-4">
            <ul>{interestsLi}</ul>
          </div>
        </div>
        <div className="ef-col -s-12 student__detail--buttons u-show-s-only">
          <ButtonGroup>
            <Button className="full-width-s" primary onClick={handleOpenModal}>
              Learn more about {name}
            </Button>
          </ButtonGroup>
        </div>
        <div className="ef-row -s-12 -m-8">
          <div className="ef-col">
            <div className="student--profile-question">
              Tell us about yourself and your personality.
            </div>
            <div className="student--profile-answer">
              {buildReadMore(answerPersonalityHabits)}
            </div>
            <div className="student--profile-question">
              Why will you be a successful exchange student?
            </div>
            <div className="student--profile-answer">
              {buildReadMore(answerPersonalOutlook)}
            </div>
          </div>
        </div>
        <div className="ef-row -s-12 -m-8">
          <div className="ef-col -s-10 -m-offset-1 student__detail--body-share">
            <FacebookShareButton url={url} quote={pageTitle}>
              <FacebookIcon {...shareButtonProps} />
            </FacebookShareButton>
            <EmailShareButton
              url={""}
              subject={pageTitle}
              body={`I found this wonderful exchange student and wanted to share the profile with you!

${name} is one of the many wonderful exchange students ready to come to US this year with EF High School Exchange Year. By opening your home to an exchange student, you’ll make a second family in another part of the world!

Learn more about this student: ${url}

If you are interested in reading more from this student’s profile or would like to view additional profiles, reach out to EF at 800-447-4273 or exchangeyear@ef.com.`}
              separator="
"
            >
              <EmailIcon {...shareButtonProps} />
            </EmailShareButton>
          </div>
        </div>
      </div>
    </>
  );
};
