import {Form} from '@ef-global/web-ui-react/lib/components/Form';
import React, {useContext, useEffect} from 'react';
import Link from '~components/utils/link';
import {IFormElement} from '~interfaces/components';
import SettingsContext from '~src/helpers/settingsContext';
import { css } from '@emotion/core'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';
import './utils/datetime-input.scss'
import {BreakpointContext, BreakpointProvider} from '~components/web-ui-react/breakpoint-context'

interface IFormGroupProps extends IFormElement {
  formik: any;
  className?: string;
  enableLabels?: boolean;
  option_layout?: 'horizontal' | 'vertical';
}

const FormGroup: React.FC<IFormGroupProps> = (props) => {
  const {children, label, id, formik, valid, className, enableLabels} = props;
  const isHorizontal = props.option_layout === 'horizontal'

  return (
    <Form.Group
      label={enableLabels ? label : null}
      valid={formik.touched[id] ? valid : undefined}
      className={className}
      inline={props.option_layout === 'horizontal'}
      css={css`
        & > .ef-form-label {
          font-weight: 700;
          font-size: 16px;
          ${isHorizontal ? 'flex: 0 0 100%;' : null}
        }

        & > .ef-form-validation {
          ${isHorizontal ? 'flex: 0 0 100%;' : null}
        }
      `}
    >
      {children}
      {
        formik.touched[id] && formik.errors[id] ?
          <Form.ValidationMessage valid={false}>{formik.errors[id]}</Form.ValidationMessage>
          : <Form.ValidationMessage valid={true}/>
      }
    </Form.Group>
  );
};

export interface IFormElementProps extends IFormElement {
  formik: any;
}

const FormInput = ({placeholder, formik, id, type}: IFormElementProps) => {
  // Convert phone type to text
  const localType = type === 'phone' ? 'text' : type;

  return (
    <Form.Input
      id={id}
      floatingLabel={true}
      type={localType}
      placeholder={placeholder}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values[id]}
      className='u-mt-xxs'
    />
  );
};

const FormSelect = (props: IFormElementProps) => {
  const {options, id, formik, label} = props;

  return (
    <Form.Select
      id={id}
      floatingLabel={true}
      placeholder={label}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values[id]}
      className='u-mt-xxs'
    >
      {options?.map((option) => {
        return <option value={option.value} key={option.value}>{option.label}</option>;
      })}
    </Form.Select>
  );
};

const FormRadio = (props: IFormElementProps) => {
  const {options, id, formik} = props;

  return (
    <>
      {options ? options.map((radio) => {
          return (
            <Form.Radio
              key={radio.value}
              id={id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={radio.value}
              checked={radio.value === formik.values[id]}
              className='u-mt-s'
            >
              {radio.label}
            </Form.Radio>
          );
        },
      ) : null}
    </>
  )
};

const FormCheckbox = (props: IFormElementProps) => {
  const {options, id, formik, label} = props;

  return (
    <>
      {options ? options.map((checkbox) => {
          // Not very elegant...
          // Terms and conditions checkbox has the legal notice link from settings hardcoded to the label
          if (label === 'Terms and Conditions') {
            const {settings} = useContext(SettingsContext);
            return (
              <div
                key={checkbox.value}
                className='display-flex'
              >
                <Form.Checkbox
                  id={id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[id]}
                >
                  {Object.keys(settings).length === 0 ?
                    (
                      <a href='/legal-notices' target={'_blank'}>{checkbox.label}</a>
                    ) : (
                    <Link
                      storyblokLink={settings.content?.legal_notices_link}
                      target={'_blank'}
                    >
                      {checkbox.label}
                    </Link>
                  )}
                </Form.Checkbox>
              </div>
            );
          }
          return (
            <Form.Checkbox
              key={checkbox.value}
              id={id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={checkbox.value}
              className='u-mt-s'
              css={css`
                & > .ef-boolean__input:checked ~ .-checkbox:before {
                  background-image: url(data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iZWYtaWNvbiBlZi1zdmciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDMyIDMyIiB3aWR0aD0iMzIiIGhlaWdodD0iMzIiPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0yNC43MSAxMS4xN2wtLjcxLS43MWExIDEgMCAwMC0xLjQxIDBsLTguNzEgOC43MS00LjQ2LTQuNDZhMSAxIDAgMDAtMS40MSAwbC0uNzEuNzFhMSAxIDAgMDAwIDEuNDFMMTIuNDYgMjJsLjcxLjcxYTEuMTUgMS4xNSAwIDAwLjMzLjIxLjk0Ljk0IDAgMDAuNzYgMCAxLjE1IDEuMTUgMCAwMC4zMy0uMjFsLjctLjcxIDkuNDItOS40MmExIDEgMCAwMDAtMS40MXoiLz48L3N2Zz4=);
                }
              `}
            >
              {checkbox.label}
            </Form.Checkbox>
          );
        },
      ) : null}
    </>
  );
};

const FormTextArea = ({id, formik, placeholder}: IFormElementProps) => {
  return (
    <Form.Textarea
      id={id}
      placeholder={placeholder}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values[id]}
      style={{width: '100%'}}
    />
  )
}

const FormDateTime = ({id, formik, placeholder, date_format = 'MMMM d, yyyy'}: IFormElementProps) => {
  const dateFormat = date_format === 'd M Y' ? 'd MMMM, yyyy' : 'MMMM d, yyyy'

  return (
    <BreakpointProvider>
      <DatePickerField
        id={id}
        dateFormat={dateFormat}
        value={formik.values[id]}
        onChange={formik.setFieldValue}
        placeholder={placeholder}
      />
    </BreakpointProvider>
  )
}

const DatePickerField = (
  {
    id,
    value,
    onChange,
    placeholder,
    dateFormat
  }: {
    id: string, value: Date, onChange: any, placeholder: string, dateFormat: string
  }) => {
  const {currentBp} = React.useContext(BreakpointContext)
  const mobileBps = ['s']

  return (
      <DatePicker
        className={'ef-input'}
        placeholderText={placeholder}
        selected={(value && convertLocalToUTCDate(value)) || null}
        onChange={(val: Date) => {
          onChange(id, val.toISOString().replace(/Z$/, ''));
        }}
        dateFormat={dateFormat}
        calendarClassName='ef-input-datetime'
        formatWeekDay={(dayOfTheWeek) => {
          return dayOfTheWeek.charAt(0)
        }}
        withPortal={mobileBps.includes(currentBp)}
        popperModifiers={{
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: 'viewport'
          }
        }}
      />
  );
};

const convertLocalToUTCDate = (date: Date) => {
  if (!date) {
    return date
  }
  date = new Date(date)
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  return date
}

interface IFormElementsProps extends IFormElementProps {
  className?: string;
  enableLabels?: boolean;
}

const FormElements = (props: IFormElementsProps) => {
  // Do not render hidden fields
  if (props.hidden === '1') {
    return null;
  }

  switch (props.type) {
    case 'text':
    case 'email':
    case 'phone':
      return (
        <FormGroup {...props}>
          <FormInput {...props} />
        </FormGroup>
      );
    case 'checkbox':
      return (
        <FormGroup {...props}>
          <FormCheckbox {...props} />
        </FormGroup>
      );
    case 'radio':
      return (
        <FormGroup {...props}>
          <FormRadio {...props} />
        </FormGroup>
      );
    case 'select':
      return (
        <FormGroup {...props}>
          <FormSelect {...props} />
        </FormGroup>
      );
    case 'textarea':
      return (
        <FormGroup {...props}>
          <FormTextArea {...props} />
        </FormGroup>
      )
    case 'datetime':
      return (
        <FormGroup {...props}>
          <FormDateTime {...props} />
        </FormGroup>
      )
    default:
      // tslint:disable-next-line:no-console
      console.log('This Form element is not implemented, type:', props.type);
      return <div key={props.id}/>;
  }
};
// TODO: Add memoization or remove this code!

// const areEqual = (prevProps, nextProps) => {
//   const isValueEqual = prevProps.formik.values[prevProps.id] === nextProps.formik.values[nextProps.id];
//   return isValueEqual;
// };

// const FormElements = React.memo((props: IFormElementProps) => {
//   switch (props.type) {
//     case 'text':
//       return formInput(props as IFormElementProps);
//     // case 'form-select':
//     //   return formSelect(props as IFormSelectProps);
//     default:
//       // tslint:disable-next-line:no-console
//       console.log('This Form element is not implemented');
//       return <div key={props.key}/>;
//   }
// }, areEqual);

export default FormElements;
